import React, { useState } from 'react';
import { GestureResponderEvent, Pressable, Text, View } from 'react-native';
import svgIcons from '../../assets';
import { cln } from '../../utils/classnames';
import { Icon } from '../index';

interface Props {
  children: string;
  onPress?: (event: GestureResponderEvent) => void;
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  isFilled?: boolean;
  iconName?: string;
  margin?: string;
  width?: string;
}

const Tag: React.FC<Props> = ({
  children,
  onPress,
  onHoverIn,
  onHoverOut,
  iconName,
  isFilled = false,
  margin,
  width,
}) => {
  const [tagIsHovered, setTagIsHovered] = useState(false);
  const [tagIsPressed, setTagIsPressed] = useState(false);

  const tagBaseStyle = {
    tagBase: cln('self-start justify-center items-center flex-row px-3 border-[1px]', margin),
    textBase: 'font-[sans-400]',
  };

  const tagStyles = {
    tag: {
      size: cln('h-12', width),
      background: {
        default: isFilled
          ? 'border-neutral-950 dark:border-neutral-50 bg-none'
          : 'border-neutral-950 dark:border-neutral-50 bg-none',
        hover: 'border-neutral-950 dark:border-neutral-50 bg-neutral-200 dark:bg-neutral-900',
        press: 'border-neutral-950 dark:border-neutral-50 bg-neutral-300 dark:bg-neutral-800',
      },
    },
    text: tagIsHovered
      ? 'text-neutral-950 dark:text-neutral-50 font-[sans-400]'
      : isFilled
        ? 'text-neutral-950 dark:text-neutral-50 font-[sans-400]'
        : 'text-neutral-950 dark:text-neutral-50 font-[sans-400]',
  };

  // Which tag should be rendered based on the variant
  return (
    <View>
      <Pressable
        className={cln(
          tagBaseStyle.tagBase,
          tagStyles.tag.size,
          tagIsHovered
            ? tagStyles.tag.background.hover
            : tagIsPressed
              ? tagStyles.tag.background.press
              : tagStyles.tag.background.default,
        )}
        onPress={onPress}
        onHoverIn={() => {
          setTagIsHovered(true);
          onHoverIn();
        }}
        onHoverOut={() => {
          setTagIsHovered(false);
          onHoverOut();
        }}
        onPressIn={() => {
          setTagIsHovered(false);
          setTagIsPressed(true);
        }}
        onPressOut={() => {
          setTagIsHovered(false);
          setTagIsPressed(false);
        }}
      >
        <Icon
          icon={svgIcons[iconName]}
          mobileSize={{ width: 24, height: 24 }}
          webSize={{ width: 24, height: 24 }}
          classNames={cln('bottom-px', children && 'mr-2.5')}
        />

        <Text className={cln(tagBaseStyle.textBase, tagStyles.text)}>{children}</Text>
      </Pressable>
    </View>
  );
};

export default Tag;
