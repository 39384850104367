import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../contexts/Messages/MessageContext';

const FormErrorNotification = () => {
  const { isValid, isValidating, isSubmitting } = useFormikContext();
  const { setMessage } = useMessage();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      setMessage({
        message: t('error_messages:form_validation_error'),
        type: 'error',
        translateMessage: false,
      });
    }
  }, [isSubmitting, isValid, isValidating]);

  return null;
};

export default FormErrorNotification;
