import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import Layout from '../../components/Layout';
import svgIcons, { SvgIcon } from '../../assets/index';
import { IconCard } from '../../components';
import { NavigationParamList, ScreenName, TabNavigationScreenProps } from '../../navigation/types';
import { handleLinkPress } from '../../utils';
import { isMobile } from '../../utils/responsive';
import { useAuth } from '../../contexts/AuthContext/AuthContext';

// TODO: multitenancy-nál tegyük dinamikussá
enum IconCardType {
  e_learning = 'e_learning',
  data_vizart = 'data_vizart',
  activities = 'activities',
  course_insight = 'course_insight',
  course_insight_admin = 'course_insight_admin',
  power_bi = 'power_bi',
  mome_run = 'mome_run',
  canteen = 'canteen',
  neptun = 'neptun',
}

interface IconCardInterface {
  type: IconCardType;
  icon: SvgIcon;
  onPress?: () => void;
  active: boolean;
}

const PlatformsScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.PlatformsScreen>
> = ({ navigation }) => {
  const defaultNumColumns = isMobile ? 2 : 5;

  const { t } = useTranslation();
  const { isStudent, isEmployee, me } = useAuth();

  const [iconCards, setIconCards] = useState<IconCardInterface[]>([]);
  const [parentWidth, setParentWidth] = useState<number>(0);
  const [cardWidth, setCardWidth] = useState<number>(0);
  const [numColumns, setNumColumns] = useState<number>(defaultNumColumns);

  const flatListRef = useRef<FlatList>(null);

  const links: { [key in IconCardType]: string } = {
    [IconCardType.e_learning]: 'https://elearning.mome.hu/',
    [IconCardType.data_vizart]: 'http://data-vizart.mome.hu:8080',
    [IconCardType.activities]: 'https://tevekenyseg.mome.hu/',
    [IconCardType.course_insight]: 'https://omhv.mome.hu/',
    [IconCardType.course_insight_admin]: 'https://omhv.mome.hu/admin/',
    [IconCardType.power_bi]:
      'https://teams.microsoft.com/l/team/19%3Ann7mtSvZARlWw3150R0KdTKQNeJzT2B1rhtO_jkRLCY1%40thread.tacv2/conversations?groupId=01992206-c9b5-46bd-ad5f-faaa7ad74d53&tenantId=b9159e33-1e3c-4cd2-b02f-bb2a2f4df561',
    [IconCardType.mome_run]: 'https://run.mome.hu/',
    [IconCardType.canteen]: 'https://mome.hu/hu/kantin',
    [IconCardType.neptun]: 'https://host.sdakft.hu/momehw/login.aspx',
  };
  const cardMargin = isMobile ? 16 : 24;
  const minCardWidth = 200;

  useEffect(() => {
    if (!parentWidth) {
      return;
    }

    const calculatedCardWidth = (parentWidth - (numColumns - 1) * cardMargin) / defaultNumColumns;
    const cardWidthWithMin =
      calculatedCardWidth < minCardWidth ? minCardWidth : calculatedCardWidth;
    const finalCardWidth = isMobile ? calculatedCardWidth : cardWidthWithMin;
    const calculatedNumColumns = Math.floor(parentWidth / (finalCardWidth + cardMargin));

    setCardWidth(finalCardWidth);

    !isMobile && setNumColumns(calculatedNumColumns);
  }, [parentWidth]);

  const updateIconCards = () => {
    const allIconCards: IconCardInterface[] = [
      {
        type: IconCardType.e_learning,
        icon: svgIcons.graduationHatIcon,
        active: false,
      },
      {
        type: IconCardType.data_vizart,
        icon: svgIcons.constellationIcon,
        active: me?.personas && me.personas.length > 0,
      },
      {
        type: IconCardType.course_insight,
        icon: svgIcons.girlCommentIcon,
        active: isStudent,
      },
      {
        type: IconCardType.course_insight_admin,
        icon: svgIcons.boySettingsIcon,
        active: isEmployee,
      },
      {
        type: IconCardType.power_bi,
        icon: svgIcons.chartsIcon,
        active: isEmployee,
      },
      {
        type: IconCardType.activities,
        icon: svgIcons.fileIcon,
        active: true,
      },
      {
        type: IconCardType.mome_run,
        icon: svgIcons.momeRunIcon,
        active: true,
      },
      {
        type: IconCardType.canteen,
        icon: svgIcons.canteenIcon,
        active: true,
      },
      {
        type: IconCardType.neptun,
        icon: svgIcons.neptunIcon,
        active: isStudent,
      },
    ];
    setIconCards(allIconCards.filter((card) => card.active));
  };

  useEffect(() => {
    updateIconCards();
  }, [isStudent, isEmployee]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        flatListRef?.current?.scrollToOffset({ offset: 0 });
      };
    }, []),
  );

  return (
    <Layout title={t('navigation:platforms')} isScrollView={false}>
      <View
        onLayout={(event) => {
          const { width } = event.nativeEvent.layout;
          setParentWidth(width);
        }}
      >
        <Text className="text-lead text-neutral-950 dark:text-neutral-50 mb-10 relative top-[-28px] font-[sans-600]">
          {t('platforms:subtitle')}
        </Text>
        <FlatList
          key={numColumns}
          data={iconCards}
          ref={flatListRef}
          contentContainerStyle={{ paddingBottom: isMobile ? 200 : 0 }}
          numColumns={numColumns}
          horizontal={false}
          keyExtractor={(item) => item.type}
          renderItem={({ item, index }) => (
            <IconCard
              icon={item.icon}
              title={t(`platforms:${item.type}`)}
              onPress={() => (item.onPress ? item.onPress() : handleLinkPress(links[item.type]))}
              style={{
                width: cardWidth,
                marginRight: (index + 1) % numColumns === 0 ? 0 : cardMargin,
                marginBottom: cardMargin,
              }}
            />
          )}
        />
      </View>
    </Layout>
  );
};

export default PlatformsScreen;
