import React, { useCallback, useState } from 'react';
import { Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect, useIsFocused } from '@react-navigation/core';
import { ErrorMessage, Field, Formik } from 'formik';
import * as Yup from 'yup';

import dayjs from 'dayjs';
import { isEmpty, values as _values } from 'lodash';
import Layout from '../../../components/Layout';
import {
  NavigationParamList,
  ScreenName,
  TabNavigationScreenProps,
} from '../../../navigation/types';
import { useAuth } from '../../../contexts/AuthContext/AuthContext';
import {
  AdminEventCreate,
  AdminPostReadDetail,
  EventTranslationRole,
  PersonaType,
  FileModuleType,
  Role,
  Translation,
} from '../../../client/interfaces';
import {
  Button,
  FileUpload,
  FormDate,
  FormErrorNotification,
  FormInput,
  Modal,
  PersonaCheckboxGroup,
  TagSelector,
  TimeInput,
  ToggleSwitch,
} from '../../../components';
import { useAdmin } from '../../../contexts/Admin/AdminContext';
import { useMessage } from '../../../contexts/Messages/MessageContext';
import client from '../../../client/client';
import { getTranslation } from '../../../utils/translations';
import {
  DeepPartial,
  FileUploadStatus,
  Language,
  UploadedFile,
} from '../../../resources/interfaces';
import svgIcons from '../../../assets';
import { extensionImageWhitelist } from '../../../components/FileUpload/helpers';
import { readFileUrl } from '../../../utils/file';

const MAX_FILE_SIZE = 1000 * 1000; // 1 MB

type EventSchemaTranslations = {
  [key in Language]: { [key in EventTranslationRole]: string };
};

/* eslint-disable camelcase */
interface EventSchema {
  translations: EventSchemaTranslations;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  location: string;
  isOnline: boolean;
  joinLink?: string;
  isEnglish: boolean;
  tagIds: number[];
  personaKeys: PersonaType[];
  published: boolean;
  fileId: number;
  files: { [k: string]: UploadedFile };
}

const emptyFormValues: EventSchema = {
  translations: {
    hu: {
      title: '',
      description: '',
      cta_label: '',
      cta_link: '',
    },
    en: {
      title: '',
      description: '',
      cta_label: '',
      cta_link: '',
    },
  },
  startDate: dayjs(Date.now()).format('YYYY-MM-DD'),
  endDate: dayjs(Date.now()).format('YYYY-MM-DD'),
  startTime: '',
  endTime: '',
  location: '',
  joinLink: '',
  isOnline: false,
  isEnglish: false,
  tagIds: [],
  personaKeys: [],
  published: false,
  fileId: null,
  files: undefined,
};

const AdminEventEditScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.AdminEventEditScreen>
> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { me, hasRole } = useAuth();
  const { isBackModalOpen, setIsBackModalOpen } = useAdmin();
  const isFocused = useIsFocused();

  const [initialFormValues, setInitialFormValues] = useState<EventSchema>(emptyFormValues);
  const { setMessage } = useMessage();

  const isNewEvent = !route?.params?.postId;
  const requiredTranslation = t('admin:common:required_field');
  const tooLongTranslation = (max) => t('admin:common:max_characters', { max });

  const requiredTogetherValidation = (otherFields, schema) => {
    const anyOtherFieldHasValue = otherFields.length && otherFields.some((field) => !!field);

    if (anyOtherFieldHasValue) {
      return schema.required(requiredTranslation);
    }

    return schema;
  };

  const eventSchema = Yup.lazy((): Yup.ObjectSchema<DeepPartial<EventSchema>> => {
    return Yup.object().shape(
      {
        fileId: Yup.number(),
        files: Yup.object()
          .test('object-is-not-empty', requiredTranslation, (value) => !isEmpty(value))
          .test(
            'upload-status-is-done',
            requiredTranslation,
            (value) => _values(value)[0] && _values(value)[0].status === FileUploadStatus.done,
          ),
        translations: Yup.object().shape({
          hu: Yup.object().shape({
            title: Yup.string().required(requiredTranslation).max(100, tooLongTranslation(100)),
            description: Yup.string()
              .required(requiredTranslation)
              .max(3000, tooLongTranslation(3000)),
            cta_label: Yup.string()
              .when(
                [
                  '$translations.en.cta_label',
                  '$translations.hu.cta_link',
                  '$translations.en.cta_link',
                ],
                requiredTogetherValidation,
              )
              .max(40, tooLongTranslation(40)),
            cta_link: Yup.string()
              .url(t('error_messages:url_format'))
              .when(
                [
                  '$translations.en.cta_link',
                  '$translations.hu.cta_label',
                  '$translations.en.cta_label',
                ],
                requiredTogetherValidation,
              ),
          }),
          en: Yup.object().shape({
            title: Yup.string().required(requiredTranslation).max(100, tooLongTranslation(100)),
            description: Yup.string()
              .required(requiredTranslation)
              .max(3000, tooLongTranslation(3000)),
            cta_label: Yup.string()
              .when(
                [
                  '$translations.hu.cta_label',
                  '$translations.en.cta_link',
                  '$translations.hu.cta_link',
                ],
                requiredTogetherValidation,
              )
              .max(40, tooLongTranslation(40)),
            cta_link: Yup.string()
              .url(t('error_messages:url_format'))
              .when(
                [
                  '$translations.hu.cta_link',
                  '$translations.en.cta_label',
                  '$translations.hu.cta_label',
                ],
                requiredTogetherValidation,
              ),
          }),
        }),
        startDate: Yup.string().required(requiredTranslation),
        endDate: Yup.string().test(
          'is-valid-end-date',
          t('error_messages:end_date_must_after'),
          function (value) {
            const { startDate } = this.parent;
            if (!startDate || !value) return true;

            if (dayjs(value).isAfter(dayjs(startDate))) return true;
            return dayjs(value).isSame(dayjs(startDate));
          },
        ),
        startTime: Yup.string()
          .min(5, t('error_messages:time_format'))
          .when(['endTime'], requiredTogetherValidation),
        endTime: Yup.string()
          .min(5, t('error_messages:time_format'))
          .test('is-valid-end-time', t('error_messages:end_date_must_after'), function (value) {
            const { startDate, startTime, endDate } = this.parent;
            if (!startTime || !value) return true;
            // Check if startDate and endDate are the same and endTime is sooner than startTime
            if (dayjs(startDate).isSame(dayjs(endDate)) && startTime && value) {
              return value > startTime;
            }
            // If startDate is later than endDate, do not generate an error
            if (dayjs(startDate).isAfter(dayjs(endDate))) {
              return true;
            }
            return true; // Default case, no error generated
          })
          .when(['startTime'], requiredTogetherValidation),
        location: Yup.string().required(requiredTranslation),
        isOnline: Yup.boolean(),
        joinLink: Yup.string()
          .url(t('error_messages:url_format'))
          .when(['isOnline'], requiredTogetherValidation),
        isEnglish: Yup.boolean(),
        tagIds: Yup.array().min(1, requiredTranslation),
        personaKeys: Yup.array().min(1, requiredTranslation),
        published: Yup.boolean(),
      },
      [
        ['translations.en.cta_label', 'translations.hu.cta_label'],
        ['translations.en.cta_link', 'translations.hu.cta_link'],
        ['startTime', 'endTime'],
        ['isOnline', 'joinLink'],
      ],
    );
  });

  const transformEventToSchema = async (event: AdminPostReadDetail): Promise<EventSchema> => {
    let file: File;

    if (event.fileId) {
      file = await readFileUrl(event.imageUrl);
    }

    return {
      translations: {
        hu: {
          title: getTranslation(event.allTranslations, EventTranslationRole.title, Language.hu),
          description: getTranslation(
            event.allTranslations,
            EventTranslationRole.description,
            Language.hu,
          ),
          cta_label: getTranslation(
            event.allTranslations,
            EventTranslationRole.ctaLabel,
            Language.hu,
          ),
          cta_link: getTranslation(
            event.allTranslations,
            EventTranslationRole.ctaLink,
            Language.hu,
          ),
        },
        en: {
          title: getTranslation(event.allTranslations, EventTranslationRole.title, Language.en),
          description: getTranslation(
            event.allTranslations,
            EventTranslationRole.description,
            Language.en,
          ),
          cta_label: getTranslation(
            event.allTranslations,
            EventTranslationRole.ctaLabel,
            Language.en,
          ),
          cta_link: getTranslation(
            event.allTranslations,
            EventTranslationRole.ctaLink,
            Language.en,
          ),
        },
      },
      startDate: event.event.startDate,
      endDate: event.event.endDate,
      startTime: event.event.startTime || '',
      endTime: event.event.endTime || '',
      location: event.event.location,
      isOnline: !!event.event.joinLink,
      joinLink: event.event.joinLink || '',
      isEnglish: event.event.isEnglish,
      tagIds: event.tags.map((tag) => tag.id),
      personaKeys: event.personas.map((persona) => persona.key),
      published: !!event.publishedAtUtc,
      fileId: event.fileId,
      files: file
        ? {
            [file.name]: {
              id: event.fileId,
              status: FileUploadStatus.done,
              src: event.imageUrl,
              file,
            },
          }
        : {},
    };
  };

  const transformSchemaToEvent = (values: EventSchema): AdminEventCreate => {
    const {
      translations: translationValues,
      startDate,
      endDate,
      startTime,
      endTime,
      location,
      joinLink,
      isEnglish,
      tagIds,
      published,
      fileId,
      personaKeys,
    } = values;

    const translations = Object.entries(translationValues).flatMap((v) => {
      const [lang, trans] = v;
      return Object.entries(trans)
        .map((t) => {
          const [key, value] = t;
          return { language: lang, value, role: key };
        })
        .filter((t) => !!t.value);
    });

    return {
      translations: translations as Translation<EventTranslationRole>[],
      startDate,
      endDate,
      startTime: startTime || null,
      endTime: endTime || null,
      location,
      joinLink,
      isEnglish,
      tagIds,
      personaKeys,
      published,
      fileId,
    };
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const event = transformSchemaToEvent(values);

      if (isNewEvent) {
        await client.createEvent(event);
      } else {
        await client.updateEvent(route.params.postId, event);
      }

      setSubmitting(false);
      resetForm({ values: emptyFormValues });
      navigation.navigate(ScreenName.AdminEventListScreen);
    } catch (e) {
      setMessage({ message: e.message, type: 'error' });
    }
  };

  // Role guard
  useFocusEffect(
    useCallback(() => {
      if (me && !hasRole(Role.publisher)) {
        navigation.navigate(ScreenName.HomeScreen);
      }
    }, [me?.roleList]),
  );

  useFocusEffect(
    useCallback(() => {
      window.scrollTo({ top: 0, behavior: 'instant' });

      const fetchEvent = async () => {
        try {
          const eventRes = await client.getAdminEvent(route.params.postId);
          const transformed = await transformEventToSchema(eventRes);
          setInitialFormValues(transformed);
        } catch (e) {
          setMessage({ message: e.message, type: 'error' });
          navigation.navigate(ScreenName.AdminEventListScreen);
        }
      };

      if (route?.params?.postId) {
        fetchEvent();
      } else {
        setInitialFormValues(emptyFormValues);
      }
    }, [route?.params?.postId]),
  );

  return (
    <Layout
      title={isNewEvent ? t('admin:event_edit:create_title') : t('admin:event_edit:edit_title')}
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={eventSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          isSubmitting,
          resetForm,
          validateForm,
          dirty,
        }) => (
          <View className="flex-1">
            <FormErrorNotification />
            <Field name="files">
              {({ field, meta }) => (
                <View className="mb-11">
                  <View className="flex-row mb-1">
                    <Text className="text-input text-neutral-950 dark:text-neutral-50 font-[sans-600]">
                      {t('admin:common:cover_image')}
                    </Text>
                    <Text className="text-input text-danger-500 font-[sans-400]"> *</Text>
                  </View>
                  <FileUpload
                    files={field.value}
                    setValue={(value) => {
                      setFieldValue(field.name, value);

                      const id = Object.values(value)[0]?.id;

                      if (id) {
                        setFieldValue('fileId', id);
                      }
                    }}
                    module={FileModuleType.event}
                    hasError={!!(meta.touched && meta.error)}
                    accepted={extensionImageWhitelist}
                    maxSize={MAX_FILE_SIZE}
                  />
                  <ErrorMessage name={field.name}>
                    {(message) => (
                      <Text className="text-notificationMessage text-danger-500 font-[sans-600] absolute bottom-[-20px]">
                        {message}
                      </Text>
                    )}
                  </ErrorMessage>
                </View>
              )}
            </Field>
            <View className="flex-1 flex-row gap-x-20 mb-10 z-0">
              <View className="flex-1 z-20">
                <FormInput
                  name="translations.hu.title"
                  title={t('admin:event_edit:title')}
                  placeholder={t('admin:common:max_characters', { max: 100 })}
                  required
                />
                <FormInput
                  name="translations.hu.description"
                  title={t('admin:event_edit:description')}
                  placeholder={t('admin:common:max_characters', { max: 3000 })}
                  required
                  multiline={true}
                  numberOfLines={5}
                />
                <FormInput
                  name="translations.hu.cta_label"
                  title={t('admin:common.cta_label')}
                  placeholder={t('admin:common:max_characters', { max: 40 })}
                  required={[
                    values?.translations?.hu?.cta_label,
                    values?.translations?.hu?.cta_link,
                    values?.translations?.en?.cta_label,
                    values?.translations?.en?.cta_link,
                  ].some((v) => !!v)}
                />
                <FormInput
                  name="translations.hu.cta_link"
                  title={t('admin:common.cta_link')}
                  placeholder={t('admin:common.cta_link_placeholder')}
                  required={[
                    values?.translations?.hu?.cta_label,
                    values?.translations?.hu?.cta_link,
                    values?.translations?.en?.cta_label,
                    values?.translations?.en?.cta_link,
                  ].some((v) => !!v)}
                />
                <View
                  style={{
                    gap: 24,
                    flexDirection: 'row',
                    marginTop: 24,
                  }}
                >
                  <FormDate
                    name="startDate"
                    title={t('admin:event_edit:start_date')}
                    multiline={false}
                    required
                    style={{ width: 272 }}
                  />
                  <TimeInput
                    name="startTime"
                    title={t('admin:event_edit:start_time')}
                    multiline={false}
                    style={{ width: 254 }}
                  />
                </View>
                <View
                  style={{
                    gap: 24,
                    flexDirection: 'row',
                  }}
                >
                  <FormDate
                    name="endDate"
                    title={t('admin:event_edit:end_date')}
                    multiline={false}
                    required
                    style={{ width: 272 }}
                  />
                  <TimeInput
                    name="endTime"
                    title={t('admin:event_edit:end_time')}
                    multiline={false}
                    style={{ width: 254 }}
                  />
                </View>
                <FormInput
                  name="location"
                  title={t('admin:event_edit.location')}
                  placeholder={t('admin:event_edit:location_placeholder')}
                  required
                  trailingIcon={svgIcons.locationIcon}
                />
                <Field name="isOnline">
                  {({ field }) => (
                    <ToggleSwitch
                      classNames="mb-11"
                      value={field.value}
                      onPress={() => {
                        if (field.value) {
                          setFieldValue('joinLink', '');
                        }
                        setFieldValue(field.name, !field.value);
                      }}
                      label={t('admin:event_edit:is_online')}
                    />
                  )}
                </Field>
                {!!values.isOnline && (
                  <FormInput
                    name="joinLink"
                    title={t('admin:event_edit.join_link')}
                    placeholder={t('admin:event_edit:join_link_placeholder')}
                    required
                  />
                )}
                <Field name="isEnglish">
                  {({ field }) => (
                    <ToggleSwitch
                      classNames="mb-11"
                      value={field.value}
                      onPress={() => {
                        setFieldValue(field.name, !field.value);
                      }}
                      label={t('admin:event_edit:is_english')}
                    />
                  )}
                </Field>
                <Field name="tagIds">
                  {({ field, meta }) => (
                    <View className="mb-11 z-50">
                      <View className="flex-row">
                        <Text className="text-input text-neutral-950 dark:text-neutral-50 font-[sans-600]">
                          {t('admin:common:tags')}
                        </Text>
                        <Text className="text-input text-danger-500 font-[sans-400]"> *</Text>
                      </View>
                      <TagSelector
                        tagParams={values.tagIds}
                        setTagParams={(t) => setFieldValue(field.name, t)}
                        hasError={!!meta.touched && !!meta.error}
                      />
                      <ErrorMessage name={field.name}>
                        {(message) => (
                          <Text className="text-notificationMessage text-danger-500 font-[sans-600] absolute top-[76px]">
                            {message}
                          </Text>
                        )}
                      </ErrorMessage>
                    </View>
                  )}
                </Field>
                <PersonaCheckboxGroup required={true} />
              </View>
              <View className="flex-1">
                <FormInput
                  name="translations.en.title"
                  title={t('admin:event_edit:title_en')}
                  placeholder={t('admin:common:max_characters', { max: 100 })}
                  required
                />
                <FormInput
                  name="translations.en.description"
                  title={t('admin:event_edit:description_en')}
                  placeholder={t('admin:common:max_characters', { max: 3000 })}
                  required
                  multiline={true}
                  numberOfLines={5}
                />
                <FormInput
                  name="translations.en.cta_label"
                  title={t('admin:common.cta_label_en')}
                  placeholder={t('admin:common:max_characters', { max: 40 })}
                  required={[
                    values?.translations?.hu?.cta_label,
                    values?.translations?.hu?.cta_link,
                    values?.translations?.en?.cta_label,
                    values?.translations?.en?.cta_link,
                  ].some((v) => !!v)}
                />
                <FormInput
                  name="translations.en.cta_link"
                  title={t('admin:common.cta_link_en')}
                  placeholder={t('admin:common.cta_link_placeholder')}
                  required={[
                    values?.translations?.hu?.cta_label,
                    values?.translations?.hu?.cta_link,
                    values?.translations?.en?.cta_label,
                    values?.translations?.en?.cta_link,
                  ].some((v) => !!v)}
                />
              </View>
            </View>
            <View className="self-end flex-row gap-x-6" style={{ zIndex: -10 }}>
              <Button
                text={t('admin:common:exit_from_edit')}
                size="medium"
                variant="link"
                onPress={() => {
                  setIsBackModalOpen(true);
                }}
                disabled={isSubmitting}
              />
              {!values.published && (
                <Button
                  text={t('admin:common:save_as_draft')}
                  size="medium"
                  variant="outline"
                  onPress={async () => {
                    await setFieldValue('published', false);
                    handleSubmit();
                  }}
                  disabled={isSubmitting || !dirty}
                />
              )}
              <Button
                text={values.published ? t('admin:common:save_changes') : t('admin:common:publish')}
                size="medium"
                variant="fill"
                onPress={async () => {
                  const validationErrors = await validateForm(values);
                  isEmpty(validationErrors) && (await setFieldValue('published', true));
                  handleSubmit();
                }}
                disabled={isSubmitting || (!dirty && (isNewEvent || values.published))}
              />
            </View>
            <Modal
              showModal={isBackModalOpen && isFocused}
              title={t('admin:common:exit_warning')}
              callback={() => {
                setIsBackModalOpen(false);
                resetForm({ values: emptyFormValues });
                navigation.navigate(ScreenName.AdminEventListScreen);
              }}
              closeModal={() => {
                setIsBackModalOpen(false);
              }}
              yesText={t('admin:common:exit')}
              cancelText={t('admin:common:stay')}
              instantCallback={!dirty}
            />
          </View>
        )}
      </Formik>
    </Layout>
  );
};

export default AdminEventEditScreen;
