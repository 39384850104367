import React, { useState } from 'react';
import { FlatList, Pressable, ScrollView, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import {
  AdminPostRead,
  AdminPostReadBase,
  GetAdminArticlesParams,
  GetAdminExtremeEventsParams,
  PostStatus,
} from '../../client/interfaces';
import { Loader, SortingIcon } from '../index';
import { cln } from '../../utils/classnames';
import TableCell from '../TableCell';

export type TableType = AdminPostRead;
export type TableTypeBase = AdminPostReadBase;
export type TableParams = GetAdminArticlesParams | GetAdminExtremeEventsParams; // TODO: ezt kiegészíteni majd a GetAdminEventsParams-al

interface Props {
  rows: TableType[];
  columns: Column[];
  paramSetter: React.Dispatch<React.SetStateAction<TableParams>>;
  params: TableParams;
  isLoading?: boolean;
  keyFieldName: keyof TableTypeBase;
}

interface Column {
  key: string;
  sortable?: boolean;
  label: string;
  templateFunction?: (row: TableType) => JSX.Element;
  relationTransformer?: (row: TableType) => string[];
  widthPercentage?: number;
}

const Table: React.FC<Props> = ({
  rows,
  columns,
  paramSetter,
  params,
  isLoading,
  keyFieldName,
}) => {
  const [hoveredMoreRelations, setHoveredMoreRelations] = useState<string>();
  const { t } = useTranslation();

  // Calculate total widthPercentage and remaining width
  const totalWidthPercentage = columns.reduce((sum, col) => sum + (col.widthPercentage || 0), 0);
  const remainingWidthPercentage = 100 - totalWidthPercentage;
  const columnsWithoutWidthPercentage = columns.filter((col) => !col.widthPercentage);
  const equalWidthPercentage = remainingWidthPercentage / columnsWithoutWidthPercentage.length;

  // Add widthPercentage to columns that don't have it
  const columnsWithWidthPercentage = columns?.map((col) =>
    col.widthPercentage ? col : { ...col, widthPercentage: equalWidthPercentage },
  );

  const renderStatus = (status: PostStatus, key) => (
    <TableCell key={key}>
      <View className="flex-row items-center" key={key}>
        <View
          className={cln(
            'rounded-full w-2 h-2 mr-2',
            status === PostStatus.published
              ? 'bg-success-600 dark:bg-success-500'
              : 'bg-danger-500',
          )}
        />
        <Text className="inline-block text-tableRow text-neutral-950 dark:text-neutral-50 font-[sans-400]">
          {t(`admin:search:${status}`)}
        </Text>
      </View>
    </TableCell>
  );

  const renderRelations = (relations: string[], key) => {
    return (
      <TableCell classNames="z-10">
        <FlatList
          data={relations.slice(0, 2)}
          keyExtractor={(item) => item}
          renderItem={({ item }) => {
            return (
              <View>
                <Text className="inline-block text-tableRow text-neutral-950 dark:text-neutral-50 font-[sans-400]">
                  {item}
                </Text>
              </View>
            );
          }}
        />
        {/*TAGS WITH NUMBER IN THEM*/}
        {relations.length > 2 && (
          <View className="pt-2.5 relative">
            <Pressable
              className="flex-grow relative border border-solid border-neutral-900 dark:border-neutral-50 py-1.5 px-3"
              onHoverIn={() => setHoveredMoreRelations(key)}
              onHoverOut={() => setHoveredMoreRelations(null)}
            >
              <Text className="inline-block text-tableRow text-neutral-950 dark:text-neutral-50 font-[sans-400]">
                + {relations.length - 2}
              </Text>

              {hoveredMoreRelations === key && (
                <View className="absolute p-3 w-[200px] border left-0 bottom-8 bg-neutral-50 dark:bg-neutral-950 border-neutral-900 dark:border-neutral-50 z-20">
                  <FlatList
                    data={relations.slice(2)}
                    renderItem={({ item }) => {
                      return (
                        <View key={item} className="py-1">
                          <Text className="inline-block text-tableRow text-neutral-950 dark:text-neutral-50 font-[sans-400]">
                            {item}
                          </Text>
                        </View>
                      );
                    }}
                  />
                </View>
              )}
            </Pressable>
          </View>
        )}
      </TableCell>
    );
  };

  // BIG TABLE
  return (
    <View className="flex-1">
      {!isLoading && (
        //   HEADER
        <View className="flex-row z-10 relative justify-start items-center border-y-[1px] border-neutral-950 dark:border-neutral-50 whitespace-nowrap">
          {columnsWithWidthPercentage?.map(({ key, label, sortable = false, widthPercentage }) => {
            const cell = (
              <TableCell
                key={key}
                text={label}
                classNames="flex-row"
                textClassNames="inline-block text-subHeader font-[sans-600] self-center"
              >
                {sortable && (
                  <SortingIcon params={params} columnName={key} paramSetter={paramSetter} />
                )}
              </TableCell>
            );
            return (
              <View key={key} style={{ width: `${widthPercentage}%` }}>
                {cell}
              </View>
            );
          })}
        </View>
      )}

      {/*INDIVIDUAL ROWS*/}
      <ScrollView style={{ overflow: 'visible', zIndex: 40 }}>
        {!isLoading &&
          rows.map((row) => (
            <View
              className="flex-row justify-start items-center border-b-[1px] border-neutral-950 dark:border-neutral-50 text-label font-extralight whitespace-nowrap"
              key={row[keyFieldName]}
            >
              {columnsWithWidthPercentage?.map(
                ({ key, templateFunction, relationTransformer, widthPercentage }) => {
                  const uniqueKey = `${key}-${row[keyFieldName]}`;
                  const tData = row[key] ? row[key] : '';
                  let cell = <TableCell text={tData} key={uniqueKey} />;

                  if (templateFunction) {
                    cell = templateFunction(row);
                  }

                  if (relationTransformer) {
                    cell = renderRelations(relationTransformer(row), uniqueKey);
                  }

                  if (key === 'status') {
                    cell = renderStatus(
                      row.publishedAtUtc ? PostStatus.published : PostStatus.draft,
                      uniqueKey,
                    );
                  }

                  const needsHigherZIndex =
                    relationTransformer && relationTransformer(row).length > 2;

                  return (
                    <View
                      key={uniqueKey}
                      style={{ width: `${widthPercentage}%` }}
                      className={cln(needsHigherZIndex && 'z-[100]')}
                    >
                      {cell}
                    </View>
                  );
                },
              )}
            </View>
          ))}
      </ScrollView>
      {isLoading && <Loader classNames="mt-40" />}
    </View>
  );
};

export default Table;
