import React, { useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  DimensionValue,
  Platform,
  Pressable,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { t } from 'i18next';
import { isMobile, isNative, isTablet, isWeb } from '../../utils/responsive';
import { cln } from '../../utils/classnames';
import { Icon, EventCard, Loader } from '../index';
import svgIcons from '../../assets';
import { EventCardItem, GetEventsParams } from '../../client/interfaces';
import CustomDatePicker from '../DatePicker/CustomDatePicker';

interface Props {
  items: EventCardItem[];
  title?: string;
  toggleBookmark: (item: EventCardItem) => void;
  isEventSection?: boolean;
  eventsParams?: GetEventsParams;
  setEventsParams?: React.Dispatch<React.SetStateAction<GetEventsParams>>;
  isLoading?: boolean;
}

const EventCardList: React.FC<Props> = ({
  items,
  title,
  toggleBookmark,
  isEventSection,
  eventsParams,
  setEventsParams,
  isLoading = false,
}) => {
  const webContainer = useRef(null);
  const [moveValue, setMoveValue] = useState(0);
  const [windowWidth, setWindowWidth] = useState(null);
  // Arrow click counter
  const [counter, setCounter] = useState(1);
  const horizontalScrollViewRef = useRef(null);
  const [displayLeftArrow, setDisplayLeftArrow] = useState('none');
  const [displayRightArrow, setDisplayRightArrow] = useState('none');

  // Card gap and arrow icon size
  const gapValue = 16;
  const iconSize = 24;

  // Breakpoints
  const bigBreakPoint = 1000;
  const midBreakPoint = 720;

  // Show or hide arrows when moveValue and items change
  useEffect(() => {
    setDisplayLeftArrow(
      windowWidth > bigBreakPoint ||
        (windowWidth < bigBreakPoint && counter === 1) ||
        items.length === 1
        ? 'none'
        : 'flex',
    );
    setDisplayRightArrow(
      windowWidth > bigBreakPoint ||
        (windowWidth > midBreakPoint && counter >= items.length - 1) ||
        (windowWidth < midBreakPoint && counter >= items?.length) ||
        (windowWidth < bigBreakPoint &&
          items?.length === items.length - 1 &&
          windowWidth > midBreakPoint) ||
        items?.length <= 1
        ? 'none'
        : 'flex',
    );
  }, [moveValue, items, windowWidth]);

  // Updating the window width only on web
  useEffect(() => {
    setWindowWidth(Dimensions.get('window').width);
    function watchWidth() {
      setWindowWidth(Dimensions.get('window').width);
    }
    if (!isNative) {
      window.addEventListener('resize', watchWidth);
    }

    return function () {
      if (!isNative) {
        window.removeEventListener('resize', watchWidth);
      }
    };
  }, []);

  const scrollToPosition = () => {
    if (horizontalScrollViewRef.current) {
      horizontalScrollViewRef.current.scrollTo({
        x: (isTablet ? cardWidthTablet : cardWidth) * -1,
      });
    }
  };

  const visibleCards = isTablet ? 2 : 1;

  // Phone width of cards
  const cardWidth =
    items?.length > visibleCards
      ? Dimensions.get('window').width * 0.75
      : Dimensions.get('window').width * 0.825;

  // Phone width of cards
  const cardWidthTablet =
    items?.length > visibleCards
      ? Dimensions.get('window').width * 0.425
      : Dimensions.get('window').width * 0.455;

  // Width of the cards on different screen widths
  const cardWidthWeb =
    windowWidth < midBreakPoint
      ? '100%'
      : windowWidth < bigBreakPoint
        ? webContainer.current?.offsetWidth / 2 - gapValue
        : 'calc(33% - (64px/3))';

  const similarArticlesStyle: ViewStyle = {
    marginRight: isNative ? 16 : 0,
    height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: isTablet ? cardWidthTablet : isNative ? cardWidth : (cardWidthWeb as DimensionValue),
    marginBottom: 0,
    flex: 1,
  };

  const itemsInRow = isEventSection ? 2 : 3;

  const flexNeeded =
    (windowWidth > bigBreakPoint && items?.length === itemsInRow) ||
    (windowWidth < midBreakPoint && items?.length === 1);

  if (!flexNeeded) {
    delete similarArticlesStyle.flex;
  }

  const datePicker = (
    <View
      className={cln('w-full', isNative && 'px-[24px] mb-3')}
      style={!isNative && windowWidth > midBreakPoint && similarArticlesStyle}
    >
      <CustomDatePicker
        label="Something"
        setValue={(value) => {
          if (setEventsParams) {
            setEventsParams((prevParams) => ({
              ...prevParams,
              date: value,
            }));
          }
        }}
        name="date"
        hideInput={true}
        value={eventsParams?.date ? new Date(eventsParams.date).toString() : new Date().toString()}
        styleProp={isNative ? cardWidth : cardWidthWeb}
      />
    </View>
  );

  const renderedCards = items?.map((card) => {
    return (
      <EventCard
        key={card.id}
        item={card}
        toggleCardMark={toggleBookmark}
        styleProp={similarArticlesStyle}
      />
    );
  });

  return (
    <View
      className={cln(isMobile ? 'px-[0px]' : 'xl:px-0', 'justify-center items-start h-full flex-1')}
      style={{
        maxWidth: !isNative ? '100%' : null,
        // We need to check for both conditions, because isMobile also checks if we are on web on small screen
        width: isNative ? windowWidth : windowWidth > midBreakPoint ? windowWidth - 100 : '100%',
        paddingLeft: isNative ? 8 : 0,
      }}
    >
      {title && (
        <Text
          className={cln(
            isNative ? 'text-h2Mobile md:text-h2 mb-6 px-[22px]' : 'text-h2Tablet md:text-h2 mb-4',
            'text-neutral-950 dark:text-neutral-50 font-[sans-600]',
          )}
        >
          {title}
        </Text>
      )}
      {isNative ? (
        <View>
          {isEventSection && datePicker}
          {!isLoading && renderedCards.length <= 0 && (
            <View className="flex-row items-center flex-1 flex-wrap px-[30px]">
              <Text className="text-cardTitle md:text-sectionTitle text-neutral-950 dark:text-neutral-50 font-[sans-600]">
                {t('events:on_this_day')}{' '}
              </Text>
              <Text className="text-cardTitle md:text-sectionTitle text-primary-600 dark:text-primary-500 font-[sans-600]">
                {t('events:no')}{' '}
              </Text>
              <Text className="text-cardTitle md:text-sectionTitle text-neutral-950 dark:text-neutral-50 font-[sans-600]">
                {t('events:event')}!
              </Text>
            </View>
          )}
          <ScrollView
            horizontal
            automaticallyAdjustContentInsets={false}
            decelerationRate={0}
            snapToInterval={isTablet ? cardWidthTablet + 16 : cardWidth + 16} // Card size plus both margins
            snapToAlignment="center"
            overScrollMode="never"
            showsHorizontalScrollIndicator={false}
            scrollEnabled={isTablet ? items.length >= 3 : items.length >= 2}
            contentInset={{
              // iOS ONLY
              top: 0,
              left: 16, // Left spacing for the first card
              bottom: 0,
              right: 24, // Right spacing for the last card
            }}
            contentContainerStyle={{
              // contentInset for Android
              paddingHorizontal: Platform.OS === 'android' ? gapValue : 0, // Horizontal spacing before and after the ScrollView
              marginLeft: 8,
              paddingBottom: 8,
            }}
            ref={horizontalScrollViewRef}
            onLayout={scrollToPosition}
          >
            {isLoading ? (
              <View className="items-center justify-center" style={{ width: cardWidth }}>
                <Loader />
              </View>
            ) : (
              renderedCards
            )}
          </ScrollView>
        </View>
      ) : (
        //   WEB SCROLL CONTAINER
        <View className="w-full flex-row items-center justify-center" ref={webContainer}>
          {/*LEFT ICON*/}
          <Pressable
            className="z-[30] absolute justify-center items-end"
            style={[
              {
                width: iconSize,
                height: iconSize,
                left: -iconSize,
              },
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              isWeb
                ? {
                    top:
                      isEventSection && windowWidth < midBreakPoint
                        ? `calc(72% - ${iconSize / 2}px)`
                        : `calc(50% - ${iconSize / 2}px)`,
                    bottom:
                      isEventSection && windowWidth < midBreakPoint
                        ? `calc(72% - ${iconSize / 2}px)`
                        : `calc(50% - ${iconSize / 2}px)`,
                    display: displayLeftArrow,
                  }
                : null,
            ]}
            onPress={() => {
              if (counter >= 1 && windowWidth > midBreakPoint) {
                setCounter((prevValue) => prevValue - 1);

                setMoveValue(
                  (prevState) => prevState + webContainer.current?.offsetWidth / 2 + gapValue,
                );
              }

              if (counter >= 1 && windowWidth < midBreakPoint) {
                setCounter((prevValue) => prevValue - 1);
                setMoveValue(
                  (prevState) =>
                    prevState + webContainer.current?.offsetWidth + gapValue + gapValue,
                );
              }
            }}
          >
            <Icon
              icon={svgIcons.arrowSimpleLeftIcon}
              mobileSize={{ width: iconSize, height: iconSize }}
              webSize={{ width: iconSize, height: iconSize }}
            />
          </Pressable>

          <View className="w-full flex-1 overflow-hidden justify-start">
            {isEventSection && windowWidth < midBreakPoint && datePicker}
            <View
              className="flex-row scroll-smooth"
              style={[
                {
                  transform:
                    windowWidth < bigBreakPoint ? `translateX(${moveValue}px)` : 'translateX(0px)',
                  rowGap: 64,
                  columnGap: 32,
                  flexWrap: windowWidth > bigBreakPoint ? 'wrap' : 'nowrap',
                },
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                isWeb ? { transition: 'transform 0.3s ease-in-out' } : null,
              ]}
            >
              {isEventSection && windowWidth > midBreakPoint && datePicker}
              {isLoading ? (
                <View className="flex-1 items-center justify-center">
                  <Loader />
                </View>
              ) : renderedCards.length > 0 ? (
                renderedCards
              ) : isEventSection ? (
                <View className="flex-row flex-1 flex-wrap h-full self-center mb-2">
                  <Text className="text-sectionTitle text-neutral-950 dark:text-neutral-50 font-[sans-600] mr-3">
                    {t('events:on_this_day')}
                  </Text>
                  <Text className="text-sectionTitle text-primary-600 dark:text-primary-500 font-[sans-600] mr-3">
                    {t('events:no')}
                  </Text>
                  <Text className="text-sectionTitle text-neutral-950 dark:text-neutral-50 font-[sans-600]">
                    {t('events:event')}!
                  </Text>
                </View>
              ) : (
                <></>
              )}
            </View>
          </View>

          {/*RIGHT ARROW ICON BUTTON*/}
          <Pressable
            className="z-[30] absolute justify-center items-start"
            style={[
              {
                width: iconSize,
                height: iconSize,
                right: -iconSize,
              },
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              isWeb
                ? {
                    top:
                      isEventSection && windowWidth < midBreakPoint
                        ? `calc(72% - ${iconSize / 2}px)`
                        : `calc(50% - ${iconSize / 2}px)`,
                    bottom:
                      isEventSection && windowWidth < midBreakPoint
                        ? `calc(72% - ${iconSize / 2}px)`
                        : `calc(50% - ${iconSize / 2}px)`,
                    display: displayRightArrow,
                  }
                : null,
            ]}
            onPress={() => {
              if (windowWidth > midBreakPoint && counter <= items.length - 2) {
                setCounter((prevValue) => prevValue + 1);
                setMoveValue(
                  (prevState) => prevState - webContainer.current?.offsetWidth / 2 - gapValue,
                );
              }

              if (windowWidth < midBreakPoint && counter <= items.length - 1) {
                setCounter((prevValue) => prevValue + 1);
                setMoveValue(
                  (prevState) =>
                    prevState - webContainer.current?.offsetWidth - gapValue - gapValue,
                );
              }
            }}
          >
            <Icon
              icon={svgIcons.arrowSimpleRightIcon}
              mobileSize={{ width: iconSize, height: iconSize }}
              webSize={{ width: iconSize, height: iconSize }}
            />
          </Pressable>
        </View>
      )}
    </View>
  );
};

export default EventCardList;
