import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { isUndefined } from 'lodash';
import { useFocusEffect } from '@react-navigation/core';
import client from '../../client/client';
import { FavoritesGroup, Loader, Tag } from '../../components';
import { TagVariant } from '../../resources/interfaces';
import { useMessage } from '../../contexts/Messages/MessageContext';
import { useTags } from '../../contexts/Tag/TagContext';
import { Tag as ITag } from '../../client/interfaces';

const Favorites: React.FC = () => {
  const { tags, fetchTags } = useTags();
  const { setMessage } = useMessage();

  const [localTags, setLocalTags] = useState<ITag[]>(tags);

  useEffect(() => {
    setLocalTags(tags);
  }, [tags]);

  useFocusEffect(
    useCallback(() => {
      fetchTags();
    }, []),
  );

  const modifyLocalShouldNotify = (item, newShouldNotify?: boolean) => {
    setLocalTags((prev) =>
      prev.map((t) => (t.id === item.id ? { ...t, shouldNotify: newShouldNotify } : t)),
    );
  };

  async function updateNotify(item) {
    try {
      modifyLocalShouldNotify(item, !item.shouldNotify);
      await client.createTag({
        tagLinks: [
          {
            tagId: item.id,
            shouldNotify: !item.shouldNotify,
          },
        ],
      });
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
      modifyLocalShouldNotify(item, item.shouldNotify);
    }
  }

  async function removeAddTag(item) {
    try {
      if (!isUndefined(item.shouldNotify)) {
        // DELETE TAG
        modifyLocalShouldNotify(item, undefined);
        await client.deleteTag({ ids: [item.id] });
      } else {
        // ADD FOLLOW TAG
        modifyLocalShouldNotify(item, false);
        await client.createTag({
          tagLinks: [
            {
              tagId: item.id,
              shouldNotify: false,
            },
          ],
        });
      }
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
      modifyLocalShouldNotify(
        item,
        !isUndefined(item.shouldNotify) ? item.shouldNotify : undefined,
      );
    }
  }

  async function followAll(parentId: number) {
    try {
      const additionalTags = localTags.filter((tag) => {
        return tag.parentId === parentId && isUndefined(tag.shouldNotify);
      });

      const backendTags = additionalTags.map((tag) => {
        return { tagId: tag.id, shouldNotify: false };
      });

      await client.createTag({ tagLinks: backendTags });

      setLocalTags((prev) =>
        prev.map((t) =>
          backendTags.map((t) => t.tagId).includes(t.id) ? { ...t, shouldNotify: false } : t,
        ),
      );
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
  }

  const areAllTagsFollowed = (group) => {
    return localTags
      .filter((tag) => tag.parentId === group.id)
      .every((groupTag) => {
        return !isUndefined(groupTag.shouldNotify);
      });
  };

  return localTags ? (
    <View>
      {localTags?.map((group) => {
        return (
          !group.parentId && (
            <FavoritesGroup
              key={group.id}
              title={group.translationValue}
              onPressFollowAll={async () => {
                if (!areAllTagsFollowed(group)) {
                  await followAll(group.id);
                }
              }}
              allFollowed={areAllTagsFollowed(group)}
            >
              {localTags?.map((item) => {
                return (
                  item.parentId === group.id && (
                    // If there is a required field on one of the tags it will be true for disabled
                    <Tag
                      variant={TagVariant.Follow}
                      isFollowed={!isUndefined(item.shouldNotify)}
                      isNotified={item.shouldNotify}
                      key={item.id}
                      onPressNotify={() => updateNotify(item)}
                      onPressFollow={() => removeAddTag(item)}
                      disabled={item.isRequired}
                    >
                      {item.translationValue}
                    </Tag>
                  )
                );
              })}
            </FavoritesGroup>
          )
        );
      })}
    </View>
  ) : (
    <Loader />
  );
};

export default Favorites;
