import React from 'react';
import { View, Text, TextInputProps } from 'react-native';
import { ErrorMessage, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { PersonaType } from '../../client/interfaces';
import { Checkbox, FormikCheckbox } from '../index';

interface Props extends TextInputProps {
  required?: boolean;
}

export const usedPersonas = [PersonaType.student, PersonaType.employee];
export const allPersonas = Object.values(PersonaType);

const PersonaCheckboxGroup: React.FC<Props> = ({ required, ...props }) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField('personaKeys');

  const handlePressAll = () => {
    const allUsedPersonasSelected = field.value.length >= usedPersonas.length;

    if (allUsedPersonasSelected) {
      helpers.setValue([]);
    } else {
      helpers.setValue(allPersonas);
    }
  };

  return (
    <View className="flex">
      <View className="flex-row">
        <Text className="text-input text-neutral-950 dark:text-neutral-50 font-[sans-600] mb-2">
          {t('admin:common:personas')}
        </Text>
        {required && <Text className="text-input text-danger-500 font-[sans-400]"> *</Text>}
      </View>

      <View>
        <View className="mb-2">
          <Checkbox
            label={t('personas:all')}
            onPress={handlePressAll}
            checked={field.value.length >= usedPersonas.length}
            hasError={!!meta.touched && !!meta.error}
          />
        </View>
        <View className="flex-row">
          <FormikCheckbox
            name="personaKeys"
            value={PersonaType.student}
            label={t('personas:student')}
            disabled={field.value.length >= usedPersonas.length}
          />
          <FormikCheckbox
            name="personaKeys"
            value={PersonaType.employee}
            label={t('personas:employee')}
            disabled={field.value.length >= usedPersonas.length}
          />
        </View>
      </View>
      <ErrorMessage name="personaKeys">
        {(message) => (
          <Text className="text-notificationMessage text-danger-500 font-[sans-600] absolute bottom-[-24px]">
            {message}
          </Text>
        )}
      </ErrorMessage>
    </View>
  );
};

export default PersonaCheckboxGroup;
