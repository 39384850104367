import { useState, useEffect, useRef, createContext, PropsWithChildren, useMemo } from 'react';
import { Platform } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { isNative } from '../../utils/responsive';
import { NavigationParamList, PushNotificationRedirectScreenName } from '../../navigation/types';
import { PushNotificationData } from '../../client/interfaces';
import { getScreenNameFromPostType } from '../../utils/navigation';
import { useMessage } from '../Messages/MessageContext';
import { useAuth } from '../AuthContext/AuthContext';
import client from '../../client/client';
import { useNotifications } from '../Notification/NotificationContext';

type PushNotificationContextType = {
  pushToken: string;
};

const pushNotificationContextDefaultValues = {
  pushToken: '',
};

export const PushNotificationContext = createContext<PushNotificationContextType>(
  pushNotificationContextDefaultValues,
);

export function PushNotificationProvider({ children }: Readonly<PropsWithChildren>) {
  const [expoPushToken, setExpoPushToken] = useState<string>('');
  const notificationListener = useRef<Notifications.Subscription>();
  const responseListener = useRef<Notifications.Subscription>();
  const navigation =
    useNavigation<NavigationProp<NavigationParamList, PushNotificationRedirectScreenName>>();
  const { setMessage } = useMessage();
  const { me, fetchMe } = useAuth();
  const { notifications, fetchNotifications } = useNotifications();

  // Push értesítés engedélykérés és token kinyerés
  const registerForPushNotificationsAsync = async () => {
    let token: string;

    // Android config (app.json -> expo.plugins.expo-notifications) felüldefiniálása
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
      });
    }

    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync({
          ios: {
            allowAlert: true,
            allowBadge: true,
            allowSound: true,
          },
        });
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        return;
      }
      token = (
        await Notifications.getExpoPushTokenAsync({
          projectId: Constants.expoConfig.extra.eas.projectId,
        })
      ).data;
    } else {
      console.log('A push értesítések csak fizikai eszközökön működnek!');
    }

    return token;
  };

  useEffect(() => {
    if (isNative) {
      Notifications.setNotificationHandler({
        handleNotification: async () => ({
          shouldShowAlert: true,
          shouldPlaySound: true,
          shouldSetBadge: true,
        }),
      });

      registerForPushNotificationsAsync().then((token) => token && setExpoPushToken(token));

      // This listener is fired whenever a notification is received while the app is foregrounded.
      notificationListener.current = Notifications.addNotificationReceivedListener(
        (notification) => {
          fetchNotifications().catch((error) => {
            console.log({ error, notification });
          });
        },
      );

      // This listener is fired whenever a user taps on or interacts with a notification (works when an app is foregrounded, backgrounded, or killed).
      responseListener.current = Notifications.addNotificationResponseReceivedListener(
        (response) => {
          const decrementBadgeCount = async () => {
            const badgeCount = await Notifications.getBadgeCountAsync();

            if (badgeCount > 0) {
              await Notifications.setBadgeCountAsync(badgeCount - 1);
            }
          };

          const setNotificationSeen = async (postId: number) => {
            const notificationId = notifications.find((n) => n.postId === postId)?.id;

            if (notificationId) {
              await client.setNotificationsSeen({
                ids: [notificationId],
              });
            }

            await fetchNotifications();
          };

          const data = response.notification.request.content
            .data as unknown as PushNotificationData;
          const relatedPost = data?.post;
          const redirectTo = getScreenNameFromPostType(relatedPost.type);

          if (relatedPost) {
            decrementBadgeCount().catch((error) => {
              console.error(error.message);
            });

            setNotificationSeen(relatedPost.id).catch((error) => {
              console.error(error.message);
            });

            navigation.navigate(redirectTo, { postId: relatedPost.id });
          } else {
            console.log('A push értesítés tartalma nem megfelelő');
          }
        },
      );

      return () => {
        Notifications.removeNotificationSubscription(notificationListener.current);
        Notifications.removeNotificationSubscription(responseListener.current);
      };
    }
  }, []);

  useEffect(() => {
    const createNewPushToken = async (token: string) => {
      await client.createPushToken(token);
      await fetchMe();
    };

    if (expoPushToken && me && !me?.pushTokens?.includes(expoPushToken)) {
      createNewPushToken(expoPushToken).catch((error) => {
        setMessage({ message: error.message, type: 'error' });
      });
    }
  }, [expoPushToken, me]);

  const value: PushNotificationContextType = useMemo(
    () => ({
      pushToken: expoPushToken,
    }),
    [expoPushToken],
  );

  return (
    <PushNotificationContext.Provider value={value}>{children}</PushNotificationContext.Provider>
  );
}
