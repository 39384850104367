import React, { useEffect, useState } from 'react';
import {
  DimensionValue,
  Image,
  ImageResizeMode,
  ImageSourcePropType,
  ImageStyle,
  Platform,
  StyleProp,
} from 'react-native';
import { isNative } from '../../utils/responsive';

interface Props {
  source?: ImageSourcePropType;
  uri?: string;
  width?: DimensionValue;
  height?: DimensionValue;
  backgroundColor?: string;
  borderRadius?: number;
  resizeMode?: ImageResizeMode;
  style?: StyleProp<ImageStyle>;
}

const ScaledImage: React.FC<Props> = ({
  source,
  uri,
  width,
  height,
  backgroundColor,
  borderRadius,
  resizeMode,
  style,
}) => {
  const [aspectRatio, setAspectRatio] = useState<number>(1);

  useEffect(() => {
    if (uri) {
      Image.getSize(uri || (source as string), (actualWidth, actualHeight) => {
        setAspectRatio(actualWidth / actualHeight);
      });
    } else if (source) {
      const { width: actualWidth, height: actualHeight } = Image.resolveAssetSource(source);
      setAspectRatio(actualWidth / actualHeight);
    }
  }, [source, uri]);

  return (
    <Image
      source={uri ? { uri } : source}
      style={[
        {
          height,
          width,
          backgroundColor,
          borderRadius,
          aspectRatio,
        },
        style,
      ]}
      resizeMode={resizeMode ? resizeMode : 'contain'}
    />
  );
};

export default ScaledImage;
