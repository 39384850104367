import React, { useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { t } from 'i18next';
import { ExtremeEvent } from '../index';
import { ExtremeEventTranslationRole } from '../../client/interfaces';
import { getTranslation } from '../../utils/translations';
import { getDateDetails } from '../../utils/getDateDetails';
import Modal from '../Modal';
import { useExtremeEvents } from '../../contexts/ExtremeEvent/ExtremeEventContext';
import client from '../../client/client';
import { useMessage } from '../../contexts/Messages/MessageContext';

interface Props {
  containerStyle: StyleProp<ViewStyle>;
}

const ExtremeEventSection: React.FC<Props> = ({ containerStyle }) => {
  const { fetchExtremeEvents, extremeEvents } = useExtremeEvents();
  const [eventToRemove, setEventToRemove] = useState(null);
  const { setMessage } = useMessage();

  const onEventClose = async () => {
    try {
      await client.createExtremeEventSeen(eventToRemove.relatedItem.id);
      await fetchExtremeEvents();
    } catch (e) {
      setMessage({ message: e.message, type: 'error' });
    }
    setShowModal(false);
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = (event) => {
    setShowModal(true);
    setEventToRemove(event);
  };

  const allExtremeEvents = extremeEvents?.map((event) => {
    const dateDetails = getDateDetails(event.relatedItem);

    return (
      <ExtremeEvent
        key={event.id}
        title={getTranslation(event.translations, ExtremeEventTranslationRole.title)}
        date={dateDetails}
        imageUrl={event.imageUrl}
        onPress={() => openModal(event)}
        containerStyle={{ marginBottom: extremeEvents.length > 1 ? 24 : 0 }}
      >
        {getTranslation(event.translations, ExtremeEventTranslationRole.description)}
      </ExtremeEvent>
    );
  });

  return (
    <View className="w-full" style={containerStyle}>
      {allExtremeEvents}
      <Modal
        showModal={showModal}
        title={t('extreme_events:sure_to_close')}
        description={t('extreme_events:notification_no_show')}
        callback={() => {
          onEventClose();
        }}
        closeModal={() => {
          setShowModal(false);
          setEventToRemove(null);
        }}
        yesText={t('extreme_events:close')}
        cancelText={t('extreme_events:cancel')}
      />
    </View>
  );
};

export default ExtremeEventSection;
