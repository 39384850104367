import React from 'react';
import { useField, useFormikContext } from 'formik';
import Checkbox, { CheckboxProps } from './index';

export interface FormikCheckboxProps extends CheckboxProps {
  name: string;
  value: string;
}

const FormikCheckbox: React.FC<FormikCheckboxProps> = ({ name, value, ...props }) => {
  const { setFieldValue, values } = useFormikContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(name);

  const isChecked = values[name]?.includes(value);

  const handleChange = () => {
    const newValue = isChecked
      ? values[name].filter((item) => item !== value)
      : [...values[name], value];

    setFieldValue(name, newValue);
  };

  return (
    <Checkbox
      checked={isChecked}
      onPress={handleChange}
      hasError={!!meta.touched && !!meta.error}
      {...props}
    />
  );
};

export default FormikCheckbox;
