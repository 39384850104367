import React, { useEffect, useRef } from 'react';
import { Animated, Easing, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useColorScheme } from 'nativewind';

const SplashScreen: React.FC = () => {
  const rectangleRefRight = useRef<Animated.Value>(new Animated.Value(0)).current;
  const rectangleRefLeft = useRef<Animated.Value>(new Animated.Value(0)).current;
  const rectangleRefMiddle = useRef<Animated.Value>(new Animated.Value(1)).current;
  const animDuration = 1300;

  const { colorScheme } = useColorScheme();

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(rectangleRefRight, {
          toValue: -230,
          duration: animDuration,
          useNativeDriver: true,
          easing: Easing.bezier(0.5, -0.7, 0.3, 1.6),
        }),
        Animated.timing(rectangleRefRight, {
          toValue: 0,
          duration: animDuration,
          useNativeDriver: true,
          easing: Easing.bezier(0.5, -0.7, 0.3, 1.6),
        }),
      ]),
    ).start();
    Animated.loop(
      Animated.sequence([
        Animated.timing(rectangleRefLeft, {
          toValue: 230,
          duration: animDuration,
          useNativeDriver: true,
          easing: Easing.bezier(0.5, -0.7, 0.3, 1.6),
        }),
        Animated.timing(rectangleRefLeft, {
          toValue: 0,
          duration: animDuration,
          useNativeDriver: true,
          easing: Easing.bezier(0.5, -0.7, 0.3, 1.6),
        }),
      ]),
    ).start();
    Animated.loop(
      Animated.sequence([
        Animated.timing(rectangleRefMiddle, {
          toValue: 0,
          duration: 1200,
          useNativeDriver: true,
          easing: Easing.bezier(0.5, -0.7, 0.3, 1.6),
        }),
        Animated.timing(rectangleRefMiddle, {
          toValue: 1,
          duration: 1400,
          useNativeDriver: true,
          easing: Easing.bezier(0.5, -0.7, 0.3, 1.6),
        }),
      ]),
    ).start();
  }, []);

  return (
    <View className="w-full h-full items-center justify-center bg-neutral-50 dark:bg-neutral-950">
      <View className="w-[256px] h-[256px] items-center justify-center overflow-hidden">
        <Animated.View
          className="justify-center items-center h-8 w-25 mb-5 left-10"
          style={{
            transform: [{ translateX: rectangleRefRight }],
          }}
        >
          <LinearGradient
            colors={colorScheme === 'dark' ? ['#edf115', '#1fffd7'] : ['#1FFFD7', '#623BFF']}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={{
              height: 24,
              width: 101,
              zIndex: 2,
              top: 6,
            }}
          />
          <LinearGradient
            colors={
              colorScheme === 'dark'
                ? ['rgba(237, 241, 21, 0.6)', 'rgba(31, 255, 215, 0.6)']
                : ['rgba(31, 255, 215, 0.6)', 'rgba(98, 59, 255, 0.6)']
            }
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={{
              height: 24,
              width: 101,
              zIndex: 10,
              position: 'absolute',
              top: 0,
            }}
          />
        </Animated.View>
        <Animated.View
          className="items-center justify-center h-8 w-25 mb-5"
          style={{
            opacity: rectangleRefMiddle,
          }}
        >
          <LinearGradient
            colors={colorScheme === 'dark' ? ['#edf115', '#1fffd7'] : ['#1FFFD7', '#623BFF']}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={{
              height: 24,
              width: 101,
              zIndex: 2,
              top: 6,
            }}
          />
          <LinearGradient
            colors={
              colorScheme === 'dark'
                ? ['rgba(237, 241, 21, 0.6)', 'rgba(31, 255, 215, 0.6)']
                : ['rgba(31, 255, 215, 0.6)', 'rgba(98, 59, 255, 0.6)']
            }
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={{
              height: 24,
              width: 101,
              zIndex: 10,
              position: 'absolute',
              top: 0,
            }}
          />
        </Animated.View>
        <Animated.View
          className="justify-center items-center h-8 w-25 mb-5 right-10"
          style={{
            transform: [{ translateX: rectangleRefLeft }],
          }}
        >
          <LinearGradient
            colors={colorScheme === 'dark' ? ['#edf115', '#1fffd7'] : ['#1FFFD7', '#623BFF']}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={{
              height: 24,
              width: 101,
              zIndex: 2,
              top: 6,
            }}
          />
          <LinearGradient
            colors={
              colorScheme === 'dark'
                ? ['rgba(237, 241, 21, 0.6)', 'rgba(31, 255, 215, 0.6)']
                : ['rgba(31, 255, 215, 0.6)', 'rgba(98, 59, 255, 0.6)']
            }
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={{
              height: 24,
              width: 101,
              zIndex: 10,
              position: 'absolute',
              top: 0,
            }}
          />
        </Animated.View>
      </View>
    </View>
  );
};

export default SplashScreen;
