import React, { ButtonHTMLAttributes, FC } from 'react';
import { GestureResponderEvent, Pressable, Text } from 'react-native';
import { IPaginationItem } from '../index';
import { cln } from '../../../utils/classnames';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  item: IPaginationItem;
  index: number;
  onPress: (event: GestureResponderEvent) => void;
}

const PaginationItem: FC<Props> = ({ item, index, onPress }) => {
  return (
    <Pressable
      className={cln(
        'w-9 h-9 flex items-center justify-center',
        item.isActive
          ? 'bg-neutral-950 dark:bg-neutral-50 cursor-default'
          : 'border border-neutral-950 dark:border-neutral-50 cursor-pointer',
        index > 0 && 'ml-3',
      )}
      onPress={onPress}
    >
      <Text
        className={cln(
          'flex items-center justify-center text-roundedBox font-[sans-700]',
          item.isActive
            ? 'text-neutral-50 dark:text-neutral-950'
            : 'text-neutral-950 dark:text-neutral-50',
        )}
      >
        {item.pageNumber}
      </Text>
    </Pressable>
  );
};

export default PaginationItem;
