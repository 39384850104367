import { Dimensions, Platform, Pressable, View } from 'react-native';
import React from 'react';
import { useColorScheme } from 'nativewind';
import { BlurView } from 'expo-blur';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { isMobile, isMobileWeb, isNative, isTablet, isWeb } from '../../utils/responsive';
import { Icon } from '../index';
import svgIcons from '../../assets';

const ProfileMenuHeader = ({ children, onPressLogo }) => {
  const { colorScheme } = useColorScheme();

  //To get the height of the statusbar = insets.top
  const insets = useSafeAreaInsets();

  const navHeight = isTablet
    ? insets.top + 64
    : Platform.OS === 'android'
      ? insets.top + 74
      : Platform.OS === 'ios'
        ? insets.top + 56
        : 56;

  // Content of screens, Layout component, gets a smaller padding below 768px so I added it to profile bar as well
  const isWidthBelowMedium = Dimensions.get('window').width < 768;

  return (
    <BlurView
      style={[
        {
          flexDirection: isMobile ? 'row' : 'column',
          alignItems: isMobileWeb ? 'center' : 'flex-end',
          justifyContent: isMobile ? 'space-between' : 'center',
          position: isNative ? 'absolute' : ('fixed' as 'absolute'),
          flex: 1,
          zIndex: 10,
          width: '100%',
          //On mobile we add the insets.top so the header is fully visible, this is instead of safeAreaView
          height: isMobile ? navHeight : 56,
          paddingBottom: 0,
          paddingHorizontal: isWidthBelowMedium ? 12 : 32,
          backgroundColor:
            colorScheme === 'dark' ? 'rgba(16,16,16,0.75)' : 'rgba(246,246,246,0.75)',
          borderBottomColor: colorScheme === 'dark' ? '#212121' : '#EEEEEE',
          borderBottomWidth: 1,
        }, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isWeb ? { transition: 'background-color 0.3s ease-in-out' } : null,
      ]}
      intensity={20}
      tint={colorScheme}
    >
      {/*Container for logo, notification button and profileImage button*/}
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* LOGO */}
        {isMobile && (
          <Pressable
            onPress={onPressLogo}
            style={{
              paddingTop: 12,
              paddingRight: 8,
              paddingBottom: 12,
            }}
          >
            <Icon
              icon={svgIcons.logo}
              mobileSize={{ width: 80, height: 18.5 }}
              webSize={{ width: 80, height: 18.5 }}
            />
          </Pressable>
        )}
        {/*Container for notification button and profileImage button*/}
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {children}
        </View>
      </View>
    </BlurView>
  );
};

export default ProfileMenuHeader;
