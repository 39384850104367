import React, { forwardRef, useState } from 'react';
import { View, TextInput } from 'react-native';
import Input, { InputProps } from '../Input/index';
import svgIcons from '../../assets';
import { cln } from '../../utils/classnames';
import { Icon } from '../index';

interface Props extends InputProps {
  errorMessage?: string;
}

const DatePickerInput = forwardRef<TextInput, Props>((props, ref) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <View
      className={cln(props.hidden && 'hidden')}
      style={{ position: 'relative', justifyContent: 'center' }}
    >
      <View style={{ position: 'absolute', right: 12, zIndex: 10 }}>
        <Icon
          icon={svgIcons.calendarIcon}
          mobileSize={{ width: 20, height: 20 }}
          webSize={{ width: 24, height: 24 }}
          isFocused={isFocused}
        />
      </View>
      <Input
        ref={ref}
        {...props}
        style={{ cursor: 'pointer', outline: 'none' }}
        className={cln(
          props.errorMessage && 'text-danger-500',
          isFocused
            ? 'text-neutral-50 dark:text-neutral-930'
            : 'text-neutral-930 dark:text-neutral-50',
        )}
        inputClassName={cln(
          props.errorMessage && 'border-danger-500',
          isFocused ? 'bg-neutral-950 dark:bg-neutral-50' : 'bg-none',
        )}
        onBlur={() => {
          setIsFocused(false);
        }}
        focusable={false}
        onFocus={() => {
          setIsFocused(true);
        }}
        blurOnSubmit={true}
        onSubmitEditing={() => setIsFocused(false)}
      />
    </View>
  );
});
DatePickerInput.displayName = 'DatePickerInput';
export default DatePickerInput;
