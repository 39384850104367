import React, { useEffect, useRef } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { t } from 'i18next';
import { useColorScheme } from 'nativewind';
import { Notification } from '../index';
import { useNotifications } from '../../contexts/Notification/NotificationContext';
import { isTablet } from '../../utils/responsive';

interface Props {
  notiVisible: boolean;
}

const NotificationPopover: React.FC<Props> = ({ notiVisible }) => {
  const { notifications, timeAgoCalc, fetchNotifications, setNotificationsSeen } =
    useNotifications();
  const { colorScheme } = useColorScheme();

  const containerRef = useRef(null);

  // TODO: Ne lehessen ezen a komponensen görgetve ellapozni az egész oldalt. Egyelőre nem működik még így
  useEffect(() => {
    const preventScroll = (e) => {
      if (containerRef.current.contains(e.target)) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const handleMouseEnter = () => {
      window.addEventListener('wheel', preventScroll, { passive: false });
      window.addEventListener('touchmove', preventScroll, { passive: false });
    };

    const handleMouseLeave = () => {
      window.removeEventListener('wheel', preventScroll);
      window.removeEventListener('touchmove', preventScroll);
    };

    const container = containerRef.current;

    if (container && !isTablet && renderedNotifications.length <= 5) {
      container.addEventListener('mouseenter', handleMouseEnter);
      container.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (container && !isTablet && renderedNotifications.length <= 5) {
        container.removeEventListener('mouseenter', handleMouseEnter);
        container.removeEventListener('mouseleave', handleMouseLeave);
      }
      if (!isTablet) {
        window.removeEventListener('wheel', preventScroll);
        window.removeEventListener('touchmove', preventScroll);
      }
    };
  }, []);

  useEffect(() => {
    timeAgoCalc();
    return () => {
      seenNotification();
    };
  }, [notiVisible]);

  const seenNotification = async () => {
    if (notifications?.some((notification) => !notification.seenAtUtc)) {
      await setNotificationsSeen();
      fetchNotifications();
    }
  };

  const renderedNotifications = notifications?.map((notification) => {
    return <Notification key={notification.id} notificationData={notification} />;
  });

  return renderedNotifications.length > 0 ? (
    <ScrollView
      style={{
        display: notiVisible ? 'flex' : 'none',
        position: 'absolute',
        right: 0,
        zIndex: 0,
        top: 56,
        width: 330,
        maxHeight: 396,
        flexDirection: 'column',
        backgroundColor: colorScheme === 'dark' ? '#000' : '#fff',
      }}
      ref={containerRef}
      persistentScrollbar={true}
      contentContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-start' }}
      indicatorStyle={colorScheme === 'dark' ? 'white' : 'black'}
    >
      {renderedNotifications}
    </ScrollView>
  ) : (
    <View
      style={{
        display: notiVisible ? 'flex' : 'none',
        position: 'absolute',
        right: 0,
        zIndex: 0,
        top: 56,
        width: 330,
        minHeight: 264,
        backgroundColor: colorScheme === 'dark' ? '#000' : '#fff',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      ref={containerRef}
    >
      <Text
        style={{
          fontFamily: 'sans-400',
          fontSize: 16,
          lineHeight: 20,
          color: colorScheme === 'dark' ? '#edf115' : '#967CFF',
        }}
      >
        {t('notifications:no_new_notifications')},
      </Text>
      <Text
        style={{
          fontFamily: 'sans-400',
          fontSize: 16,
          lineHeight: 20,
          color: colorScheme === 'dark' ? '#f6f6f6' : '#101010',
        }}
      >
        {t('notifications:check_back_later')}
      </Text>
    </View>
  );
};
export default NotificationPopover;
