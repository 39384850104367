import React, { useEffect, useRef } from 'react';
import { View, StyleSheet, Animated, Easing } from 'react-native';
import { useColorScheme } from 'nativewind';
import { PropsWithClassName } from '../../resources/interfaces';

const Loader: React.FC<PropsWithClassName> = ({ classNames }) => {
  const { colorScheme } = useColorScheme();
  const animationValue = useRef(new Animated.Value(0)).current;
  const squareCount = 5;

  useEffect(() => {
    const cubicBezierTiming = Easing.bezier(0.76, 0, 0.24, 1);

    Animated.loop(
      Animated.sequence([
        Animated.timing(animationValue, {
          toValue: 1,
          duration: 500,
          easing: cubicBezierTiming,
          useNativeDriver: true,
        }),
        Animated.timing(animationValue, {
          toValue: 0,
          duration: 500,
          easing: cubicBezierTiming,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  }, []);

  const squares = Array(squareCount)
    .fill(0)
    .map((_, index) => {
      const scaleY = animationValue.interpolate({
        inputRange: [0, 1],
        outputRange: [1 - index * 0.25, index * 0.25],
      });

      return (
        <Animated.View
          key={index}
          className={classNames}
          style={[
            styles.lineSquare,
            {
              transform: [{ scaleY }],
              backgroundColor: colorScheme === 'light' ? '#101010' : '#F6F6F6',
            },
          ]}
        />
      );
    });

  return <View style={styles.container}>{squares}</View>;
};
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    fontSize: 16,
  },
  lineSquare: {
    width: 40,
    height: 40,
  },
});

export default Loader;
