import AsyncStorage from '@react-native-async-storage/async-storage';

export const saveLocalStorage = async (key, value) => {
  try {
    await AsyncStorage.setItem(`${key}`, value);
  } catch (error) {
    console.error('The following error occured: ', error);
  }
};

export const loadLocalStorage = async (key, setter, initial = null) => {
  try {
    const storedData = await AsyncStorage.getItem(key);
    if (!storedData) {
      setter(initial);
    } else {
      setter(storedData);
    }
  } catch (error) {
    console.error(`Error loading ${key}:`, error);
    return null;
  }
};
