import React, { useState } from 'react';
import { View, Text, TextInput, TextInputProps, StyleProp, ViewProps } from 'react-native';
import { ErrorMessage, useField } from 'formik';
import { useColorScheme } from 'nativewind';
import { cln } from '../../utils/classnames';
import Icon from '../Icon';
import { SvgIcon } from '../../assets';

interface Props extends TextInputProps {
  title: string;
  name: string;
  required?: boolean;
  style?: StyleProp<ViewProps>;
  trailingIcon?: SvgIcon;
}

const FormInput: React.FC<Props> = ({
  title,
  name,
  required = false,
  style,
  trailingIcon,
  ...props
}) => {
  const { colorScheme } = useColorScheme();
  const [field, meta, helpers] = useField(name);

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const isDarkMode = colorScheme === 'dark';
  const needsDarkBackground = isDarkMode === isFocused;

  return (
    <View className="mb-11 relative" style={style}>
      <View className="flex-row">
        <Text className="text-input text-neutral-950 dark:text-neutral-50 font-[sans-600]">
          {title}
        </Text>
        {required && <Text className="text-input text-danger-500 font-[sans-400]"> *</Text>}
      </View>

      <View className="relative">
        <TextInput
          value={field.value ? field.value : ''}
          className={cln(
            isFocused
              ? 'text-neutral-50 bg-neutral-950 dark:text-neutral-950 dark:bg-neutral-50'
              : 'text-neutral-950 bg-neutral-50 dark:text-neutral-50 dark:bg-neutral-950',
            !!meta.touched && !!meta.error
              ? 'border-danger-500'
              : 'border-neutral-900 dark:border-neutral-200',
            'h-12 border-[1px] flex-1 p-3  outline-none',
          )}
          style={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            outline: 'none',
          }}
          keyboardType="ascii-capable"
          placeholderTextColor={needsDarkBackground ? '#101010' : '#888888'}
          onChangeText={helpers.setValue}
          onBlur={() => {
            setIsFocused(false);
            field.onBlur(name);
          }}
          focusable={false}
          onFocus={() => {
            setIsFocused(true);
          }}
          blurOnSubmit={true}
          onSubmitEditing={() => setIsFocused(false)}
          tabIndex={0}
          {...props}
        />
        {trailingIcon && (
          <Icon
            classNames="absolute right-3 top-[9px]"
            icon={trailingIcon}
            mobileSize={{ width: 24, height: 24 }}
            webSize={{ width: 24, height: 24 }}
          />
        )}
      </View>
      <ErrorMessage name={name}>
        {(message) => (
          <Text
            className="text-notificationMessage text-danger-500 font-[sans-600] absolute"
            style={{ top: props.multiline ? 136 : 68 }}
          >
            {message}
          </Text>
        )}
      </ErrorMessage>
    </View>
  );
};

export default FormInput;
