import React, { useEffect } from 'react';
import { Text, View, ScrollView } from 'react-native';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/index';
import { Notification } from '../../components';
import { useNotifications } from '../../contexts/Notification/NotificationContext';
import { cln } from '../../utils/classnames';

const NotificationsScreen = ({ onPressBackButton }) => {
  const { t } = useTranslation();
  const { notifications, fetchNotifications, timeAgoCalc, setNotificationsSeen } =
    useNotifications();

  useEffect(() => {
    timeAgoCalc();

    return () => {
      seenNotification();
    };
  }, []);

  const seenNotification = async () => {
    if (notifications?.some((notification) => !notification.seenAtUtc)) {
      await setNotificationsSeen();
      fetchNotifications();
    }
  };

  const renderedNotifications = notifications?.map((notification) => {
    return <Notification key={notification.id} notificationData={notification} />;
  });

  return (
    <Layout
      title={t('navigation:notifications')}
      isScrollView={false}
      onPressBackButton={onPressBackButton}
    >
      <ScrollView
        className={cln(
          renderedNotifications.length > 0 ? 'flex-start' : 'items-center justify-center',
        )}
      >
        {renderedNotifications.length > 0 ? (
          renderedNotifications
        ) : (
          <>
            <Text className="font-[sans-400] text-primaryLight-500 dark:text-primary-500 text-lead">
              {t('notifications:no_new_notifications')},
            </Text>
            <Text className="font-[sans-400] text-neutral-950 dark:text-neutral-50 text-lead">
              {t('notifications:check_back_later')}!
            </Text>
          </>
        )}
      </ScrollView>
    </Layout>
  );
};

export default NotificationsScreen;
