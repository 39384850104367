import React, { useCallback, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect, useIsFocused } from '@react-navigation/core';
import { ErrorMessage, Field, Formik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import { isEmpty } from 'lodash';
import Layout from '../../../components/Layout';
import {
  NavigationParamList,
  ScreenName,
  TabNavigationScreenProps,
} from '../../../navigation/types';
import { useAuth } from '../../../contexts/AuthContext/AuthContext';
import {
  AdminExtremeEventCreate,
  AdminPostReadDetail,
  ExtremeEventTranslationRole,
  FileModuleType,
  Role,
  Translation,
} from '../../../client/interfaces';
import {
  Button,
  FileUpload,
  FormInput,
  Modal,
  FormDate,
  TimeInput,
  FormErrorNotification,
} from '../../../components';
import { useAdmin } from '../../../contexts/Admin/AdminContext';
import { useMessage } from '../../../contexts/Messages/MessageContext';
import client from '../../../client/client';
import { getTranslation } from '../../../utils/translations';
import {
  DeepPartial,
  Language,
  FileUploadStatus,
  UploadedFile,
} from '../../../resources/interfaces';
import { extensionImageWhitelist } from '../../../components/FileUpload/helpers';
import { readFileUrl } from '../../../utils/file';

const MAX_FILE_SIZE = 1000 * 1000; // 1 MB

type ExtremeEventSchemaTranslations = {
  [key in Language]: { [key in ExtremeEventTranslationRole]: string };
};

/* eslint-disable camelcase */
interface ExtremeEventSchema {
  translations: ExtremeEventSchemaTranslations;
  published: boolean;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  displayFromDate: string;
  displayFromTime: string;
  fileId?: number;
  files?: { [k: string]: UploadedFile };
}
const emptyFormValues: ExtremeEventSchema = {
  translations: {
    hu: {
      title: '',
      description: '',
    },
    en: {
      title: '',
      description: '',
    },
  },
  published: false,
  startDate: dayjs(Date.now()).format('YYYY-MM-DD'),
  endDate: dayjs(Date.now()).format('YYYY-MM-DD'),
  startTime: '',
  endTime: '',
  displayFromDate: dayjs(Date.now()).format('YYYY-MM-DD'),
  displayFromTime: '',
  fileId: null,
  files: undefined,
};

const AdminExtremeEventEditScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.AdminExtremeEventEditScreen>
> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { me, hasRole } = useAuth();
  const { isBackModalOpen, setIsBackModalOpen } = useAdmin();
  const isFocused = useIsFocused();

  const [initialFormValues, setInitialFormValues] = useState<ExtremeEventSchema>(emptyFormValues);
  const { setMessage } = useMessage();

  const isNewExtremeEvent = !route?.params?.postId;
  const requiredTranslation = t('admin:common:required_field');
  const tooLongTranslation = (max) => t('admin:common:max_characters', { max });

  const requiredTogetherValidation = (otherFields, schema) => {
    const anyOtherFieldHasValue = otherFields.length && otherFields.some((field) => !!field);

    if (anyOtherFieldHasValue) {
      return schema.required(requiredTranslation);
    }

    return schema;
  };

  const extremeEventSchema = Yup.lazy((): Yup.ObjectSchema<DeepPartial<ExtremeEventSchema>> => {
    return Yup.object().shape(
      {
        fileId: Yup.number().nullable(),
        files: Yup.object(),
        translations: Yup.object().shape({
          hu: Yup.object().shape({
            title: Yup.string().required(requiredTranslation).max(100, tooLongTranslation(100)),
            description: Yup.string()
              .required(requiredTranslation)
              .max(3000, tooLongTranslation(3000)),
          }),
          en: Yup.object().shape({
            title: Yup.string().required(requiredTranslation).max(100, tooLongTranslation(100)),
            description: Yup.string()
              .required(requiredTranslation)
              .max(3000, tooLongTranslation(3000)),
          }),
        }),
        published: Yup.boolean(),
        startDate: Yup.string().required(requiredTranslation),
        endDate: Yup.string().test(
          'is-valid-end-date',
          t('error_messages:end_date_must_after'),
          function (value) {
            const { startDate } = this.parent;
            if (!startDate || !value) return true;

            if (dayjs(value).isAfter(dayjs(startDate))) return true;
            return dayjs(value).isSame(dayjs(startDate));
          },
        ),
        startTime: Yup.string()
          .min(5, t('error_messages:time_format'))
          .when(['endTime'], requiredTogetherValidation),
        endTime: Yup.string()
          .min(5, t('error_messages:time_format'))
          .test('is-valid-end-time', t('error_messages:end_date_must_after'), function (value) {
            const { startDate, startTime, endDate } = this.parent;
            if (!startTime || !value) return true;
            // Check if startDate and endDate are the same and endTime is sooner than startTime
            if (dayjs(startDate).isSame(dayjs(endDate)) && startTime && value) {
              return value > startTime;
            }
            // If startDate is later than endDate, do not generate an error
            if (dayjs(startDate).isAfter(dayjs(endDate))) {
              return true;
            }
            return true; // Default case, no error generated
          })
          .when(['startTime'], requiredTogetherValidation),
        displayFromDate: Yup.string()
          .min(5, t('error_messages:time_format'))
          .test('is-valid-end-date', t('error_messages:display_from_must_after'), function (value) {
            if (!value) return true;
            if (dayjs(value).isAfter(dayjs(Date.now()).format('YYYY-MM-DD'))) return true;
            return dayjs(value).isSame(dayjs(Date.now()).format('YYYY-MM-DD'));
          }),
        displayFromTime: Yup.string()
          .min(5, t('error_messages:time_format'))
          .required(requiredTranslation)
          .test('is-valid-end-time', t('error_messages:display_from_must_after'), function (value) {
            const { displayFromDate } = this.parent;
            if (!value) return true;

            if (dayjs(displayFromDate).isSame(dayjs(Date.now()).format('YYYY-MM-DD')) && value) {
              return value > dayjs(Date.now()).format('HH:mm');
            }

            return true; // Default case, no error generated
          }),
      },
      [['startTime', 'endTime']],
    );
  });

  const transformExtremeEventToSchema = async (
    extremeEvent: AdminPostReadDetail,
  ): Promise<ExtremeEventSchema> => {
    let file: File;

    if (extremeEvent.fileId) {
      file = await readFileUrl(extremeEvent.imageUrl);
    }

    return {
      translations: {
        hu: {
          title: getTranslation(
            extremeEvent.allTranslations,
            ExtremeEventTranslationRole.title,
            Language.hu,
          ),
          description: getTranslation(
            extremeEvent.allTranslations,
            ExtremeEventTranslationRole.description,
            Language.hu,
          ),
        },
        en: {
          title: getTranslation(
            extremeEvent.allTranslations,
            ExtremeEventTranslationRole.title,
            Language.en,
          ),
          description: getTranslation(
            extremeEvent.allTranslations,
            ExtremeEventTranslationRole.description,
            Language.en,
          ),
        },
      },
      published: !!extremeEvent.publishedAtUtc,
      startDate: extremeEvent.extremeEvent.startDate,
      endDate: extremeEvent.extremeEvent.endDate,
      startTime: extremeEvent.extremeEvent.startTime || '',
      endTime: extremeEvent.extremeEvent.endTime || '',
      displayFromDate: extremeEvent.extremeEvent.displayFromDate,
      displayFromTime: extremeEvent.extremeEvent.displayFromTime,
      fileId: extremeEvent.fileId,
      files: file
        ? {
            [file.name]: {
              id: extremeEvent.fileId,
              status: FileUploadStatus.done,
              src: extremeEvent.imageUrl,
              file,
            },
          }
        : {},
    };
  };

  const transformSchemaToExtremeEvent = (values: ExtremeEventSchema): AdminExtremeEventCreate => {
    const {
      translations: translationValues,
      published,
      startDate,
      endDate,
      startTime,
      endTime,
      displayFromDate,
      displayFromTime,
      fileId,
    } = values;

    const translations = Object.entries(translationValues).flatMap((v) => {
      const [lang, trans] = v;
      return Object.entries(trans)
        .map((t) => {
          const [key, value] = t;
          return { language: lang, value, role: key };
        })
        .filter((t) => !!t.value);
    });

    return {
      translations: translations as Translation<ExtremeEventTranslationRole>[],
      published,
      startDate,
      endDate,
      startTime: startTime || null,
      endTime: endTime || null,
      displayFromDate,
      displayFromTime,
      fileId,
    };
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const extremeEvent = transformSchemaToExtremeEvent(values);

      if (isNewExtremeEvent) {
        await client.createExtremeEvent(extremeEvent);
      } else {
        await client.updateExtremeEvent(route.params.postId, extremeEvent);
      }

      setSubmitting(false);
      resetForm({ values: emptyFormValues });
      navigation.navigate(ScreenName.AdminExtremeEventListScreen);
    } catch (e) {
      setMessage({ message: e.message, type: 'error' });
    }
  };

  // Role guard
  useFocusEffect(
    useCallback(() => {
      if (me && !hasRole(Role.extremeEventAdmin)) {
        navigation.navigate(ScreenName.HomeScreen);
      }
    }, [me?.roleList]),
  );

  useFocusEffect(
    useCallback(() => {
      window.scrollTo({ top: 0, behavior: 'instant' });

      const fetchExtremeEvent = async () => {
        try {
          const extremeEventRes = await client.getAdminExtremeEvent(route.params.postId);
          const transformed = await transformExtremeEventToSchema(extremeEventRes);
          setInitialFormValues(transformed);
        } catch (e) {
          setMessage({ message: e.message, type: 'error' });
          navigation.navigate(ScreenName.AdminExtremeEventListScreen);
        }
      };

      if (route?.params?.postId) {
        fetchExtremeEvent();
      } else {
        setInitialFormValues(emptyFormValues);
      }
    }, [route?.params?.postId]),
  );

  return (
    <Layout
      title={
        isNewExtremeEvent
          ? t('admin:extreme_event_edit:create_title')
          : t('admin:extreme_event_edit:edit_title')
      }
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={extremeEventSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          isSubmitting,
          resetForm,
          validateForm,
          dirty,
        }) => {
          return (
            <View className="flex-1">
              <FormErrorNotification />
              <Field name="files">
                {({ field, meta }) => (
                  <View className="mb-11">
                    <View className="flex-row mb-1">
                      <Text className="text-input text-neutral-950 dark:text-neutral-50 font-[sans-600]">
                        {t('admin:common:image')}
                      </Text>
                    </View>
                    <FileUpload
                      files={field.value}
                      setValue={(value) => {
                        setFieldValue(field.name, value);

                        const id = Object.values(value)[0]?.id;

                        if (id) {
                          setFieldValue('fileId', id);
                        }
                      }}
                      module={FileModuleType.extremeEvent}
                      hasError={!!(meta.touched && meta.error)}
                      accepted={extensionImageWhitelist}
                      maxSize={MAX_FILE_SIZE}
                    />
                    <ErrorMessage name={field.name}>
                      {(message) => (
                        <Text className="text-notificationMessage text-danger-500 font-[sans-600] absolute bottom-[-20px]">
                          {message}
                        </Text>
                      )}
                    </ErrorMessage>
                  </View>
                )}
              </Field>
              <View className="flex-1 flex-row gap-x-20 mb-10 z-0">
                <View className="flex-1 z-20">
                  <FormInput
                    name="translations.hu.title"
                    title={t('admin:extreme_event_edit:title')}
                    placeholder={t('admin:common:max_characters', { max: 100 })}
                    required
                  />
                  <FormInput
                    name="translations.hu.description"
                    title={t('admin:extreme_event_edit:description')}
                    placeholder={t('admin:common:max_characters', { max: 3000 })}
                    required
                    multiline={true}
                    numberOfLines={5}
                  />
                  <View
                    style={{
                      gap: 24,
                      flexDirection: 'row',
                      marginTop: 24,
                      flexWrap: 'nowrap',
                    }}
                  >
                    <FormDate
                      name="startDate"
                      title={t('admin:extreme_event_edit:start_date')}
                      multiline={false}
                      required
                      style={{ width: 272 }}
                    />
                    <TimeInput
                      name="startTime"
                      title={t('admin:extreme_event_edit:time')}
                      multiline={false}
                      style={{ width: 254 }}
                    />
                  </View>
                  <View
                    style={{
                      gap: 24,
                      flexDirection: 'row',
                    }}
                  >
                    <FormDate
                      name="endDate"
                      title={t('admin:extreme_event_edit:end_date')}
                      multiline={false}
                      required
                      style={{ width: 272 }}
                    />
                    <TimeInput
                      name="endTime"
                      title={t('admin:extreme_event_edit:time')}
                      multiline={false}
                      style={{ width: 254 }}
                    />
                  </View>
                  <View
                    style={{
                      gap: 24,
                      flexDirection: 'row',
                    }}
                  >
                    <FormDate
                      name="displayFromDate"
                      title={t('admin:extreme_event_edit:display_from_date')}
                      multiline={false}
                      required
                      style={{ width: 272 }}
                      helperText={t('admin:extreme_event_edit:notify_users_from')}
                    />

                    <TimeInput
                      name="displayFromTime"
                      title={t('admin:extreme_event_edit:time')}
                      multiline={false}
                      style={{ width: 254 }}
                      required
                    />
                  </View>
                </View>
                <View className="flex-1">
                  <FormInput
                    name="translations.en.title"
                    title={t('admin:extreme_event_edit:title_en')}
                    placeholder={t('admin:common:max_characters', { max: 100 })}
                    required
                  />
                  <FormInput
                    name="translations.en.description"
                    title={t('admin:extreme_event_edit:description_en')}
                    placeholder={t('admin:common:max_characters', { max: 3000 })}
                    required
                    multiline={true}
                    numberOfLines={5}
                  />
                </View>
              </View>
              <View className="self-end flex-row gap-x-6 z-0">
                <Button
                  text={t('admin:common:exit_from_edit')}
                  size="medium"
                  variant="link"
                  onPress={() => {
                    setIsBackModalOpen(true);
                  }}
                  disabled={isSubmitting}
                />
                {!values.published && (
                  <Button
                    text={t('admin:common:save_as_draft')}
                    size="medium"
                    variant="outline"
                    onPress={async () => {
                      await setFieldValue('published', false);
                      handleSubmit();
                    }}
                    disabled={isSubmitting || !dirty}
                  />
                )}
                <Button
                  text={
                    values.published ? t('admin:common:save_changes') : t('admin:common:publish')
                  }
                  size="medium"
                  variant="fill"
                  onPress={async () => {
                    const validationErrors = await validateForm(values);
                    isEmpty(validationErrors) && (await setFieldValue('published', true));
                    handleSubmit();
                  }}
                  disabled={isSubmitting || (!dirty && (isNewExtremeEvent || values.published))}
                />
              </View>
              <Modal
                showModal={isBackModalOpen && isFocused}
                title={t('admin:common:exit_warning')}
                callback={() => {
                  setIsBackModalOpen(false);
                  resetForm({ values: emptyFormValues });
                  navigation.navigate(ScreenName.AdminExtremeEventListScreen);
                }}
                closeModal={() => {
                  setIsBackModalOpen(false);
                }}
                yesText={t('admin:common:exit')}
                cancelText={t('admin:common:stay')}
                instantCallback={!dirty}
              />
            </View>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default AdminExtremeEventEditScreen;
