import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import client from '../../client/client';
import { ExtremeEvent } from '../../client/interfaces';
import { useMessage } from '../Messages/MessageContext';

interface ExtremeEventContext {
  extremeEvents: ExtremeEvent[];
  fetchExtremeEvents: () => Promise<void>;
}

const Context = createContext<ExtremeEventContext>({
  extremeEvents: [],
  fetchExtremeEvents: async () => {
    return;
  },
});

export const useExtremeEvents = () => useContext(Context);

export const ExtremeEventProvider: FC<PropsWithChildren> = ({ children }) => {
  const { setMessage } = useMessage();
  const [extremeEvents, setExtremeEvents] = useState<ExtremeEvent[]>([]);

  const fetchExtremeEvents = async () => {
    try {
      const extremeEventsResponse = await client.getExtremeEventUnseen();
      setExtremeEvents(extremeEventsResponse);
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
  };

  const value: ExtremeEventContext = useMemo(
    () => ({
      extremeEvents: extremeEvents || [],
      fetchExtremeEvents,
    }),
    [extremeEvents],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
