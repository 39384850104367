import navigationConfig from './navigationConfig';

const screens = navigationConfig.reduce((acc, screen) => {
  return { ...acc, [screen.name]: screen.route };
}, {});

export default {
  prefixes: ['ulp.mome://', 'https://ulp.mome.hu', 'https://*.ulp.mome.hu'], // TODO: Expo universal links
  config: {
    screens: screens,
  },
};
