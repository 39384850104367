import React, { useState } from 'react';
import { View, Text, Pressable, GestureResponderEvent, StyleProp, ViewStyle } from 'react-native';
import { SvgIcon } from '../../assets';
import Icon from '../Icon';
import { cln } from '../../utils/classnames';
import { isTablet } from '../../utils/responsive';

interface Props {
  icon: SvgIcon;
  title: string;
  onPress: (event: GestureResponderEvent) => void;
  style?: StyleProp<ViewStyle>;
}

const IconCard: React.FC<Props> = ({ icon, title, onPress, style }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const styles = {
    base: 'h-[128px] md:h-[200px]',
    default: 'border-2 border-neutral-950 dark:border-neutral-50',
    hovered:
      'border-2 bg-neutral-200 dark:bg-neutral-700 border-neutral-950 dark:border-neutral-50',
    pressed:
      'border-[3px] bg-neutral-300 dark:bg-neutral-930 border-neutral-950 dark:border-neutral-50',
  };

  let finalStyle = styles.default;

  if (true === isActive) {
    finalStyle = styles.pressed;
  } else if (true === isHovered || isFocused) {
    finalStyle = styles.hovered;
  }

  return (
    <View className={cln(styles.base, finalStyle)} style={style}>
      <Pressable
        className="flex-1"
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => {
          setIsFocused(false);
          setIsHovered(false);
        }}
        onPress={onPress}
        onPressIn={() => setIsActive(true)}
        onPressOut={() => setIsActive(false)}
      >
        <View className="flex-1 flex-col items-center justify-center px-2">
          <Icon
            icon={icon}
            mobileSize={{ width: isTablet ? 48 : 32, height: isTablet ? 48 : 32 }}
            webSize={{ width: 80, height: 80 }}
          />
          <Text className="text-lead text-center text-neutral-950 dark:text-neutral-50 font-[sans-600] mt-4">
            {title}
          </Text>
        </View>
      </Pressable>
    </View>
  );
};

export default IconCard;
