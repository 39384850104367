import React from 'react';
import { useColorScheme } from 'nativewind';
import RadioGroup, { RadioGroupProps } from '../ReactNativeRadioButtonsGroup';

const CustomRadioGroup: React.FC<RadioGroupProps> = ({ ...props }) => {
  const { colorScheme } = useColorScheme();

  props.radioButtons = props.radioButtons.map((radio) => ({
    borderColor: colorScheme === 'dark' ? '#f6f6f6' : '#101010',
    color: colorScheme === 'dark' ? '#f6f6f6' : '#101010',
    size: 24,
    borderSize: 1,
    innerCircleSize: 18,
    ...radio,
  }));

  return (
    <RadioGroup
      labelStyle={{
        marginLeft: 8,
        marginRight: 32,
        fontFamily: 'sans-400',
        fontSize: 14,
        lineHeight: 20,
        color: colorScheme === 'dark' ? '#f6f6f6' : '#101010',
      }}
      {...props}
    />
  );
};

export default CustomRadioGroup;
