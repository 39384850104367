import React, { FC, useEffect, useRef } from 'react';
import { Image, View, Text, Pressable } from 'react-native';
import filter from 'lodash/filter';
import values from 'lodash/values';
import { useTranslation } from 'react-i18next';
import { FileUploadStatus, UploadedFile } from '../../resources/interfaces';
import { FileModuleType } from '../../client/interfaces';
import { cln } from '../../utils/classnames';
import { handleLinkPress } from '../../utils';
import { isWeb } from '../../utils/responsive';
import { UseFileUpload } from './useFileUpload';
import { DropZone } from './DropZone';
import { UploadTask } from './UploadTask';

interface Props {
  className?: string;
  files: { [k: string]: UploadedFile };
  setValue: (value: unknown) => void;
  module: FileModuleType;
  accepted: string[];
  maxSize?: number;
  hasError?: boolean;
}

const FileUpload: FC<Props> = ({
  className,
  files,
  setValue,
  module,
  accepted,
  maxSize,
  hasError = false,
}) => {
  const { t } = useTranslation();
  const filesRef = useRef<Record<string, UploadedFile>>(files || {});
  const { handleFiles, loadFiles, ...uploadFileHook } = UseFileUpload(
    filesRef,
    files,
    setValue,
    module,
    maxSize,
  );

  useEffect(() => {
    loadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    isWeb && (
      <View className={cln('flex', className)}>
        {values(files).length > 0 && (
          <View className="flex flex-wrap mb-5">
            {filter(values(files), (r) => r?.file?.type?.includes('image'))?.map(
              ({ file, status, src }) =>
                file && (
                  <Image
                    key={file.name}
                    source={{ uri: src }}
                    alt="image"
                    style={{ width: 640, height: 480 }}
                    className={cln(status === FileUploadStatus.inProgress && 'blur-sm')}
                    resizeMode={'cover'}
                  />
                ),
            )}
          </View>
        )}
        {values(files).map(
          (record) =>
            !!record && <UploadTask record={record} key={record?.file?.name} {...uploadFileHook} />,
        )}
        {values(files).length === 0 && (
          <View>
            <DropZone onDrop={handleFiles} hasError={hasError} accepted={accepted} />
            {/* Image hint */}
            <View className="flex flex-row items-start mt-1">
              <Text className="font-[sans-600] text-xs text-neutral-950 dark:text-neutral-50">
                {t('admin:common:image_hint_start')}
              </Text>
              <Pressable onPress={() => handleLinkPress(t('admin:common:image_hint_url'))}>
                <Text className="font-[sans-600] text-xs text-primary-600 dark:text-primary-500 underline">
                  {t('admin:common:image_hint_link')}
                </Text>
              </Pressable>
              <Text className="font-[sans-600] text-xs text-neutral-950 dark:text-neutral-50">
                {t('admin:common:image_hint_end')}
              </Text>
            </View>
          </View>
        )}
      </View>
    )
  );
};

FileUpload.displayName = 'FileUpload';
export default FileUpload;
