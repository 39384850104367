import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface AdminContext {
  isBackModalOpen: boolean;
  setIsBackModalOpen: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext<AdminContext>({
  isBackModalOpen: false,
  setIsBackModalOpen: () => {},
});

export const useAdmin = () => useContext(Context);

export const AdminProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isBackModalOpen, setIsBackModalOpen] = useState<boolean>(false);

  const value: AdminContext = {
    isBackModalOpen,
    setIsBackModalOpen,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
