import React, { useState, useEffect } from 'react';
import { Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useColorScheme } from 'nativewind';
import { ColorSchemeSystem } from 'nativewind/dist/style-sheet/color-scheme';
import { ToggleWithText } from '../../components/index';
import { ThemeOption } from '../../resources/interfaces';
import { loadLocalStorage, saveLocalStorage } from '../../utils/localStorage';

const ThemeChanger: React.FC = () => {
  const { colorScheme, setColorScheme } = useColorScheme();
  const { t } = useTranslation();
  const [themeOptions] = useState([ThemeOption.dark, ThemeOption.light]);

  // Load from local storage on first render if there is anything saved already if not provide an initial value
  useEffect(() => {
    loadLocalStorage('themeOption', setColorScheme, ThemeOption.dark);
  }, []);

  function handleThemeChange(currentOption: ColorSchemeSystem) {
    setColorScheme(currentOption);
    // Save chosen theme to device
    saveLocalStorage('themeOption', currentOption);
  }

  return (
    <View className="md:flex-1 md:ml-10">
      <Text className="text-sectionSubtitle font-[sans-700] text-neutral-950 dark:text-neutral-50">
        {t('common:theme')}
      </Text>
      <View className="flex-row">
        <ToggleWithText
          onPress={handleThemeChange}
          activeOption={colorScheme}
          options={themeOptions.map((item) => {
            return { key: item, label: item };
          })}
        />
      </View>
    </View>
  );
};

export default ThemeChanger;
