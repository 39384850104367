import { ModuleKey } from '../client/interfaces';
import { Screen, ScreenName } from './types';

const navigationConfig: Screen[] = [
  // Main Navigator
  {
    name: ScreenName.HomeScreen,
    route: '',
    translation: 'navigation:home',
  },
  {
    name: ScreenName.ArticlesScreen,
    route: 'feed',
    translation: 'navigation:articles',
    moduleKey: ModuleKey.feed,
  },
  {
    name: ScreenName.EventsScreen,
    route: 'events',
    translation: 'navigation:events',
    moduleKey: ModuleKey.event,
  },
  {
    name: ScreenName.PlatformsScreen,
    route: 'platforms',
    translation: 'navigation:platforms',
  },

  // Profile Navigator
  {
    name: ScreenName.AdminIndexScreen,
    route: 'admin',
    translation: 'navigation:admin_index',
  },
  {
    name: ScreenName.SettingsScreen,
    route: 'settings',
    translation: 'navigation:settings',
  },

  //Screens outside navigator
  {
    name: ScreenName.ProfileScreen,
    route: 'profile',
    translation: 'navigation:profile',
  },
  {
    name: ScreenName.ArticleScreen,
    route: 'article',
    translation: 'navigation:article',
  },
  {
    name: ScreenName.EventScreen,
    route: 'event',
    translation: 'navigation:event',
  },
  {
    name: ScreenName.FavoritesScreen,
    route: 'favorites',
    translation: 'navigation:favorites',
  },
  {
    name: ScreenName.OnboardingScreen,
    route: 'onboarding',
    translation: 'navigation:onboarding',
  },
  {
    name: ScreenName.ErrorScreen,
    route: 'error',
    translation: 'navigation:error',
  },
  {
    name: ScreenName.NotFound,
    route: '*',
    translation: 'navigation:error',
  },

  // Admin
  {
    name: ScreenName.AdminArticleListScreen,
    route: 'admin/articles/list',
    translation: 'navigation:admin_article_list',
  },
  {
    name: ScreenName.AdminArticleEditScreen,
    route: 'admin/articles/edit',
    translation: 'navigation:admin_article_edit',
  },
  {
    name: ScreenName.AdminExtremeEventListScreen,
    route: 'admin/extreme-events/list',
    translation: 'navigation:admin_extreme_event_list',
  },
  {
    name: ScreenName.AdminExtremeEventEditScreen,
    route: 'admin/extreme-events/edit',
    translation: 'navigation:admin_extreme_event_edit',
  },
  {
    name: ScreenName.AdminEventEditScreen,
    route: 'admin/events/edit',
    translation: 'navigation:admin_event_edit',
  },
  {
    name: ScreenName.AdminEventListScreen,
    route: 'admin/events/list',
    translation: 'navigation:admin_event_list',
  },
];

export default navigationConfig;
