import { Pressable, View, Text } from 'react-native';
import { CommonActions } from '@react-navigation/native';
import { useColorScheme } from 'nativewind';
import { useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { isMobile, isMobileWeb, isTablet } from '../utils/responsive';
import { Icon } from '../components/index';
import svgIcons from '../assets/index';
import { useAdmin } from '../contexts/Admin/AdminContext';
import { Navigator, ScreenName } from './types';

const screensWithBackMenu = [
  ScreenName.AdminArticleEditScreen,
  ScreenName.AdminExtremeEventEditScreen,
  ScreenName.AdminEventEditScreen,
];

const MainMenu = ({ state, navigation, descriptors }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const { colorScheme } = useColorScheme();
  const { setIsBackModalOpen } = useAdmin();

  const isScreenWithBackMenu = screensWithBackMenu.includes(state.routes[state.index].name);

  const [backButtonHovered, setBackButtonHovered] = useState(false);
  const insets = useSafeAreaInsets();

  return (
    <View
      style={[
        isMobile
          ? {
              flexDirection: 'row',
              alignItems: 'flex-start',
              bottom: 0,
              zIndex: 20,
              width: '100%',
              paddingBottom: isTablet ? insets.bottom + 8 : insets.bottom,
              backgroundColor: colorScheme === 'dark' ? '#000' : '#fff',
            }
          : {
              flexDirection: 'column',
              position: 'fixed' as 'absolute',
              left: 0,
              flex: 1,
              height: '100%',
              zIndex: 20,
              width: 100,
            },
      ]}
    >
      {state.routes.map((route, index) => {
        const isFocused = state.index === index;
        const isHovered = hoveredItem === route.key;
        const iconName = route.name.split('/')[1];
        const transitionValue = 'all 0.3s ease-in-out';

        let backgroundColor: string;
        let menuButtonTextColor: string;
        // Dark theme menu button and text color
        if (colorScheme === 'dark' && !isFocused) {
          backgroundColor = isHovered || backButtonHovered ? 'rgba(246,246,246,0.1)' : '#000000';
          menuButtonTextColor = '#fff';
        } else if (colorScheme === 'dark' && isFocused) {
          backgroundColor = '#fff';
          menuButtonTextColor = '#101010';
        }

        // Light theme menu button and text color
        if (colorScheme === 'light' && !isFocused) {
          backgroundColor = isHovered || backButtonHovered ? 'rgba(16,16,16,0.1)' : '#ffffff';
          menuButtonTextColor = '#101010';
        } else if (colorScheme === 'light' && isFocused) {
          backgroundColor = '#000';
          menuButtonTextColor = '#fff';
        }

        // MAIN MENU BUTTONS
        return (
          (!isScreenWithBackMenu || isMobile) &&
          route.name.startsWith(Navigator.main) && (
            <View
              key={route.key}
              style={{
                flex: 1,
                // Extra background on mobile below the nav items
                backgroundColor: colorScheme === 'dark' ? '#000000' : '#ffffff',
              }}
            >
              <Pressable
                key={route.key}
                onPress={() => {
                  const event = navigation.emit({
                    type: 'tabPress',
                    target: route.key,
                    canPreventDefault: true,
                    data: {
                      isAlreadyFocused: isFocused,
                    },
                  });

                  if (!isFocused && !event.defaultPrevented) {
                    navigation.dispatch({
                      ...CommonActions.navigate(route),
                      target: state.key,
                    });
                  }
                }}
                onPressIn={() => setHoveredItem(route.key)}
                onPressOut={() => setHoveredItem(null)}
                onHoverIn={() => setHoveredItem(route.key)}
                onHoverOut={() => setHoveredItem(null)}
                style={[
                  {
                    backgroundColor: backgroundColor,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: isTablet ? 56 : 48,
                    paddingVertical: isMobileWeb ? 10 : null,
                  },
                  // This way it doesnt give an error for the transition

                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  !isMobile || isMobileWeb ? { transition: transitionValue, flex: 1 } : null,
                ]}
              >
                <Icon
                  icon={svgIcons[iconName]}
                  mobileSize={{ width: 24, height: 24 }}
                  webSize={{ width: 32, height: 32 }}
                  isFocused={isFocused}
                />
                <Text
                  style={[
                    {
                      fontSize: isTablet ? 12 : isMobile ? 10 : 12,
                      fontFamily: 'sans-400',
                      color: menuButtonTextColor,
                      textTransform: 'uppercase',
                      fontWeight: '400',
                      marginTop: isMobile ? 6 : 10,
                    },
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    !isMobile ? { transition: transitionValue } : null,
                  ]}
                >
                  {descriptors[route.key].options.title ?? route.name}
                </Text>
              </Pressable>
            </View>
          )
        );
      })}

      {/*  BACK BUTTON  */}
      {isScreenWithBackMenu && !isMobile && (
        <Pressable
          style={[
            {
              flex: 1,
              backgroundColor: backButtonHovered
                ? `${colorScheme === 'dark' ? '#272727' : '#dfdfdf'}`
                : colorScheme === 'dark'
                  ? '#000000'
                  : '#ffffff',
              alignItems: 'center',
              justifyContent: 'center',
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore

            !isMobile ? { transition: 'all 0.3s ease-in-out' } : null,
          ]}
          onHoverIn={() => setBackButtonHovered(true)}
          onHoverOut={() => setBackButtonHovered(false)}
          onPress={() => {
            setIsBackModalOpen(true);
            setBackButtonHovered(false);
          }}
        >
          <Icon
            icon={svgIcons.arrowLeftIcon}
            mobileSize={{ width: 24, height: 24 }}
            webSize={{ width: 40, height: 40 }}
          />
        </Pressable>
      )}

      {/*LOGO IN THE WEB MAIN MENU*/}
      {!isMobile && (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: colorScheme === 'dark' ? '#000000' : '#ffffff',
          }}
        >
          <Pressable
            onPress={() => {
              navigation.navigate(ScreenName.HomeScreen);
            }}
          >
            <Icon
              icon={svgIcons.logo}
              mobileSize={{ width: 84, height: 20 }}
              webSize={{ width: 65, height: 15 }}
            />
          </Pressable>
        </View>
      )}
    </View>
  );
};

export default MainMenu;
