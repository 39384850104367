import React, { useCallback, useRef, useState } from 'react';
import { useFocusEffect } from '@react-navigation/core';
import { Image, Pressable, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useColorScheme } from 'nativewind';
import {
  Button,
  EventCardList,
  HyperlinkedText,
  Icon,
  Layout,
  Loader,
  Tag,
} from '../../components/index';
import { isMobile, isMobileWeb, isNative, isTablet, isWeb } from '../../utils/responsive';
import { cln } from '../../utils/classnames';
import { Event, EventTranslationRole } from '../../client/interfaces';
import { ErrorType, TagVariant } from '../../resources/interfaces';
import { NavigationParamList, ScreenName, TabNavigationScreenProps } from '../../navigation/types';
import client from '../../client/client';
import { getTranslation } from '../../utils/translations';
import { useMessage } from '../../contexts/Messages/MessageContext';
import { useTags } from '../../contexts/Tag/TagContext';
import { handleLinkPress } from '../../utils';
import { getDateDetails } from '../../utils/getDateDetails';
import svgIcons from '../../assets';

const EventScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.EventScreen>
> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const [event, setEvent] = useState(null);
  const [similarEvents, setSimilarEvents] = useState<Event[]>(null);
  const { setMessage } = useMessage();
  const { tags } = useTags();
  const [tagIds, setTagIds] = useState<number[]>(null);
  const finalTagData = tags?.filter((tag) => tagIds?.includes(tag.id));
  const eventRef = useRef(null);
  const [hasCtaLabel, setHasCtaLabel] = useState<boolean>(false);
  const { colorScheme } = useColorScheme();

  const renderedTags = finalTagData?.map((tag) => {
    return (
      <Tag
        key={tag.id}
        variant={TagVariant.Border}
        onPress={() =>
          navigation.navigate(ScreenName.EventsScreen, {
            tags: [`${tag.id}`],
            isBookmarked: false,
          })
        }
        margin="mr-3 mb-3"
      >
        {tag.translationValue}
      </Tag>
    );
  });

  // FETCHING DATA
  const fetchEvent = async () => {
    try {
      if (eventRef.current && isNative) {
        eventRef?.current?.scrollTo({ y: 10, animated: false });
      } else if (eventRef.current && !isNative) {
        window.scrollTo({ top: 0, behavior: 'instant' });
      }
      const eventRes = await client.getEvent(route.params.postId);
      setEvent(eventRes);
      setHasCtaLabel(!!getTranslation(eventRes.translations, EventTranslationRole.ctaLabel));

      navigation.setOptions({
        title: getTranslation(eventRes.translations, EventTranslationRole.title),
      });

      const tagIds = eventRes?.tags?.map((tag) => {
        return tag.id;
      });
      setTagIds(tagIds);

      const similarEventsRes = await client.getSimilarEvents({
        postId: route.params.postId,
        tagIds: tagIds,
        limit: 3,
      });

      setSimilarEvents(similarEventsRes);
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
      navigation.navigate(ScreenName.ErrorScreen, {
        type:
          error?.response?.status === 404 ? ErrorType.not_found : ErrorType.internal_server_error,
      });
    }
  };

  useFocusEffect(
    useCallback(() => {
      route?.params?.postId && fetchEvent();
    }, [route?.params?.postId]),
  );

  const toggleBookmark = (event: Event) => {
    setSimilarEvents((prev) =>
      prev.map((a) => (a.id === event.id ? { ...a, isBookmarked: !a?.isBookmarked } : a)),
    );
  };

  const dateDetails = getDateDetails(event?.relatedItemDetail);
  const givenDate = new Date(dateDetails?.fullEndDate);
  const currentDate = new Date();
  const isPast = givenDate < currentDate;

  const fallbackImage =
    colorScheme === 'light'
      ? require('../../assets/fallback.jpg')
      : require('../../assets/fallback-dark.jpg');

  return event && event?.id == route?.params?.postId ? (
    <Layout
      title={getTranslation(event.translations, EventTranslationRole.title)}
      isScrollView={isMobile}
      isArticleStyle={true}
      refProp={eventRef}
      titleMarginBottom={false}
    >
      <View className="max-w-[640px] w-full self-center mt-2">
        <View className={cln(isMobile && 'px-0', 'w-full mt-0')}>
          <View
            className={cln(
              isMobile ? 'mb-0' : 'mt-2 mb-6',
              'flex-row justify-between items-center',
            )}
            style={{
              minHeight: 40,
              marginBottom: 24,
              marginTop: 0,
            }}
          >
            <View className="w-full">
              <View className="mb-6">
                <Text className="font-[sans-400] text-xs text-neutral-950 dark:text-neutral-50">
                  {`${t('article:uploaded_by')}: ${!event.publisher ? '' : event.publisher}`}
                </Text>
                <Text className="font-[sans-400] text-xs text-neutral-950 dark:text-neutral-50 mt-3">
                  {t('article:date')}: {event.publishedAtUtc.slice(0, 10)}
                </Text>
              </View>

              <View className="w-full">
                <View className="flex-row items-center relative w-full mb-1">
                  <Icon
                    icon={svgIcons.calendarIcon}
                    mobileSize={{ width: 24, height: 24 }}
                    webSize={{ width: 24, height: 24 }}
                    classNames={'mr-3 mb-[2px]'}
                  />
                  <View className="flex-row items-start flex-1 flex-wrap">
                    <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-400] text-calendarCardTitle md:text-body mr-2">
                      {dateDetails.dateFirstLine} {dateDetails.dateSecondLine ? '-' : ''}
                    </Text>
                    <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-400] text-calendarCardTitle md:text-body mr-2">
                      {dateDetails.dateSecondLine}
                    </Text>
                  </View>
                </View>
                <View className="flex-row items-center mb-1">
                  <Icon
                    icon={svgIcons.pinIcon}
                    mobileSize={{ width: 24, height: 24 }}
                    webSize={{ width: 24, height: 24 }}
                    classNames={'mr-3'}
                  />
                  <View className="flex-row items-start flex-1 flex-wrap">
                    <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-400] text-calendarCardTitle md:text-body mr-2">
                      {event?.relatedItemDetail.location}
                    </Text>
                  </View>
                </View>
                {event?.relatedItemDetail.isEnglish ? (
                  <View className="flex-row items-center mb-1">
                    <Icon
                      icon={svgIcons.speechBubbleIcon}
                      mobileSize={{ width: 23, height: 23 }}
                      webSize={{ width: 23, height: 23 }}
                      classNames={'mr-3'}
                    />
                    <View className="flex-row items-start flex-1 flex-wrap">
                      <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-400] text-calendarCardTitle md:text-body mr-2">
                        {t('admin:event_edit:is_english')}
                      </Text>
                    </View>
                  </View>
                ) : (
                  <></>
                )}
                {event?.relatedItemDetail.joinLink ? (
                  <Pressable
                    className="flex-row items-center justify-start"
                    onPress={() => handleLinkPress(event.relatedItemDetail.joinLink)}
                  >
                    <Icon
                      icon={svgIcons.filmIconPrimary}
                      mobileSize={{ width: 24, height: 24 }}
                      webSize={{ width: 24, height: 24 }}
                      classNames={'mr-3'}
                    />
                    <Text className="text-primary-600 dark:text-primary-500 text-calendarCardDetails font-[sans-400] underline">
                      {t('today:join_online')}
                    </Text>
                  </Pressable>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
          <View />
        </View>
        <Tag
          onPress={async () => {
            if (event?.isBookmarked) {
              await client.deletePostBookmark(event?.id);
              setEvent((prevValue) => {
                return { ...prevValue, isBookmarked: false };
              });
            } else {
              await client.createPostBookmark(event?.id);
              setEvent((prevValue) => {
                return { ...prevValue, isBookmarked: true };
              });
            }
          }}
          variant={TagVariant.IconTag}
          iconName={event?.isBookmarked ? 'calendarIconFilled' : 'calendarIcon'}
          isFilled={event?.isBookmarked}
          margin="mr-3"
          width={isMobile && !isTablet && 'w-full'}
          iconTagDisabled={isPast}
        >
          {event.isBookmarked ? t('event:event_saved') : t('event:save_event')}
        </Tag>

        <View
          className={isMobile ? 'my-6' : 'my-6'}
          style={{ aspectRatio: 4 / 3, width: '100%', overflow: 'hidden' }}
        >
          <Image
            source={event.imageUrl ? { uri: event.imageUrl } : fallbackImage}
            resizeMode={'cover'}
            style={{ height: '100%', maxWidth: '100%' }}
          />
        </View>
        <View className="flex-row flex-wrap mb-6">{renderedTags}</View>

        <View className={cln(isMobile && 'mt-0', 'self-center mb-6 sm:mb-10 w-full')}>
          <HyperlinkedText
            text={getTranslation(event.translations, EventTranslationRole.description)}
          />
        </View>

        {hasCtaLabel && (
          <View
            style={{
              marginBottom:
                // On phone without similar articles
                isNative && similarEvents?.length < 1
                  ? 40
                  : isNative && similarEvents?.length >= 1
                    ? 0
                    : // On web phone width with similar articles
                      isMobileWeb && similarEvents?.length > 0
                      ? 0
                      : // On web phone width without similar articles
                        isMobileWeb && similarEvents?.length < 1
                        ? 40
                        : // On web bigger screens without similar articles
                          isWeb && similarEvents?.length <= 0
                          ? 0
                          : // On web bigger screens with similar articles
                            40,
            }}
          >
            <Button
              text={getTranslation(event.translations, EventTranslationRole.ctaLabel)}
              variant={'fill'}
              onPress={() =>
                handleLinkPress(getTranslation(event.translations, EventTranslationRole.ctaLink))
              }
              size={'wide'}
              style={{ alignItems: 'center' }}
            />
          </View>
        )}
      </View>
      {similarEvents?.length >= 1 && (
        <View
          className={cln(isNative ? 'mb-0 left-[-32px] w-full' : 'mb-0')}
          style={{
            marginTop: isNative && !hasCtaLabel ? 64 : isMobileWeb && !hasCtaLabel ? 16 : 90,
          }}
        >
          <EventCardList
            items={similarEvents}
            toggleBookmark={toggleBookmark}
            title={t('event:similar_events')}
          />
        </View>
      )}
    </Layout>
  ) : (
    <View className="h-full w-full items-center justify-center">
      <Loader />
    </View>
  );
};

export default EventScreen;
