import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, Pressable, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import { isEmpty } from 'lodash';
import { EventCard, Layout, PostFilter, Pagination, Loader } from '../../components';
import { isMobile, isMobileWeb, isTablet, isWeb } from '../../utils/responsive';
import {
  Event,
  GetEventsParams,
  Order,
  PaginatedResponse,
  Pagination as IPagination,
  PostOrderBy,
} from '../../client/interfaces';
import client from '../../client/client';
import { useMessage } from '../../contexts/Messages/MessageContext';
import { NavigationParamList, ScreenName, TabNavigationScreenProps } from '../../navigation/types';
import { distributeElements } from '../../utils';
import { cln } from '../../utils/classnames';
import { useResize } from '../../utils/resize';
import { PostFilterScreen } from '../index';

const emptyGetEventsParams: GetEventsParams = {
  orderBy: PostOrderBy.publishedAtUtc,
  order: Order.DESC,
  page: 1,
  perPage: 9,
  search: '',
  tags: [],
  isBookmarked: false,
  isPast: false,
};

const emptyPagination: IPagination = {
  total: 0,
  perPage: 0,
  currentPage: 1,
  pageCount: 1,
  fromNumber: 0,
  toNumber: 0,
};

const EventsScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.EventsScreen>
> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { setMessage } = useMessage();
  const { isWidthBelowSmall, isWidthBelowLarge } = useResize();

  const [isLoading, setIsLoading] = useState(true);
  const [allEvents, setAllEvents] = useState<Event[]>([]);
  const [distributedEvents, setDistributedEvents] = useState<Event[][]>([]);
  const [paginatedEvents, setPaginatedEvents] = useState<Event[]>([]);
  const [pagination, setPagination] = useState<IPagination>(emptyPagination);

  const fallbackTags = route.params?.tags ? route.params.tags : [];
  const normalizedTags = Array.isArray(fallbackTags) ? fallbackTags : [fallbackTags];

  const [getEventsParams, setGetEventsParams] = useState<GetEventsParams>({
    ...emptyGetEventsParams,
    ...route.params,
    tags: normalizedTags,
  });

  const [nextPageIdentifier, setNextPageIdentifier] = useState<number>(0);
  const [firstPageLoaded, setFirstPageLoaded] = useState<boolean>(false);

  const [isPostFilterScreen, setIsPostFilterScreen] = useState(false);

  const flatListRef = useRef<FlatList>(null);

  const getEventsParamsJSON = JSON.stringify(getEventsParams);

  const fetchEvents = async (
    afterFetchCallback: (paginatedEvents: PaginatedResponse<Event>) => void,
  ) => {
    try {
      !firstPageLoaded && setFirstPageLoaded(true);
      setIsLoading(true);
      const paginatedEventsResponse = await client.getEvents(getEventsParams);
      afterFetchCallback(paginatedEventsResponse);
      setPagination(paginatedEventsResponse.pagination);
      setDistributedEvents(
        distributeElements(
          paginatedEventsResponse.items,
          isWidthBelowSmall ? 1 : isWidthBelowLarge ? 2 : 3,
        ),
      );
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setDistributedEvents(
      distributeElements(paginatedEvents, isWidthBelowSmall ? 1 : isWidthBelowLarge ? 2 : 3),
    );
  }, [isWidthBelowLarge, isWidthBelowSmall]);

  const afterMobileFetch = async (paginatedEvents: PaginatedResponse<Event>) => {
    const shouldAppend = getEventsParams.page === nextPageIdentifier;
    setAllEvents((prev) => [...(shouldAppend ? prev : []), ...paginatedEvents.items]);

    if (!shouldAppend && !isPostFilterScreen) {
      flatListRef?.current?.scrollToOffset({ animated: true, offset: 0 });
    }
  };

  const afterDesktopFetch = async (paginatedEvents: PaginatedResponse<Event>) => {
    setPaginatedEvents(paginatedEvents.items);
  };

  const toggleBookmark = (event: Event) => {
    const setter = isMobile ? setAllEvents : setPaginatedEvents;

    setter((prev) =>
      prev.map((a) => (a.id === event.id ? { ...a, isBookmarked: !a?.isBookmarked } : a)),
    );

    if (!isMobile) {
      setDistributedEvents((prev) =>
        prev.map((row) =>
          row.map((a) => (a.id === event.id ? { ...a, isBookmarked: !a?.isBookmarked } : a)),
        ),
      );
    }
  };

  const onScrollEndReached = () => {
    const nextPage = pagination.currentPage + 1;

    if (nextPage <= pagination.pageCount) {
      setNextPageIdentifier(nextPage);
    }
  };

  const emptyEventsState = (hard = true) => {
    setIsLoading(true);
    hard && setGetEventsParams({ ...emptyGetEventsParams });
    setAllEvents([]);
    setPaginatedEvents([]);
    resetInfiniteScroll();
    setPagination(emptyPagination);
    setIsLoading(false);
  };

  const resetInfiniteScroll = () => {
    setNextPageIdentifier(0);
  };

  useEffect(() => {
    isMobile ? fetchEvents(afterMobileFetch) : fetchEvents(afterDesktopFetch);
  }, [getEventsParamsJSON]);

  useFocusEffect(
    useCallback(() => {
      if (isEmpty(route.params)) {
        isMobile ? fetchEvents(afterMobileFetch) : fetchEvents(afterDesktopFetch);
      }
    }, [route]),
  );

  useEffect(() => {
    if (nextPageIdentifier > pagination.currentPage) {
      setGetEventsParams((prev) => ({ ...prev, page: nextPageIdentifier }));
    }
  }, [nextPageIdentifier]);

  useEffect(() => {
    setGetEventsParams((prev) => ({ ...prev, ...route.params, tags: normalizedTags }));
  }, [route.params]);

  // Hide postFilterScreen when navigating to another screen without closing it
  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      setIsPostFilterScreen(false);
      // Empty filters when navigating away from the screen
      emptyFilters();
    });
    return unsubscribe;
  }, [navigation]);

  function emptyFilters() {
    setGetEventsParams((prevState) => ({
      ...prevState,
      tags: [],
      isBookmarked: false,
      search: '',
      isPast: false,
    }));
  }

  const mobileFeed = (
    <View
      style={{
        flex: 1,
        marginTop: 24,
        overflow: 'hidden',
      }}
    >
      <FlatList
        data={allEvents}
        keyExtractor={(item) => item.id}
        renderItem={(event) => {
          return (
            <EventCard
              item={event.item}
              toggleCardMark={toggleBookmark}
              styleProp={{
                flex: isTablet ? 0.5 : 1,
                // MarginBottom doesnt get applied to tablet, and when I conditionally add to tablet it gets removed from mobile
                marginBottom: 40,
              }}
            />
          );
        }}
        onEndReached={onScrollEndReached}
        onEndReachedThreshold={0.8}
        initialNumToRender={emptyGetEventsParams.perPage}
        ListFooterComponent={isLoading ? Loader : null}
        ref={flatListRef}
        showsVerticalScrollIndicator={false}
        numColumns={isTablet ? 2 : 1}
        columnWrapperStyle={isTablet ? { flex: 1, justifyContent: 'space-between', gap: 24 } : null}
      />
    </View>
  );

  const desktopFeed = isLoading ? (
    <Loader />
  ) : (
    <View>
      <View
        className="flex flex-row flex-wrap md:mx-[24px]"
        style={{
          rowGap: 64,
          columnGap: 24,
          justifyContent: 'flex-start',
          marginTop: isMobileWeb ? 24 : 0,
        }}
      >
        {distributedEvents.map((column, index) => (
          <View className={cln('flex-1')} key={index}>
            {column.map((item) => (
              <EventCard
                key={item.id}
                item={item}
                toggleCardMark={toggleBookmark}
                styleProp={{ marginBottom: isMobile ? 40 : 64 }}
              />
            ))}
          </View>
        ))}
      </View>
      <Pagination
        data={pagination}
        paramSetter={(change?: Partial<GetEventsParams>) => {
          setGetEventsParams((prev) => ({ ...prev, ...change }));
          return { ...getEventsParams, ...change };
        }}
        params={getEventsParams}
      />
    </View>
  );

  const feed = isMobile ? mobileFeed : desktopFeed;

  const emptySearch = (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <>
      {!isLoading && (
        <View className={'flex-column'} style={{ display: isWeb ? 'inline' : 'flex' }}>
          <Text className="text-h3 md:text-h2 text-primaryLight-500 dark:text-primary-500 font-[sans-600]">
            {t('articles:no_results')}
          </Text>
          <Text className="text-h3 md:text-h2 text-neutral-950 dark:text-neutral-50 font-[sans-600]">
            {t('articles:based_on_your_search')}.
          </Text>
        </View>
      )}
    </>
  );

  return isPostFilterScreen ? (
    <PostFilterScreen
      getPostsParams={getEventsParams}
      setGetPostsParams={setGetEventsParams}
      resetInfiniteScroll={resetInfiniteScroll}
      allPosts={allEvents}
      screen={ScreenName.EventsScreen}
      onPressBackButton={() => setIsPostFilterScreen(false)}
    />
  ) : (
    <Layout
      title={t('events:title')}
      isScrollView={!isMobile}
      onPressSearch={() => setIsPostFilterScreen(true)}
    >
      {(getEventsParams?.tags?.length >= 1 ||
        getEventsParams.isBookmarked ||
        getEventsParams.search ||
        getEventsParams.isPast) &&
      !isTablet &&
      isMobile ? (
        <View className="flex-row self-end">
          <Text className="text-neutral-950 dark:text-neutral-50 text-body font-[sans-700] mr-1.5">
            {allEvents.length}
          </Text>
          <Text className="text-neutral-950 dark:text-neutral-50 text-body font-[sans-400]">
            {t('articles:results')}
          </Text>
        </View>
      ) : (
        <></>
      )}
      {/* REMOVE ALL BUTTON */}
      {(getEventsParams?.tags?.length >= 1 ||
        getEventsParams.isBookmarked ||
        getEventsParams.search ||
        getEventsParams.isPast) &&
      !isTablet &&
      isMobile ? (
        <Pressable
          className="justify-center items-center"
          onPress={emptyFilters}
          style={{
            alignSelf: 'flex-end',
          }}
        >
          <Text className="text-primary-600 dark:text-primary-500 text-body font-[sans-400] py-2">
            {t('articles:remove_search')}
          </Text>
        </Pressable>
      ) : (
        <></>
      )}

      {!isMobile || isTablet ? (
        <PostFilter
          getPostsParams={getEventsParams}
          setGetPostsParams={setGetEventsParams}
          resetInfiniteScroll={resetInfiniteScroll}
          allPosts={allEvents}
          pagination={pagination}
          screen={ScreenName.EventsScreen}
        />
      ) : (
        <></>
      )}
      {firstPageLoaded && pagination.total === 0 ? emptySearch : feed}
    </Layout>
  );
};

export default EventsScreen;
