import React, { useRef, useState, FC, useCallback } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import { Section as ISection } from '../../resources/interfaces';
import { ExtremeEventSection, Section } from '../../components/index';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import { Role } from '../../client/interfaces';
import { useExtremeEvents } from '../../contexts/ExtremeEvent/ExtremeEventContext';
import { isMobile, isMobileWeb } from '../../utils/responsive';

interface Props {
  sections: ISection[];
  setter: React.Dispatch<React.SetStateAction<unknown[]>>;
  componentMap: { [key: string]: FC };
  roleMap: { [key: string]: Role | null };
}

const SectionList: React.FC<Props> = ({ sections, setter, componentMap, roleMap }) => {
  const dragItem = useRef(null);
  const dragNode = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const { extremeEvents, fetchExtremeEvents } = useExtremeEvents();

  useFocusEffect(
    useCallback(() => {
      fetchExtremeEvents();
    }, []),
  );

  const { t } = useTranslation();
  const { me } = useAuth();

  function enableIsDraggable() {
    setIsDraggable(true);
  }

  function handleDragStart(e, itemIndex) {
    if (isDraggable) {
      dragItem.current = itemIndex;
      dragNode.current = e.target;
      dragNode.current.addEventListener('dragend', handleDragEnd);
      setTimeout(() => setIsDragging(true), 0);
    }
  }

  function handleDragEnd() {
    dragNode.current.removeEventListener('dragend', handleDragEnd);
    dragItem.current = null;
    dragNode.current = null;
    setIsDragging(false);
    setIsDraggable(false);
  }

  const handleDragEnter = (e, targetItem) => {
    if (dragItem.current !== e.target) {
      setter((prevModules) => {
        const newList = [...prevModules];
        const draggedItem = newList[dragItem.current];
        newList.splice(dragItem.current, 1);
        newList.splice(targetItem, 0, draggedItem);
        dragItem.current = targetItem;
        return newList;
      });
    }
  };

  function getStyles(itemIndex) {
    if (dragItem.current === itemIndex) {
      return { opacity: 0 };
    } else {
      return { opacity: 1 };
    }
  }

  const renderedElementsWeb = sections.map((section, itemIndex) => {
    const ChildComponent = componentMap[section.key];
    const roles = me?.roleList;
    let showAddNew = false;

    if (roles && roles.length > 0) {
      showAddNew = roles.some((role) => role === roleMap[section.key]);
    }

    return (
      ChildComponent && (
        <Section
          key={section.key}
          title={t(`section:${section.key}`)}
          draggable={isDraggable}
          containerStyle={isDragging ? getStyles(itemIndex) : { opacity: 1 }}
          onDragStart={(e) => handleDragStart(e, itemIndex)}
          onDragEnter={isDragging ? (e) => handleDragEnter(e, itemIndex) : null}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          showAddNew={showAddNew}
          onMouseDown={enableIsDraggable}
        >
          <ChildComponent />
        </Section>
      )
    );
  });

  return (
    <View>
      {extremeEvents && (
        <ExtremeEventSection
          containerStyle={{
            marginTop: isMobileWeb ? 40 : 0,
            marginBottom:
              extremeEvents.length > 0 && isMobile
                ? 40
                : extremeEvents.length > 0 && !isMobile
                  ? 80
                  : 0,
          }}
        />
      )}
      <div
        style={{
          gap: isMobileWeb ? 40 : 80,
          display: 'flex',
          flexDirection: 'column',
          marginTop: 0,
        }}
      >
        {renderedElementsWeb}
      </div>
    </View>
  );
};
export default SectionList;
