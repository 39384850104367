import { ColorSchemeName, Linking } from 'react-native';
import { ThemeOption } from '../resources/interfaces';
import { isNative } from './responsive';

export const getOppositeColorScheme = (colorScheme: ColorSchemeName | ThemeOption) => {
  return Object.values(ThemeOption).find((c) => c !== colorScheme);
};

export const distributeElements = <T>(inputArray: T[], columns): T[][] => {
  const output = Array.from({ length: columns }, () => []);

  for (let i = 0; i < inputArray.length; i++) {
    const targetIndex = i % columns;
    output[targetIndex].push(inputArray[i]);
  }

  return output;
};

export const handleLinkPress = (url: string) => {
  if (isNative) {
    Linking.openURL(url);
  } else {
    if (typeof window !== 'undefined') {
      // We are running in a web environment
      const newWindow = window.open(url, '_blank');
      if (newWindow) {
        newWindow.focus();
      }
    } else {
      // We are running in a React Native environment
      Linking.openURL(url);
    }
  }
};
