import { PostType } from '../client/interfaces';
import { PushNotificationRedirectScreenName, ScreenName } from '../navigation/types';

export const getScreenNameFromPostType = (
  postType: PostType,
): PushNotificationRedirectScreenName => {
  switch (postType) {
    case PostType.article:
      return ScreenName.ArticleScreen;
    case PostType.event:
      return ScreenName.EventScreen;
  }
};
