import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TextInput, View, Pressable, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useFocusEffect } from '@react-navigation/core';
import { useColorScheme } from 'nativewind';
import { useNavigation } from '@react-navigation/native';
import {
  AdminArticleRead,
  GetAdminArticlesParams,
  Pagination as IPagination,
  PostStatus,
  PostStatusFilter,
  Tag as TagInterface,
} from '../../client/interfaces';
import useDebounce from '../../hooks/useDebounce';
import { TagVariant } from '../../resources/interfaces';
import svgIcons from '../../assets';
import { Icon, Dropdown, Tag, ResultCounter, RadioGroup } from '../index';
import { cln } from '../../utils/classnames';
import { useResize } from '../../utils/resize';
import { useTags } from '../../contexts/Tag/TagContext';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { ScreenName } from '../../navigation/types';

interface Props {
  params: GetAdminArticlesParams;
  paramSetter: React.Dispatch<React.SetStateAction<GetAdminArticlesParams>>;
  allPosts?: AdminArticleRead[];
  pagination?: IPagination;
  type?: ScreenName;
}

const AdminPostFilter: React.FC<Props> = ({ params, paramSetter, allPosts, pagination, type }) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { colorScheme } = useColorScheme();
  const { filterTags } = useTags();
  const { isWidthBelowMedium } = useResize();

  // Search input field states
  const [search, setSearch] = useState<string>(params?.search);
  const [searchActive, setSearchActive] = useState(false);
  const debouncedSearch = useDebounce(search);
  const searchInputRef = useRef(null);

  // Dropdown input field states
  const [dropdownTags, setDropdownTags] = useState([]);
  const [tagSearch, setTagSearch] = useState<string>('');

  // Use refs for clicking outside
  // Dropdown ref for bluring the input in the handleBlur function
  const dropdownInputRef = useRef(null);
  const dropdownRef = useRef(null);
  const { visible, setVisible, reference } = useOnClickOutside(false);

  // Status
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(PostStatusFilter.all);

  // Dropdown filtering based on text input
  useEffect(() => {
    if (tagSearch) {
      const filteringTagsUpdated = filterTags.filter((tag) => {
        return (
          tag.translationValue.toLowerCase().includes(tagSearch.toLowerCase()) &&
          !params?.tags?.includes(String(tag.id))
        );
      });
      setDropdownTags(filteringTagsUpdated);
    } else {
      const updatedTags = filterTags.filter((tag) => !params?.tags?.includes(String(tag.id)));
      setDropdownTags(updatedTags);
    }
  }, [tagSearch]);

  //Removing tags from chosen tags
  function removeTag(tag: TagInterface) {
    paramSetter((prevState) => ({
      ...prevState,
      tags: prevState.tags.filter((tagId) => {
        return tag.id !== Number(tagId);
      }),
    }));
  }

  function emptyFilters() {
    handleBlur();
    paramSetter((prevState) => ({
      ...prevState,
      search: '',
      tags: [],
    }));
    setSearch('');
    setSelectedStatus(PostStatusFilter.all);
  }

  // Updating the list of tags we can choose from when chosen tags change so either removing from the list or adding to the list
  useEffect(() => {
    const updatedArray = filterTags.filter((item) => !params?.tags?.includes(String(item.id)));
    setDropdownTags(updatedArray);
  }, [params?.tags]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      emptyFilters();
    });

    return unsubscribe;
  }, [navigation]);

  // We add all the tags to the filtering tags which is the list we can choose from
  useFocusEffect(
    useCallback(() => {
      setDropdownTags(filterTags);
    }, []),
  );

  useEffect(() => {
    paramSetter((prev) => ({ ...prev, search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    paramSetter((prev) => ({
      ...prev,
      page: 1,
      status: selectedStatus === PostStatusFilter.all ? undefined : (selectedStatus as PostStatus),
    }));
  }, [selectedStatus]);

  function handleBlur() {
    if (dropdownInputRef.current) {
      dropdownInputRef.current.blur();
    }
    searchInputRef.current.blur();
    setVisible(false);
  }

  function handleFocus() {
    setVisible(true);
    dropdownInputRef.current.focus();
  }

  let dropdownPlaceholderColor: string;
  if (!visible) {
    dropdownPlaceholderColor = colorScheme === 'dark' ? '#888888' : '#101010';
  } else if (visible) {
    dropdownPlaceholderColor = colorScheme === 'dark' ? '#101010' : '#888888';
  }

  let searchPlaceholderColor: string;
  if (!searchActive) {
    searchPlaceholderColor = colorScheme === 'dark' ? '#888888' : '#101010';
  } else if (searchActive) {
    searchPlaceholderColor = colorScheme === 'dark' ? '#101010' : '#888888';
  }

  return (
    <View className="mb-2 md:mb-4 z-50">
      <View className="flex-column" style={{ columnGap: 16, rowGap: 8 }}>
        <View
          className="flex-column sm:flex-row relative z-30"
          style={{ columnGap: 16, rowGap: 16 }}
        >
          <View className="flex-row items-center h-12 flex-1">
            <View className="absolute ml-4 z-20">
              <Icon
                icon={svgIcons.searchIcon}
                mobileSize={{ width: 24, height: 24 }}
                webSize={{ width: 24, height: 24 }}
                inverted={searchActive}
              />
            </View>
            <TextInput
              ref={searchInputRef}
              style={
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                { outline: 'none' }
              }
              placeholderTextColor={searchPlaceholderColor}
              className={cln(
                searchActive
                  ? 'text-neutral-50 bg-neutral-950 dark:text-neutral-950 dark:bg-neutral-50'
                  : 'text-neutral-950 bg-neutral-50 dark:text-neutral-50 dark:bg-neutral-950',
                'h-12 border-[1px] flex-1 pl-[52px] pr-3 border-neutral-900 dark:border-neutral-200',
              )}
              focusable={false}
              onChangeText={setSearch}
              onFocus={() => {
                setSearchActive(true);
                setVisible(false);
              }}
              onBlur={() => setSearchActive(false)}
              value={search}
              keyboardType="ascii-capable"
              placeholder={t('common:search')}
              blurOnSubmit={true}
              onSubmitEditing={() => setSearchActive(false)}
            />
          </View>
          {type !== ScreenName.AdminExtremeEventListScreen ? (
            <View
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={reference}
              className="flex-row items-center relative z-80 flex-1"
            >
              <TextInput
                style={
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  { outline: 'none' }
                }
                ref={dropdownInputRef}
                placeholderTextColor={dropdownPlaceholderColor}
                className={cln(
                  visible
                    ? 'text-neutral-50 bg-neutral-950 dark:text-neutral-950 dark:bg-neutral-50'
                    : 'text-neutral-950 bg-neutral-50 dark:text-neutral-50 dark:bg-neutral-950',
                  'h-12 border-[1px] flex-1 px-3 border-neutral-900 dark:border-neutral-200',
                )}
                onFocus={handleFocus}
                onChangeText={setTagSearch}
                value={tagSearch}
                keyboardType="ascii-capable"
                placeholder={t('common:tags')}
                blurOnSubmit={true}
              />
              <View className="absolute right-[8px] z-20">
                <Pressable
                  onPress={() => {
                    if (visible) {
                      handleBlur();
                    } else {
                      handleFocus();
                    }
                  }}
                  className="py-2 px-2"
                >
                  <Icon
                    icon={visible ? svgIcons.chevronUpIcon : svgIcons.chevronDownIcon}
                    mobileSize={{ width: 24, height: 24 }}
                    webSize={{ width: 24, height: 24 }}
                    inverted={visible}
                  />
                </Pressable>
              </View>
              {visible && (
                <Dropdown
                  onPress={(option) => {
                    paramSetter((prevState) => ({
                      ...prevState,
                      tags: [...prevState.tags, String(option.id)],
                    }));
                    handleBlur();
                    setTagSearch('');
                  }}
                  dropdownTags={dropdownTags}
                  dropdownRef={dropdownRef}
                />
              )}
            </View>
          ) : (
            <></>
          )}
        </View>

        <View
          className="w-full justify-between flex-nowrap"
          style={{
            flexDirection: isWidthBelowMedium ? 'column' : 'row',
          }}
        >
          <View
            className="flex-row flex-wrap z-10"
            style={{
              rowGap: 8,
              columnGap: 8,
              width: isWidthBelowMedium ? '100%' : '75%',
              display: 'flex',
              marginBottom: params?.tags?.length >= 1 ? 12 : 0,
            }}
          >
            {params?.tags?.map((tagId) => {
              const tag = filterTags.find((t) => t.id === Number(tagId));
              return (
                <Tag
                  key={tag.id}
                  variant={TagVariant.Selected}
                  onPressRemove={() => {
                    removeTag(tag);
                  }}
                >
                  {tag.translationValue}
                </Tag>
              );
            })}
          </View>
        </View>
        <View className="flex-row items-start">
          <View className="flex-row items-center self-center">
            <Text className="text-input text-neutral-950 dark:text-neutral-50 font-[sans-600] mr-16">
              {t('admin:search:status')}:
            </Text>

            <RadioGroup
              radioButtons={Object.values(PostStatusFilter).map((status) => ({
                id: status,
                label: t(`admin:search:${status}`),
                value: status,
              }))}
              selectedId={selectedStatus}
              onPress={setSelectedStatus}
              layout={'row'}
            />
          </View>

          <View
            className="justify-start flex-col ml-auto"
            style={{ width: isWidthBelowMedium ? '100%' : '25%' }}
          >
            {(params?.tags?.length >= 1 || !!search || selectedStatus !== PostStatusFilter.all) && (
              <Pressable
                className="justify-center items-center"
                onPress={emptyFilters}
                style={{
                  alignSelf: 'flex-end',
                  marginBottom: 0,
                }}
              >
                <Text className="text-primary-600 dark:text-primary-500 text-body font-[sans-400] py-2">
                  {t('articles:remove_search')}
                </Text>
              </Pressable>
            )}
            <ResultCounter pagination={pagination} classNames="mb-10 self-end" />
          </View>
        </View>
      </View>
    </View>
  );
};

export default AdminPostFilter;
