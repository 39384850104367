import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDatePicker, { CustomDatePickerProps } from './CustomDatePicker';

interface Props extends CustomDatePickerProps {
  label?: string;
  value: string;
  todayText?: string;
  required?: boolean;
  errorMessage?: string;
  setValue(value: string): void;
  name: string;
}

const DatePicker = forwardRef<HTMLInputElement, Props>(
  ({ todayText, errorMessage, name, ...props }, ref) => {
    const { t, i18n } = useTranslation('common');
    todayText = todayText ?? t('today');

    return (
      <CustomDatePicker
        ref={ref}
        todayText={todayText}
        lang={i18n.language as 'hu' | 'en'}
        name={name}
        errorMessage={errorMessage}
        {...props}
      />
    );
  },
);

DatePicker.displayName = 'DatePicker';
export default DatePicker;
