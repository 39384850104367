import { FC, useEffect, useMemo } from 'react';
import { View, Text, Pressable } from 'react-native';
import useIsFirstRender from '../../hooks/useIsFirstRender';
import { FileUploadStatus, UploadedFile } from '../../resources/interfaces';
import ProgressBar from '../ProgressBar';
import Icon from '../Icon';
import svgIcons from '../../assets';

export interface Props {
  record: UploadedFile;
  upload: (fileName: string, controller: AbortController) => Promise<void>;
  cancelUpload: (fileName: string, controller: AbortController) => void;
  deleteUploadedFile: (fileName: string) => Promise<void>;
}

export const UploadTask: FC<Props> = ({ record, upload, cancelUpload, deleteUploadedFile }) => {
  const isFirstRender = useIsFirstRender();
  const controller = useMemo(() => new AbortController(), []);

  useEffect(() => {
    if (isFirstRender) upload(record.file.name, controller);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRender]);

  return (
    <View className="flex flex-row justify-between items-center p-5 border border-dashed border-neutral-400">
      <View className="mr-5">
        <Icon
          icon={
            record.status === FileUploadStatus.done ? svgIcons.tickSuccessIcon : svgIcons.fileIcon
          }
          mobileSize={{ width: 40, height: 40 }}
          webSize={{ width: 40, height: 40 }}
        />
      </View>
      <View className="flex-col grow mr-5">
        <Text className="font-[sans-400] text-label text-neutral-400 mb-3">{record.file.name}</Text>
        <ProgressBar isLoading={record.status === FileUploadStatus.inProgress} />
      </View>
      {record.status === FileUploadStatus.inProgress && (
        <Pressable onPress={() => cancelUpload(record.file.name, controller)}>
          <Icon
            icon={svgIcons.closeIcon}
            mobileSize={{ width: 40, height: 40 }}
            webSize={{ width: 40, height: 40 }}
          />
        </Pressable>
      )}
      {record.status === FileUploadStatus.done && (
        <Pressable onPress={() => deleteUploadedFile(record.file.name)}>
          <Icon
            icon={svgIcons.deleteIcon}
            mobileSize={{ width: 40, height: 40 }}
            webSize={{ width: 40, height: 40 }}
          />
        </Pressable>
      )}
    </View>
  );
};
