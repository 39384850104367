import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View } from 'react-native';
import dayjs from 'dayjs';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Icon, Table } from '../index';
import { AdminPostRead, GetAdminExtremeEventsParams } from '../../client/interfaces';
import { useMessage } from '../../contexts/Messages/MessageContext';
import svgIcons from '../../assets';
import Modal from '../Modal';
import TableCell from '../TableCell';
import client from '../../client/client';
import { NavigationParamList, ScreenName } from '../../navigation/types';

interface Props {
  rows;
  paramSetter: React.Dispatch<React.SetStateAction<GetAdminExtremeEventsParams>>;
  params: GetAdminExtremeEventsParams;
  refresh: () => Promise<void>;
  isLoading?: boolean;
}

const ExtremeEventTable: React.FC<Props> = ({
  rows,
  paramSetter,
  params,
  refresh,
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const navigation =
    useNavigation<NavigationProp<NavigationParamList, ScreenName.AdminExtremeEventEditScreen>>();
  const { setMessage } = useMessage();

  const [eventToDelete, setEventToDelete] = useState<AdminPostRead>();

  const deleteEvent = async (event: AdminPostRead) => {
    try {
      await client.deletePost(event.id);
      setMessage({
        message: t('success_messages:deleted'),
        type: 'success',
        translateMessage: false,
      });
      refresh();
    } catch (e) {
      console.error(e);
      setMessage({ message: e.message, type: 'error' });
    }
  };

  return (
    <View className="z-[100]">
      <Table
        paramSetter={paramSetter}
        params={params}
        isLoading={isLoading}
        keyFieldName="id"
        columns={[
          {
            key: 'title',
            label: t('admin:table_columns:extreme_events.title'),
            sortable: true,
            widthPercentage: 20,
            templateFunction: (row) => <TableCell text={row.titleTranslation} />,
          },
          {
            key: 'lastEditedBy',
            label: t('admin:table_columns:common.last_edited_by'),
          },
          {
            key: 'status',
            label: t('admin:table_columns:common.status'),
          },
          {
            key: 'start_date',
            widthPercentage: 16,
            label: t('admin:table_columns:common.starting'),
            sortable: true,
            templateFunction: (row) => (
              <TableCell
                text={dayjs(row.extremeEvent.startDate).format('YYYY. MM. DD.')}
                key={`created_at_utc-${row.extremeEvent.id}`}
              />
            ),
          },
          {
            key: 'end_date',
            widthPercentage: 16,
            label: t('admin:table_columns:common:ending'),
            sortable: true,
            templateFunction: (row) => (
              <TableCell
                text={dayjs(row.extremeEvent.endDate).format('YYYY. MM. DD.')}
                key={`created_at_utc-${row.extremeEvent.id}`}
              />
            ),
          },
          {
            key: 'actions',
            label: '',
            widthPercentage: 10,
            templateFunction: (row) => (
              <TableCell key={`actions-${row.id}`} classNames="flex-row justify-end">
                <Pressable
                  onPress={() => {
                    navigation.navigate(ScreenName.AdminExtremeEventEditScreen, {
                      postId: row.id,
                    });
                  }}
                  className="items-center w-[24px] mr-4"
                >
                  <View className="cursor-pointer">
                    <Icon
                      icon={svgIcons.editIcon}
                      mobileSize={{ width: 24, height: 24 }}
                      webSize={{ width: 24, height: 24 }}
                    />
                  </View>
                </Pressable>
                {
                  <Pressable
                    onPress={() => {
                      setEventToDelete(row);
                    }}
                    className="items-center w-[24px]"
                  >
                    <View className="cursor-pointer">
                      <Icon
                        icon={svgIcons.deleteIcon}
                        mobileSize={{ width: 24, height: 24 }}
                        webSize={{ width: 24, height: 24 }}
                      />
                    </View>
                  </Pressable>
                }
              </TableCell>
            ),
          },
        ]}
        rows={rows}
      />
      <Modal
        showModal={!!eventToDelete}
        title={t('admin:extreme_event_list:sure_to_delete')}
        callback={() => {
          deleteEvent(eventToDelete);
          setEventToDelete(null);
        }}
        closeModal={() => {
          setEventToDelete(null);
        }}
        yesText={t('admin:extreme_event_list:yes_delete')}
        cancelText={t('admin:article_list:no')}
      />
    </View>
  );
};
export default ExtremeEventTable;
