import React, { ReactNode } from 'react';
import { View, ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { cln } from '../../utils/classnames';
import { isMobileWeb, isNative, isTablet } from '../../utils/responsive';

interface Props {
  isScrollView?: boolean;
  children: ReactNode;
  refProp?: any;
  layoutWithoutMenu?: boolean;
  isArticleStyle?: boolean;
  verticalPadding: number;
  isHomeScreen?: boolean;
}

const AdaptiveContainer: React.FC<Props> = ({
  isScrollView,
  children,
  refProp,
  verticalPadding,
  isHomeScreen,
}) => {
  const insets = useSafeAreaInsets();

  let scrollBottomPadding;

  if (isMobileWeb) {
    scrollBottomPadding = 0;
  } else if (isNative) {
    scrollBottomPadding = insets.top + 54;
  }
  // SCROLLVIEW
  return isScrollView ? (
    <ScrollView
      overScrollMode="never"
      contentContainerStyle={{
        paddingHorizontal: 30,
        //We need to start content at the top of the top navigation, but we need margin, so we can scroll behind the top navigation
        marginTop: isMobileWeb ? 54 : insets.top + 54,
        //Because of this we have to add the same paddingBottom
        paddingBottom: scrollBottomPadding + verticalPadding,
        width: '100%',
        alignSelf: 'center',
      }}
      ref={refProp}
    >
      {children}
    </ScrollView>
  ) : (
    <View
      className={cln(isNative ? 'px-[30px] w-full' : 'px-8 md:px-20 md:pt-20 w-full')}
      style={{
        marginTop: isHomeScreen ? 0 : insets.top + 54,
        flex: 1,
        position: 'relative',
      }}
      ref={refProp}
    >
      {children}
    </View>
  );
};

export default AdaptiveContainer;
