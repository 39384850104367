import React, { useState } from 'react';
import { Image, Pressable, Text, View } from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { cln } from '../../utils/classnames';
import { isMobile, isTablet } from '../../utils/responsive';
import { getScreenNameFromPostType } from '../../utils/navigation';
import { NavigationParamList, ScreenName } from '../../navigation/types';
import { Notification as NotificationInterface } from '../../client/interfaces';
import client from '../../client/client';
import { Icon } from '../index';
import svgIcons from '../../assets';
import { useExtremeEvents } from '../../contexts/ExtremeEvent/ExtremeEventContext';

interface Props {
  notificationData: NotificationInterface;
}
const Notification: React.FC<Props> = ({ notificationData }) => {
  const [isHovered, setIsHovered] = useState(false);
  const cardType =
    notificationData.type === 'article'
      ? ScreenName.ArticleScreen
      : notificationData.type === 'event'
        ? ScreenName.EventScreen
        : ScreenName.HomeScreen;
  const navigation = useNavigation<NavigationProp<NavigationParamList, typeof cardType>>();
  const { fetchExtremeEvents } = useExtremeEvents();

  const notificationBackground = isMobile ? 'bg-none' : 'bg-white dark:bg-black';

  const extremeEventNotificationPress = async (id) => {
    await client.deleteExtremeEventSeen(id);
    fetchExtremeEvents();
    navigation.navigate(ScreenName.HomeScreen);
  };

  return (
    notificationData && (
      <View className="w-full">
        <Pressable
          className={cln(
            'flex-row items-center px-4',
            isMobile && !isTablet ? 'py-[10px] h-[72px]' : 'py-[18px] h-[88px]',
            isHovered ? 'bg-neutral-200 dark:bg-neutral-910' : notificationBackground,
          )}
          onPress={() => {
            if (cardType === ScreenName.ArticleScreen || cardType === ScreenName.EventScreen) {
              navigation.navigate(getScreenNameFromPostType(notificationData.type), {
                postId: notificationData.postId,
              });
            } else {
              extremeEventNotificationPress(notificationData.postId);
            }
          }}
          onHoverIn={() => setIsHovered(true)}
          onHoverOut={() => setIsHovered(false)}
        >
          {cardType === ScreenName.HomeScreen ? (
            <>
              <View
                className={cln(
                  'w-3 h-3 rounded-lg mr-2',
                  notificationData?.seenAtUtc ? 'bg-none' : 'bg-primary-600 dark:bg-primary-500',
                )}
              />
              <Icon
                icon={notificationData?.seenAtUtc ? svgIcons.alertReadIcon : svgIcons.alertIcon}
                mobileSize={{ width: 48, height: 48 }}
                webSize={{ width: 48, height: 48 }}
                classNames={'left-[4px]'}
              />
            </>
          ) : (
            <>
              <View
                className={cln(
                  'w-3 h-3 rounded-lg mr-4',
                  notificationData?.seenAtUtc ? 'bg-none' : 'bg-primary-600 dark:bg-primary-500',
                )}
              />

              <Image
                source={{ uri: notificationData?.imageUrl }}
                resizeMode={'cover'}
                width={40}
                height={40}
                style={{ borderRadius: 20, height: 40, width: 40 }}
              />
            </>
          )}

          {/*TEXT CONTAINER*/}
          <View style={{ flex: 1 }}>
            <Text
              className={cln(
                'text-notificationMessage font-[sans-700] ml-4 py-[2px] max-h-12 overflow-hidden',
                !notificationData?.seenAtUtc && cardType === ScreenName.HomeScreen
                  ? 'text-danger-500'
                  : !notificationData?.seenAtUtc && cardType !== ScreenName.HomeScreen
                    ? 'text-neutral-950 dark:text-neutral-50'
                    : 'text-neutral-600 dark:text-neutral-400',
              )}
            >
              {notificationData?.titleTranslation}
            </Text>
            <Text
              className={cln(
                'text-notificationSubtext font-[sans-400] ml-4 py-[2px]',
                notificationData?.seenAtUtc
                  ? 'text-neutral-600 dark:text-neutral-400'
                  : 'text-neutral-900 dark:text-neutral-300',
              )}
            >
              {notificationData?.timeAgo}
            </Text>
          </View>
        </Pressable>
      </View>
    )
  );
};
export default Notification;
