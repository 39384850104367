// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Pressable, View, Text, Dimensions } from 'react-native';
import { CommonActions } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { useColorScheme } from 'nativewind';
import { useTranslation } from 'react-i18next';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { isMobile, isWeb, isNative, isTablet } from '../utils/responsive';
import useOnClickOutside from '../hooks/useOnClickOutside';
import svgIcons from '../assets/index';
import { Icon, NotificationPopover, ProfileMenuHeader } from '../components';
import { useAuth } from '../contexts/AuthContext/AuthContext';
import { NotificationsScreen } from '../screens';
import { useNotifications } from '../contexts/Notification/NotificationContext';
import { Navigator, ScreenName } from './types';
import ProfileImage from './ProfileImage';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

const ProfileMenu = ({ state, navigation, descriptors }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  // Close profile menu on clicking outside of it
  const { visible, setVisible, reference } = useOnClickOutside(false);
  const { colorScheme } = useColorScheme();
  const { me, logout } = useAuth();
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [isNotificationsScreen, setIsNotificationsScreen] = useState(false);
  const { notifications } = useNotifications();
  const [notiVisible, setNotiVisible] = useState(false);

  // TO CLOSE NOTIFICATIONS SCREEN WHEN WE NAVIGATE SOMEWHERE ELSE
  useEffect(() => {
    setIsNotificationsScreen(false);
    setNotiVisible(false);
  }, [state]);

  let backgroundColor: string;
  // Dark theme menu button and text color
  if (colorScheme === 'dark' && !notiVisible) {
    backgroundColor = isHovered ? 'rgba(246,246,246,0.1)' : 'rgba(0,0,0,0)';
  } else if (colorScheme === 'dark' && notiVisible) {
    backgroundColor = '#ffffff';
  }

  // Light theme menu button and text color
  if (colorScheme === 'light' && !notiVisible) {
    backgroundColor = isHovered ? 'rgba(16,16,16,0.1)' : 'rgba(0,0,0,0)';
  } else if (colorScheme === 'light' && notiVisible) {
    backgroundColor = '#000000';
  }

  // Content of screens, Layout component, gets a smaller padding below 768px so I added it to profile bar as well
  const isWidthBelowMedium = Dimensions.get('window').width < 768;

  function toggleMenu() {
    setVisible((prevState) => !prevState);
  }

  function toggleNotificationMenu() {
    setNotiVisible((prevState) => !prevState);
  }

  const insets = useSafeAreaInsets();

  const notificationsScreenHeight = Dimensions.get('screen').height - insets.bottom - 48;

  return isNotificationsScreen ? (
    <View
      style={{
        position: 'absolute',
        width: '100%',
        height: notificationsScreenHeight,
      }}
    >
      <ProfileMenuHeader onPressLogo={() => navigation.navigate(ScreenName.HomeScreen)}>
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          {/*  Notification nav button*/}
          <Pressable
            onPress={() => setIsNotificationsScreen(true)}
            style={{
              display: 'flex',
              width: 44,
              height: 56,
              zIndex: 20,
              cursor: 'pointer',
              position: 'relative',
              marginRight: isNative ? 4 : isWidthBelowMedium ? 4 : 40,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon
              icon={
                notifications?.some((notification) => !notification.seenAtUtc)
                  ? svgIcons.notificationNewIcon
                  : svgIcons.NotificationsScreen
              }
              mobileSize={{ width: 24, height: 24 }}
              webSize={{ width: 32, height: 32 }}
            />
          </Pressable>
        </View>

        {/*  Reference container, if we click outside this view, it closes the profile menu*/}
        <View
          ref={reference}
          style={{
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <ProfileImage
            imageUrl={me?.profilePhoto ? `data:image/jpeg;base64,${me?.profilePhoto}` : null}
            onPress={() => navigation.navigate(ScreenName.ProfileScreen)}
          />
        </View>
      </ProfileMenuHeader>
      <NotificationsScreen
        onPressBackButton={() => setIsNotificationsScreen(false)}
        isNotificationsScreen={isNotificationsScreen}
      />
    </View>
  ) : (
    <Pressable
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: 'default',
      }}
      pointerEvents={notiVisible ? 'auto' : 'box-none'}
      onPress={() => {
        if (notiVisible) {
          setNotiVisible(false);
        }
      }}
    >
      <ProfileMenuHeader onPressLogo={() => navigation.navigate(ScreenName.HomeScreen)}>
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          {/*  Notification nav button*/}
          {!isTablet && isMobile ? (
            <Pressable
              onPress={() => setIsNotificationsScreen(true)}
              style={{
                display: 'flex',
                width: 44,
                height: 56,
                zIndex: 20,
                cursor: 'pointer',
                position: 'relative',
                marginRight: isNative ? 4 : isWidthBelowMedium ? 4 : 40,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon
                icon={
                  notifications?.some((notification) => !notification.seenAtUtc)
                    ? svgIcons.notificationNewIcon
                    : svgIcons.NotificationsScreen
                }
                mobileSize={{ width: 24, height: 24 }}
                webSize={{ width: 32, height: 32 }}
              />
            </Pressable>
          ) : (
            <Pressable
              onPress={toggleNotificationMenu}
              onHoverIn={() => setIsHovered(true)}
              onHoverOut={() => setIsHovered(false)}
              style={[
                {
                  display: 'flex',
                  position: 'relative',
                  width: 56,
                  height: 56,
                  zIndex: 0,
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: backgroundColor,
                }, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                isWeb ? { transition: 'all 0.3s ease-in-out' } : null,
              ]}
            >
              <Icon
                icon={
                  notifications?.some((notification) => !notification.seenAtUtc)
                    ? svgIcons.notificationNewIcon
                    : svgIcons.NotificationsScreen
                }
                mobileSize={{ width: 30, height: 30 }}
                webSize={{ width: 32, height: 32 }}
                inverted={notiVisible}
              />
            </Pressable>
          )}
          {notiVisible && <NotificationPopover notiVisible={notiVisible} />}
        </View>
        {/*  Reference container, if we click outside this view, it closes the profile menu*/}
        <View
          ref={reference}
          style={{
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          {/*  On mobile we navigate to Profile screen, on web we just toggle profile menu*/}
          {isMobile ? (
            <ProfileImage
              imageUrl={me?.profilePhoto ? `data:image/jpeg;base64,${me?.profilePhoto}` : null}
              onPress={() => navigation.navigate(ScreenName.ProfileScreen)}
            />
          ) : (
            <ProfileImage
              imageUrl={me?.profilePhoto ? `data:image/jpeg;base64,${me?.profilePhoto}` : null}
              onPress={toggleMenu}
            />
          )}

          {/*  Profile menu */}
          <View
            style={{
              display: visible ? 'flex' : 'none',
              position: 'absolute',
              right: 0,
              top: 56,
              width: 179,
              height: '100%',
              flexDirection: 'column',
              backgroundColor: '#101010',
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
            }}
          >
            {state.routes.map((route, index) => {
              const routeKey = route.key;
              const isHovered = hoveredItem === routeKey;

              return (
                route.name.startsWith(Navigator.profile) && (
                  <Pressable
                    key={route.key}
                    onPress={() => {
                      const isFocused = state.index === index;
                      const event = navigation.emit({
                        type: 'tabPress',
                        target: route.key,
                        canPreventDefault: true,
                        data: {
                          isAlreadyFocused: isFocused,
                        },
                      });

                      if (!isFocused && !event.defaultPrevented) {
                        navigation.dispatch({
                          ...CommonActions.navigate(route),
                          target: state.key,
                        });
                      }
                      setVisible(false);
                    }}
                    onPressIn={() => setHoveredItem(routeKey)}
                    onPressOut={() => setHoveredItem(null)}
                    onHoverIn={() => setHoveredItem(routeKey)}
                    onHoverOut={() => setHoveredItem(null)}
                    style={{
                      padding: 17.5,
                      paddingLeft: 20,
                      display: 'flex',
                      width: '100%',
                      backgroundColor: isHovered
                        ? `${colorScheme === 'dark' ? '#272727' : '#dfdfdf'}`
                        : colorScheme === 'dark'
                          ? '#000'
                          : '#fff',
                      zIndex: 20,
                      cursor: 'pointer',
                    }}
                  >
                    <Text
                      style={{
                        color: colorScheme === 'dark' ? '#F6F6F6' : '#101010',
                      }}
                    >
                      {descriptors[route.key].options.title ?? route.name}
                    </Text>
                  </Pressable>
                )
              );
            })}
            <Pressable
              key={'logout'}
              onPress={async () => {
                await logout();
                setVisible(false);
              }}
              onPressIn={() => setHoveredItem('logout')}
              onPressOut={() => setHoveredItem(null)}
              onHoverIn={() => setHoveredItem('logout')}
              onHoverOut={() => setHoveredItem(null)}
              style={{
                padding: 17.5,
                paddingLeft: 20,
                display: 'flex',
                width: '100%',
                backgroundColor:
                  hoveredItem === 'logout'
                    ? `${colorScheme === 'dark' ? '#272727' : '#dfdfdf'}`
                    : colorScheme === 'dark'
                      ? '#000'
                      : '#fff',
                zIndex: 20,
                cursor: 'pointer',
              }}
            >
              <Text
                style={{
                  color: colorScheme === 'dark' ? '#F6F6F6' : '#101010',
                }}
              >
                {t('common:logout')}
              </Text>
            </Pressable>
          </View>
        </View>
      </ProfileMenuHeader>
    </Pressable>
  );
};
export default ProfileMenu;
