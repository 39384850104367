export const IAM_BE_ENDPOINTS = {
  REFRESH_TOKEN: '/auth/refresh',
  LOGOUT: '/auth/logout',
};

export const ENDPOINTS = {
  MODULES: '/modules/user',
  MODULES_ORDER: '/modules/user/order',
  ARTICLES: '/articles',
  HIGHLIGHTED_ARTICLES: '/articles/highlighted',
  SIMILAR_ARTICLES: '/articles/similar',
  POSTS: '/posts',
  BOOKMARKED: '/posts/bookmarked',
  TAGS: '/tags',
  TAG_DELETE: '/tags/delete',
  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_SEEN: '/notifications/seen',
  USERS_ME: '/users/me',
  CREATE_PUSH_TOKEN: '/users/push-token',
  SET_ONBOARDED: '/users/onboarding',
  SAVE_USER_LANGUAGE: '/users/language',
  ADMIN_POSTS: '/admin/posts',
  ADMIN_ARTICLES: '/admin/articles',
  ADMIN_EXTREME_EVENTS: '/admin/extreme-events',
  ADMIN_EVENTS: '/admin/events',
  ADMIN_FILES: '/admin/files',
  EVENTS: '/events',
  SIMILAR_EVENTS: '/events/similar',
  EXTREME_EVENT_UNSEEN: '/extreme-event/unseen',
  EXTREME_EVENT_SEEN: '/extreme-event/seen',
  CALENDAR_EVENTS: '/calendar',
};
