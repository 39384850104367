import React from 'react';
import { Pressable, Image } from 'react-native';
import { useColorScheme } from 'nativewind';
import svgIcons from '../assets';
import { Icon } from '../components';
import { isMobile, isTablet, isWeb } from '../utils/responsive';

interface Props {
  imageUrl: string;
  onPress: () => void;
}

const ProfileImage: React.FC<Props> = ({ imageUrl = null, onPress }) => {
  const { colorScheme } = useColorScheme();
  return (
    <Pressable
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        width: isTablet ? 56 : isMobile ? 40 : 56,
        height: 56,
        marginLeft: isWeb && !isMobile ? 12 : 0,
      }}
      onPress={onPress}
    >
      {imageUrl ? (
        <Image
          source={{
            uri: imageUrl,
          }}
          style={{
            width: isTablet ? 32 : isMobile ? 24 : 40,
            height: isTablet ? 32 : isMobile ? 24 : 40,
            borderRadius: 40,
            borderColor: colorScheme === 'dark' ? '#101010' : '#f6f6f6',
            borderWidth: 1,
          }}
        />
      ) : (
        <Icon
          icon={svgIcons.defaultProfilePicture}
          mobileSize={{ width: 24, height: 24 }}
          webSize={{ width: 32, height: 32 }}
        />
      )}
    </Pressable>
  );
};

export default ProfileImage;
