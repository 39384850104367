import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Language } from '../../resources/interfaces';
import en from './en/common.json';
import hu from './hu/common.json';

const resources = {
  // list of languages
  en,
  hu,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3', //To make it work for Android devices, add this line.
    resources,
    lng: Language.en, // default language to use.
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
