import React from 'react';
import { Text, View, Pressable, ViewStyle } from 'react-native';
import { t } from 'i18next';
import dayjs from 'dayjs';
import svgIcons from '../../assets';
import { Icon } from '../index';
import { CalendarCardItem } from '../../client/interfaces';

import { cln } from '../../utils/classnames';
import { getDateDetails } from '../../utils/getDateDetails';
import { handleLinkPress } from '../../utils';
import { isWeb } from '../../utils/responsive';

interface Props {
  item?: CalendarCardItem;
  styleProp?: ViewStyle;
}

const CalendarCard: React.FC<Props> = ({ item, styleProp }) => {
  const dateDetails = getDateDetails(item);
  const isTodayEvent =
    dateDetails.startDate === dayjs().format('YYYY-MM-DD') &&
    dateDetails.endDate === dateDetails.startDate;
  const isTomorrowEvent = dateDetails.startDate === dayjs().add(1, 'day').format('YYYY-MM-DD');
  const isTodayOrTomorrow = isTodayEvent || isTomorrowEvent;
  return (
    <View style={styleProp ? styleProp : { width: '100%' }}>
      {/*Eppen zajlik ful*/}
      {item.shouldShowNow || item.next ? (
        <View className="bg-neutral-100 dark:bg-neutral-930 px-4 py-2 mb-1 self-start h-8">
          <Text
            className={cln(
              item.shouldShowNow
                ? 'text-primary-600 dark:text-primary-500'
                : 'text-neutral-930 dark:text-neutral-50',
              'text-notificationMessage font-[sans-600]',
            )}
          >
            {item.shouldShowNow ? t('today:currently') : t('today:next')}
          </Text>
        </View>
      ) : (
        <View className="h-8 mb-1" />
      )}
      {/*EPPEN ZAJLIK VONAL*/}
      <View
        className={cln(
          item.shouldShowNow || item.shouldShowNowLine
            ? 'bg-primary-600 dark:bg-primary-500'
            : 'bg-neutral-930 dark:bg-neutral-50',
          'h-[3px]',
        )}
      />
      {/*MAIN CONTAINER*/}
      <View className="bg-neutral-100 dark:bg-neutral-930 px-4 pt-[13px] pb-5 items-start justify-start w-full flex-1">
        {/*DATUM CONTAIENR*/}
        <View className="h-[32px] mb-2">
          {isTodayOrTomorrow ? (
            <View className="flex-row flex-wrap items-start justify-start relative mb-2">
              <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-400] text-calendarCardDate mr-2">
                {isTodayEvent ? t('events:today') : t('events:tomorrow')}
              </Text>
              <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-400] text-calendarCardDate">
                {isTodayEvent
                  ? `${dateDetails.startTime}${dateDetails.startTime ? '-' : ''}${dateDetails.endTime}`
                  : `${dateDetails.startTime}-${dateDetails.endTime}`}
              </Text>
            </View>
          ) : (
            <View className="flex-row flex-wrap items-start justify-start relative mb-2">
              <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-400] text-calendarCardDate">
                {dateDetails.dateFirstLine} {dateDetails.dateSecondLine ? '-' : ''}{' '}
              </Text>
              <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-400] text-calendarCardDate">
                {dateDetails.dateSecondLine}
              </Text>
            </View>
          )}
        </View>

        {/*TITLE*/}
        <Text
          className="text-neutral-930 dark:text-neutral-50 text-calendarCardTitle mb-4 font-[sans-600]"
          numberOfLines={4}
        >
          {item?.name}
        </Text>
        {/*LOCATION AND JOIN LINK CONTAINER*/}
        <View className="items-start mt-auto">
          {/*LOCATION CONTAINER*/}
          {item.location ? (
            <View className="flex-row mb-1 items-center">
              <Icon
                icon={svgIcons.pinIcon}
                mobileSize={{ width: 20, height: 20 }}
                webSize={{ width: 16, height: 16 }}
                classNames={'mr-3 bottom-[1px]'}
              />
              <View className="justify-center h-[28px] flex-1">
                <Text
                  className="flex-wrap text-neutral-930 dark:text-neutral-50 text-calendarCardDetails font-[sans-400]"
                  numberOfLines={2}
                >
                  {item.location}
                </Text>
              </View>
            </View>
          ) : (
            <View style={{ height: 16 }} />
          )}
          {/*JOIN LINK PRESSABLE*/}
          {item.joinLink ? (
            <Pressable
              className="flex-row items-center justify-center"
              onPress={() => handleLinkPress(item.joinLink)}
            >
              <Icon
                icon={svgIcons.filmIconPrimary}
                mobileSize={{ width: 20, height: 20 }}
                webSize={{ width: 16, height: 16 }}
                classNames={'mr-3'}
              />

              <Text className="flex-1 text-primary-600 dark:text-primary-500 text-calendarCardDetails font-[sans-400] underline">
                {t('today:join_online')}
              </Text>
            </Pressable>
          ) : (
            <View style={{ height: 16 }} />
          )}
        </View>
      </View>
    </View>
  );
};

export default CalendarCard;
