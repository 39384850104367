import React from 'react';
import { View, Pressable, Text } from 'react-native';
import { cln } from '../../utils/classnames';
import { Icon } from '../../components/index';
import svgIcons from '../../assets';
interface DatePickerStepperProps {
  onClickBack(): void;
  onClickForward(): void;
  label: string;
  onClickLabel?(): void;
  border?: boolean;
}

const DatePickerStepper = ({
  onClickBack,
  onClickForward,
  onClickLabel,
  label,
  border = false,
}: DatePickerStepperProps): JSX.Element => {
  const handleLabelClick = () => {
    if (onClickLabel) {
      onClickLabel();
    }
  };

  return (
    <View
      className="flex-row items-center justify-center"
      style={{
        width: '100%',
        alignSelf: 'center',
        // maxWidth: 420,
        flex: 1,
      }}
    >
      <Pressable
        className="px-2 py-2 absolute"
        onPress={onClickBack}
        style={{
          left: '2%',
        }}
      >
        <Icon
          icon={svgIcons.arrowLeftIcon}
          mobileSize={{ width: 32, height: 32 }}
          webSize={{ width: 32, height: 32 }}
        />
      </Pressable>
      <Pressable
        className={cln('cursor-pointer flex items-center', border && 'border border-neutral-500')}
        onPress={handleLabelClick}
        style={{
          paddingHorizontal: 12,
          paddingVertical: 12,
          borderWidth: 1,
          borderColor: '#6d6d6d',
        }}
      >
        <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-600] text-label">
          {label}
        </Text>
      </Pressable>
      <Pressable
        className="px-2 py-2 absolute"
        onPress={onClickForward}
        style={{
          right: '2%',
        }}
      >
        <Icon
          icon={svgIcons.arrowRightIcon}
          mobileSize={{ width: 32, height: 32 }}
          webSize={{ width: 32, height: 32 }}
        />
      </Pressable>
    </View>
  );
};

export default DatePickerStepper;
