import React from 'react';
import { View, Text, Pressable } from 'react-native';
import { cln } from '../../utils/classnames';
import svgIcons from '../../assets';
import { Icon } from '../index';
import { PropsWithClassName } from '../../resources/interfaces';

export interface CheckboxProps extends PropsWithClassName {
  checked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  label?: string;
  onPress?: () => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  classNames,
  disabled = false,
  hasError = false,
  label,
  onPress = () => {},
}) => {
  let opacity = 1;
  if (disabled) {
    opacity = 0.4;
  }

  const borderClassNames = disabled
    ? 'border-neutral-900'
    : hasError
      ? 'border-danger-500'
      : 'border-neutral-950 dark:border-neutral-50';

  return (
    <Pressable
      onPress={onPress}
      disabled={disabled}
      className={cln('flex flex-row items-center mr-6', classNames)}
    >
      <View
        className={cln(
          'w-6 h-6 flex items-center justify-center border cursor-pointer mr-2',
          borderClassNames,
          disabled && 'bg-neutral-900',
        )}
        style={{
          opacity,
        }}
      >
        {checked && (
          <Icon
            icon={svgIcons.checkmarkIcon}
            webSize={{ width: 18, height: 12 }}
            mobileSize={{ width: 18, height: 12 }}
          />
        )}
      </View>
      {label && (
        <Text
          className={cln(
            'text-input font-[sans-600]',
            disabled
              ? 'text-neutral-900 dark:text-neutral-900'
              : 'text-neutral-950 dark:text-neutral-50',
          )}
        >
          {label}
        </Text>
      )}
    </Pressable>
  );
};

export default Checkbox;
