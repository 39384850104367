import React from 'react';
import { Image, ImageProps, Platform, View } from 'react-native';
import { useColorScheme } from 'nativewind';
import { SvgIcon } from '../../assets';
import { isMobileWeb } from '../../utils/responsive';
import { PropsWithClassName, ThemeOption } from '../../resources/interfaces';
import { getOppositeColorScheme } from '../../utils';

interface Dimensions {
  width: number;
  height: number;
}
interface Props extends PropsWithClassName {
  icon: SvgIcon;
  mobileSize: Dimensions;
  webSize: Dimensions;
  style?: ImageProps | Readonly<ImageProps>;
  isHovered?: boolean;
  isFocused?: boolean;
  inverted?: boolean;
}

const Icon: React.FC<Props> = ({
  webSize,
  mobileSize,
  icon,
  style,
  isHovered,
  isFocused,
  inverted,
  classNames,
}) => {
  const { colorScheme } = useColorScheme();

  const iconColor =
    isFocused || inverted
      ? colorScheme
      : isHovered
        ? ThemeOption.dark
        : getOppositeColorScheme(colorScheme);

  const MobileIcon = icon.mobile[iconColor];
  const webIcon = icon.web[iconColor];

  //If the screen size is mobile width, but we are on the web, render the icons with the mobile size, but the web source
  return (
    <View className={classNames}>
      {Platform.OS === 'web' ? (
        isMobileWeb ? (
          <Image source={webIcon} style={{ ...mobileSize, ...style }} />
        ) : (
          <Image source={webIcon} style={{ ...webSize, ...style }} />
        )
      ) : (
        <MobileIcon {...mobileSize} style={style} />
      )}
    </View>
  );
};

export default Icon;
