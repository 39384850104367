import React, { useRef } from 'react';
import { View, Text, TextInputProps } from 'react-native';
import { FormikErrors, useField } from 'formik';
import dayjs from 'dayjs';
import { cln } from '../../utils/classnames';
import DatePicker from '../DatePicker';
import { DateTimeType } from '../DatePicker/CustomDatePicker';

interface Props extends TextInputProps {
  title: string;
  name: string;
  required?: boolean;
  style?: unknown;
  type?: DateTimeType;
  errorMessage?: FormikErrors<unknown>;
  helperText?: string;
}

const FormDate: React.FC<Props> = ({
  title,
  name,
  required = false,
  style,
  value,
  type,
  errorMessage,
  helperText,
}) => {
  const [field, meta, helpers] = useField(name);

  const containerRef = useRef(null);

  return (
    <View className="mb-11 relative z-50" style={style}>
      <View className="flex-row mb-1">
        <Text className="text-input text-neutral-950 dark:text-neutral-50 font-[sans-600]">
          {title}
        </Text>
        {required && <Text className="text-input text-danger-500 font-[sans-400]"> *</Text>}
      </View>

      <DatePicker
        value={field?.value ? field.value : dayjs(Date.now()).format('YYYY-MM-DD')}
        name={name}
        setValue={(value) => {
          helpers.setValue(value);
        }}
        onBlur={() => {
          field.onBlur(name);
        }}
        blurOnSubmit={true}
        tabIndex={0}
        portalTo={containerRef?.current}
        isModal={true}
        type={type}
        errorMessage={meta.touched && meta.error}
      />
      <View>
        <Text
          className={cln(
            'text-notificationMessage text-danger-500 font-[sans-600] absolute bottom-[-38px]',
          )}
        >
          {meta.touched && meta.error}
        </Text>
        {helperText && (
          <Text
            className={cln(
              'text-notificationMessage text-neutral-930 dark:text-neutral-50 font-[sans-600] absolute',
              meta.touched && meta.error ? 'bottom-[-72px]' : 'bottom-[-38px]',
            )}
          >
            {helperText}
          </Text>
        )}
      </View>
    </View>
  );
};

export default FormDate;
