import React, { PropsWithChildren } from 'react';
import { View, Text } from 'react-native';
import { PropsWithClassName } from '../../resources/interfaces';
import { cln } from '../../utils/classnames';

interface Props extends PropsWithChildren, PropsWithClassName {
  textClassNames?: string;
  text?: string;
}

const TableCell: React.FC<Props> = ({ children, classNames, text, textClassNames }) => {
  return (
    <View className={cln('flex-grow align-center items-start px-3 py-6', classNames)}>
      {!!text && (
        <Text
          className={cln(
            'text-tableRow text-neutral-950 dark:text-neutral-50 font-[sans-400]',
            textClassNames,
          )}
        >
          {text}
        </Text>
      )}

      {children}
    </View>
  );
};

export default TableCell;
