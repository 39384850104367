import React, { useState } from 'react';
import { Pressable, StyleProp, Text, View, ViewStyle } from 'react-native';
import { cln } from '../../utils/classnames';
import { isMobile, isTablet, isWeb } from '../../utils/responsive';
import { Icon } from '../index';
import svgIcons from '../../assets';

interface Props {
  text: string;
  variant?: 'fill' | 'outline' | 'link';
  size?: 'wide' | 'medium';
  disabled?: boolean;
  iconName?: string;
  onPress?: (event: unknown) => void;
  style?: StyleProp<ViewStyle>;
}

const Button: React.FC<Props> = ({ text, variant, size, disabled, iconName, onPress, style }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const buttonStyles = {
    buttonBase: 'justify-center items-center h-[48px] flex-row',
    textBase: 'text-md font-[sans-400] text-center',
    disabledButton: 'bg-secondary-700 dark:bg-primary-200',
    disabledText: 'text-neutral-500 dark:text-neutral-700',
    buttonWidth: {
      wide: isTablet || !isMobile ? 'w-full max-w-[540px]' : 'w-full',
      medium: isTablet || !isMobile ? 'w-full w-[256px]' : 'w-full',
    },
    fill: {
      default: 'bg-primaryLight-500 dark:bg-primary-500',
      hovered: 'bg-primaryLight-300 dark:bg-primary-300',
      pressed: 'bg-primaryLight-700 dark:bg-primary-700',
      disabled: 'bg-neutral-200 dark:bg-neutral-300',
      text: 'text-neutral-50 dark:text-neutral-950 font-[sans-400]',
      textHovered: 'text-neutral-50 dark:text-neutral-950 font-[sans-600] tracking-[-0.015em]',
      textPressed: 'text-neutral-50 dark:text-neutral-950 font-[sans-700]',
      textDisabled: 'font-[sans-400] text-neutral-400 dark:text-neutral-500',
    },
    outline: {
      default: 'border-2 border-neutral-950 dark:border-neutral-50',
      hovered:
        'border-2 bg-neutral-200 dark:bg-neutral-700 border-neutral-950 dark:border-neutral-50',
      pressed:
        'border-[3px] bg-neutral-300 dark:bg-neutral-930 border-neutral-950 dark:border-neutral-50',
      disabled: 'bg-none border-2 border-neutral-400 dark:border-neutral-500',
      text: 'text-neutral-950 dark:text-neutral-50 font-[sans-400]',
      textHovered: 'text-neutral-950 dark:text-neutral-50 font-[sans-600] tracking-[-0.015em]',
      textPressed: 'text-neutral-950 dark:text-neutral-50 font-[sans-700]',
      textDisabled: 'font-[sans-400] text-neutral-400 dark:text-neutral-500',
    },
    link: {
      default: 'bg-none',
      hovered: 'bg-none',
      pressed: 'bg-none',
      disabled: 'bg-none',
      text: 'text-neutral-950 dark:text-neutral-50 font-[sans-400]',
      textHovered: 'text-neutral-950 dark:text-neutral-50 font-[sans-600] tracking-[-0.015em]',
      textPressed: 'text-neutral-950 dark:text-neutral-50 font-[sans-700]',
      textDisabled: 'font-[sans-400] text-neutral-400 dark:text-neutral-500',
    },
  };

  let finalStyle;
  let textStyle;
  switch (true) {
    case isActive:
      finalStyle = buttonStyles[variant]?.pressed;
      textStyle = buttonStyles[variant]?.textPressed;
      break;
    case isHovered || isFocused:
      finalStyle = buttonStyles[variant]?.hovered;
      textStyle = buttonStyles[variant]?.textHovered;
      break;
    case disabled:
      finalStyle = buttonStyles[variant]?.disabled;
      textStyle = buttonStyles[variant]?.textDisabled;
      break;
    default:
      finalStyle = buttonStyles[variant]?.default;
      textStyle = buttonStyles[variant]?.text;
  }

  return (
    <View style={style}>
      <Pressable
        className={cln(buttonStyles.buttonBase, buttonStyles.buttonWidth[size], finalStyle)}
        style={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          isWeb ? { transition: 'all 0.1s ease-in-out' } : null
        }
        disabled={disabled}
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => {
          setIsFocused(false);
          setIsHovered(false);
        }}
        onPress={onPress}
        onPressIn={() => setIsActive(true)}
        onPressOut={() => setIsActive(false)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <Text className={cln(buttonStyles.textBase, textStyle)}>{text}</Text>
        {iconName && (
          <Icon
            icon={svgIcons[iconName]}
            mobileSize={{ width: 24, height: 24 }}
            webSize={{ width: 24, height: 24 }}
            classNames="ml-6 bottom-px mt-[1px]"
          />
        )}
      </Pressable>
    </View>
  );
};
export default Button;
