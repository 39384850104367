import React, { FC, useEffect, useRef } from 'react';
import { StyleSheet, View, Animated } from 'react-native';
import { useColorScheme } from 'nativewind';
interface Props {
  isLoading: boolean;
}

const ProgressBar: FC<Props> = ({ isLoading }) => {
  const animatedValue = useRef(new Animated.Value(0)).current; // Initial animation value
  const { colorScheme } = useColorScheme();
  useEffect(() => {
    const startAnimation = () => {
      // Reset animation value to 0
      animatedValue.setValue(0);
      // Configure and start the animation
      Animated.timing(animatedValue, {
        toValue: 1, // Animate to the final value of 1
        duration: 1500, // Animation duration
        useNativeDriver: true, // Use native driver for better performance
        delay: 500, // Start with a delay
      }).start(({ finished }) => {
        if (finished && isLoading) {
          startAnimation(); // Loop the animation if isLoading is true
        }
      });
    };

    if (isLoading) {
      startAnimation(); // Start animation if isLoading is true
    } else {
      animatedValue.stopAnimation(); // Stop the animation if isLoading is false
    }

    return () => {
      animatedValue.stopAnimation(); // Cleanup the animation on component unmount
    };
  }, [animatedValue, isLoading]);

  const translateX = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0%', '400%'], // Translate from 0% to 400% of its width
  });

  return (
    <View style={styles.container}>
      <Animated.View
        style={[
          styles.loadingBar,
          {
            width: isLoading ? '25%' : '100%', // Adjust width based on isLoading prop
            transform: [{ translateX: isLoading ? translateX : '0%' }],
            backgroundColor: isLoading
              ? '#007bff'
              : `${colorScheme === 'dark' ? '#29FF8C' : '#00A34B'}`,
          },
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 4, // Set a fixed height for the container
    overflow: 'hidden', // Ensure the animated view is clipped
    backgroundColor: 'transparent', // Optional: set the container background
  },
  loadingBar: {
    height: '100%',
  },
});

export default ProgressBar;
