import React, { FC } from 'react';
import { Platform } from 'react-native';
import { Layout, FeedSection, EventSection, TodaySection } from '../../components/index';
import {
  default as SectionListWeb,
  default as SectionListNative,
} from '../../components/SectionList/index';
import { NavigationParamList, ScreenName, TabNavigationScreenProps } from '../../navigation/types';
import { useModules } from '../../contexts/Module/ModuleContext';
import { ModuleKey, Role } from '../../client/interfaces';
import { isNative } from '../../utils/responsive';

const modulesToComponents: { [key in ModuleKey]: FC } = {
  [ModuleKey.feed]: FeedSection,
  [ModuleKey.today]: TodaySection,
  [ModuleKey.event]: EventSection,
  [ModuleKey.canteen]: null,
  [ModuleKey.sport]: null,
};

const modulesToRoles: { [key in ModuleKey]: Role | null } = {
  [ModuleKey.feed]: Role.publisher,
  [ModuleKey.today]: null,
  [ModuleKey.event]: Role.publisher,
  [ModuleKey.canteen]: null,
  [ModuleKey.sport]: null,
};

const HomeScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.HomeScreen>
> = () => {
  const { modules, setModules } = useModules();

  const sectionListCommonProps = {
    sections: modules,
    setter: setModules,
    componentMap: modulesToComponents,
  };

  return (
    <Layout isScrollView={!isNative} isHomeScreen={true}>
      {Platform.OS === 'web' ? (
        <SectionListWeb {...sectionListCommonProps} roleMap={modulesToRoles} />
      ) : (
        <SectionListNative {...sectionListCommonProps} roleMap={modulesToRoles} />
      )}
    </Layout>
  );
};
export default HomeScreen;
