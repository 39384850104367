import { useState, useEffect } from 'react';
import { ConditionalStorage, StorageKey, StorageValueTypes } from '../../utils/storage';

function useConditionalStorage<T extends StorageKey>(key: T) {
  const [storedValue, setStoredValue] = useState<StorageValueTypes[T] | null>(undefined);

  useEffect(() => {
    (async () => {
      try {
        const item = await ConditionalStorage.getItem(key);
        setStoredValue(item);
      } catch (error) {
        console.error(`Error loading the storage key "${key}":`, error);
      }
    })();
  }, [key]);

  const setValue = async (value: StorageValueTypes[T]) => {
    try {
      await ConditionalStorage.setItem(key, value);
      setStoredValue(value);
    } catch (error) {
      console.error(`Error setting the storage key "${key}":`, error);
    }
  };

  const removeValue = async () => {
    try {
      await ConditionalStorage.removeItem(key);
      setStoredValue(null);
    } catch (error) {
      console.error(`Error removing the storage key "${key}":`, error);
    }
  };

  return [storedValue, setValue, removeValue] as const;
}

export default useConditionalStorage;
