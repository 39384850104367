export enum Language {
  hu = 'hu',
  en = 'en',
}

export enum ThemeOption {
  light = 'light',
  dark = 'dark',
}

export enum ErrorType {
  not_found = 'not_found',
  internal_server_error = 'internal_server_error',
}

export interface PropsWithClassName {
  classNames?: string;
}

export enum TagVariant {
  Filled = 'filled',
  Border = 'border',
  IconTag = 'iconTag',
  Anchor = 'anchor',
  Selected = 'selected',
  Follow = 'follow',
}

export interface Section {
  id: number;
  key: string;
}

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export enum FileUploadStatus {
  inProgress = 'inProgress',
  done = 'done',
}

export interface UploadedFile {
  id?: number;
  file: File;
  status: FileUploadStatus;
  src?: string;
}
