import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Loader, Favorites } from '../../components';
import { useTags } from '../../contexts/Tag/TagContext';

const FavoritesScreen: React.FC = () => {
  const { tags } = useTags();
  const { t } = useTranslation();

  return tags ? (
    <Layout title={t('navigation:favorites')}>
      <Favorites />
    </Layout>
  ) : (
    <Loader />
  );
};

export default FavoritesScreen;
