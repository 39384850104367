import { useTranslation } from 'react-i18next';
import React, { FC, useCallback, useRef } from 'react';
import { Pressable, Text, View } from 'react-native';
import { useDropzone } from 'react-dropzone';
import svgIcons from '../../assets';
import Icon from '../Icon';
import { isWeb } from '../../utils/responsive';

interface Props {
  onDrop: (files: FileList) => void;
  hasError: boolean;
  accepted: string[];
}

export const DropZone: FC<Props> = ({ onDrop, hasError, accepted }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslation();
  const handleDrop = useCallback((acceptedFiles) => {
    onDrop(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: Object.fromEntries(accepted.map((e) => [e, []])),
    maxFiles: 1,
    onError: (err) => console.error(err),
    noClick: true,
    noKeyboard: true,
  });

  return (
    isWeb && (
      <Pressable
        onPress={() => {
          inputRef.current?.click();
        }}
      >
        {/*A DropZone működéséhez szükséges getRootProps csak div komponensen működik, azon viszont a tailwind nem*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 40,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: hasError ? '#FD2C5B' : '#888888',
            opacity: isDragActive ? 0.5 : 1,
            cursor: 'pointer',
          }}
          {...getRootProps()}
        >
          <View className="mb-2.5">
            <Icon
              icon={svgIcons.uploadIcon}
              mobileSize={{ width: 69, height: 60 }}
              webSize={{ width: 69, height: 60 }}
            />
          </View>

          <View className="w-full flex-row items-center justify-center mb-2.5">
            <Text className="font-[sans-400] text-md text-neutral-950 dark:text-neutral-50">
              {t('admin:common:drop_image')}
            </Text>

            <Text className="font-[sans-400] text-md text-primary-600 dark:text-primary-500 underline">
              {t('admin:common:browse_image')}
            </Text>
          </View>
          <View className="flex flex-col items-center">
            <Text className="font-[sans-400] text-label text-neutral-400">
              {t('admin:common:supported_formats')}
            </Text>
            <Text className="font-[sans-400] text-label text-neutral-400">
              {t('admin:common:suggested_resolution')}
            </Text>
            <Text className="font-[sans-400] text-label text-neutral-400">
              {t('admin:common:max_file_size')}
            </Text>
          </View>
          <input
            {...getInputProps()}
            ref={inputRef}
            type="file"
            onChange={(e) => {
              onDrop(e.target.files);
              inputRef.current.value = null; // this reset the input value, this way if u select the same file on change will fire (handleFiles handel the duplicates)
            }}
            accept={accepted.join(', ')} // Valójában nem itt, hanem a useDropzone hook-kal konfigurálható
            hidden
            className="hidden"
          />
        </div>
      </Pressable>
    )
  );
};
