import React from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/index';
import { PostFilter, Button } from '../../components';
import { isMobile } from '../../utils/responsive';

const PostFilterScreen = ({
  onPressBackButton,
  getPostsParams,
  setGetPostsParams,
  resetInfiniteScroll,
  allPosts,
  screen,
}) => {
  const { t } = useTranslation();

  return (
    <Layout
      title={t('common:search')}
      onPressBackButton={onPressBackButton}
      isScrollView={!isMobile}
    >
      <View style={{ marginBottom: 'auto' }}>
        <PostFilter
          getPostsParams={getPostsParams}
          setGetPostsParams={setGetPostsParams}
          resetInfiniteScroll={resetInfiniteScroll}
          allPosts={allPosts}
          screen={screen}
        />
      </View>

      <View
        style={{
          width: '100%',
          bottom: 20,
        }}
      >
        <Button
          text={allPosts?.length < 1 ? t('articles:no_results') : t('articles:view_results')}
          size={'medium'}
          variant={'fill'}
          onPress={onPressBackButton}
          disabled={allPosts?.length < 1}
        />
      </View>
    </Layout>
  );
};

export default PostFilterScreen;
