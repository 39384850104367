import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { isNative } from './responsive';

export const useResize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isWidthBelowSmall, setIsWidthBelowSmall] = useState(window.innerWidth < 640);
  const [isWidthBelowMedium, setIsWidthBelowMedium] = useState(window.innerWidth < 768);
  const [isWidthBelowLarge, setIsWidthBelowLarge] = useState(window.innerWidth < 1024);

  useEffect(() => {
    function watchWidth() {
      setWidth(window.innerWidth);
      setIsWidthBelowSmall(window.innerWidth < 640);
      setIsWidthBelowMedium(window.innerWidth < 768);
      setIsWidthBelowLarge(window.innerWidth < 1024);
    }
    if (Platform.OS === 'web') {
      window.addEventListener('resize', watchWidth);
    }

    return function () {
      if (!isNative) {
        window.removeEventListener('resize', watchWidth);
      }
    };
  }, [isWidthBelowLarge, isWidthBelowMedium, isWidthBelowSmall]);

  return { isWidthBelowSmall, isWidthBelowMedium, isWidthBelowLarge };
};
