import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Pagination as IPagination, PaginationParams } from '../../client/interfaces';
import PaginationArrow from './PaginationArrow';
import PaginationItem from './PaginationItem';

interface Props {
  data: IPagination;
  paramSetter: React.Dispatch<React.SetStateAction<PaginationParams>>;
  params: PaginationParams;
}

export interface IPaginationItem {
  pageNumber: number;
  isActive?: boolean;
}

const Pagination: FC<Props> = ({ data, paramSetter, params }) => {
  const [paginationItems, setPaginationItems] = useState<IPaginationItem[]>([]);

  const getPaginationItems = (): IPaginationItem[] => {
    const maxItemCount = 5;
    const itemCount = Math.min(maxItemCount, data.pageCount);
    const possibleFirstItemNumber = Math.min(data.currentPage - 2, data.pageCount - 4);
    const firstItemNumber = Math.max(1, possibleFirstItemNumber);
    const items = [];

    for (let i = 0; i < itemCount; i++) {
      items.push({
        pageNumber: firstItemNumber + i,
        isActive: firstItemNumber + i === data.currentPage,
      });
    }

    return items;
  };

  const goToPage = (pageNumber: number) => {
    window?.scrollTo(0, 0);
    paramSetter((prev) => ({ ...prev, page: pageNumber }));
  };

  useEffect(() => {
    setPaginationItems(getPaginationItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <View className="mt-10 flex-row items-center justify-end">
      <PaginationArrow variant="first" visible={data.currentPage > 1} onPress={() => goToPage(1)} />
      <PaginationArrow
        variant="left"
        visible={data.currentPage > 1}
        onPress={() => goToPage(data.currentPage - 1)}
      />
      <View className="flex-row mx-6">
        {paginationItems.map((item, index) => (
          <PaginationItem
            key={item.pageNumber}
            index={index}
            item={item}
            onPress={() => {
              !item.isActive && goToPage(item.pageNumber);
            }}
          />
        ))}
      </View>
      <PaginationArrow
        variant="right"
        visible={data.currentPage < data.pageCount}
        onPress={() => goToPage(data.currentPage + 1)}
      />
    </View>
  );
};

export default Pagination;
