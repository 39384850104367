import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import client from '../../client/client';
import { Tag, UserTag } from '../../client/interfaces';
import { useMessage } from '../Messages/MessageContext';

interface TagContext {
  tags: Tag[];
  userTags: UserTag[];
  filterTags: Tag[];
  fetchTags: () => Promise<void>;
}

const Context = createContext<TagContext>({
  tags: [],
  userTags: [],
  filterTags: [],
  fetchTags: async () => {
    return;
  },
});

export const useTags = () => useContext(Context);

export const TagProvider: FC<PropsWithChildren> = ({ children }) => {
  const { setMessage } = useMessage();

  const [tags, setAllTags] = useState<Tag[]>([]);
  const [userTags, setUserTags] = useState<UserTag[]>([]);
  const [filterTags, setFilterTags] = useState<Tag[]>([]);

  const fetchTags = useCallback(
    async () => {
      try {
        const tagsResponse = await client.getAllTags();
        const userTagsResponse = await client.getUserTags();

        tagsResponse.map((t) => {
          const userTag = userTagsResponse.find((ut) => ut.id === t.id);

          if (userTag) {
            t.shouldNotify = userTag.shouldNotify;
          }

          return t;
        });

        setAllTags(tagsResponse);
        setUserTags(userTagsResponse);
        setFilterTags(
          tagsResponse.filter((tag) => {
            return tag.parentId !== null;
          }),
        );
      } catch (error) {
        setMessage({ message: error.message, type: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const value: TagContext = {
    tags: tags || [],
    userTags: userTags || [],
    filterTags: filterTags || [],
    fetchTags,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
