import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import { Pagination } from '../../client/interfaces';
import { PropsWithClassName } from '../../resources/interfaces';
import { cln } from '../../utils/classnames';

export interface Props extends PropsWithClassName {
  pagination: Pagination;
}

const ResultCounter: React.FC<Props> = ({ pagination, classNames }) => {
  const { t } = useTranslation('common');

  return (
    <View className={cln('min-w-[12rem] min-h-[4rem]', classNames)}>
      {!!pagination?.total && !!pagination?.toNumber && !!pagination?.fromNumber && (
        <View className="flex flex-row flex-nowrap">
          <Text className="text-subHeader text-neutral-950 dark:text-neutral-50 font-[sans-700]">
            {pagination?.fromNumber + '-'}
            {pagination?.toNumber + ' / '}
            {pagination?.total + ' '}
          </Text>
          <Text className="text-subHeader text-neutral-950 dark:text-neutral-50 font-[sans-400]">
            {t('common:total_result')}
          </Text>
        </View>
      )}
    </View>
  );
};

export default ResultCounter;
