import React, { useState } from 'react';
import { Pressable, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { cln } from '../../utils/classnames';

interface Props {
  onPress: (code: string) => void;
  activeOption: string;
  options: Option[];
}

interface Option {
  key: string;
  label: string;
}

const ToggleWithText: React.FC<Props> = ({ onPress, activeOption, options }) => {
  const { t } = useTranslation();
  const [hoveredOption, setHoveredOption] = useState(null);
  const [pressedOption, setPressedOption] = useState(null);

  return (
    <>
      {options.map((currentOption) => {
        let tagStyle: string;
        const isActive = currentOption.key === activeOption;
        const isOptionHovered = hoveredOption === currentOption.key && !isActive;
        const isOptionPressed = pressedOption === currentOption.key && !isActive;

        const buttonStyle = 'flex-1 items-center justify-center h-[54] mt-5';

        // Active
        if (isActive) {
          tagStyle = 'bg-neutral-950 dark:bg-neutral-50';
        }

        // Inactive
        if (!isActive && !isOptionHovered) {
          tagStyle = 'bg-none border-neutral-950 dark:border-neutral-50 border-[1px]';
        }

        // Inactive hovered
        if (!isActive && isOptionHovered) {
          tagStyle =
            'bg-neutral-100 dark:bg-neutral-600 border-neutral-950 dark:border-neutral-50 border-[1px]';
        }

        // Inactive pressed
        if (isOptionPressed) {
          tagStyle =
            'bg-neutral-300 dark:bg-neutral-900 border-neutral-950 dark:border-neutral-50 border-[1px]';
        }

        return (
          <Pressable
            key={currentOption.key}
            onHoverIn={() => setHoveredOption(currentOption.key)}
            onHoverOut={() => setHoveredOption(null)}
            className={cln(buttonStyle, tagStyle)}
            onPress={() => {
              if (!isActive) {
                onPress(currentOption.key);
              }
            }}
            onPressIn={() => setPressedOption(currentOption.key)}
            onPressOut={() => setPressedOption(null)}
          >
            <Text
              className={`${
                isActive
                  ? 'text-neutral-50 dark:text-neutral-950 font-[sans-700] tracking-wider'
                  : 'text-neutral-950 dark:text-neutral-50 font-[sans-400]'
              } text-md`}
            >
              {t(`common:${currentOption.label}`)}
            </Text>
          </Pressable>
        );
      })}
    </>
  );
};
export default ToggleWithText;
