import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from '../../utils/responsive';

const useOutsideClickHandler = (initialValue: boolean) => {
  const reference = useRef(null);
  const [visible, setVisible] = useState(initialValue);

  const useOnClickOutside = useCallback(
    (event) => {
      if (reference.current && !reference.current.contains(event.target)) setVisible(false);
    },
    [reference, setVisible],
  );

  useEffect(() => {
    if (!isMobile) {
      document.addEventListener('click', useOnClickOutside, true);
    }

    return () => {
      if (!isMobile) {
        document.removeEventListener('click', useOnClickOutside, true);
      }
    };
  }, [reference, useOnClickOutside]);

  return { visible, setVisible, reference, useOnClickOutside };
};

export default useOutsideClickHandler;
