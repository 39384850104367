import React, { useEffect } from 'react';
import { useFonts } from 'expo-font';
import { useTags } from '../../contexts/Tag/TagContext';
import { useModules } from '../../contexts/Module/ModuleContext';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import { useNotifications } from '../../contexts/Notification/NotificationContext';

interface Props {
  onAppLoadingReady: () => void;
}

const AppDataLoader: React.FC<Props> = ({ onAppLoadingReady }) => {
  const [fontsLoaded] = useFonts({
    'sans-700': require('../../assets/fonts/PolySans-Bulky.otf'),
    'sans-600': require('../../assets/fonts/PolySans-Median.otf'),
    'sans-400': require('../../assets/fonts/PolySans-Neutral.otf'),
    'sans-300': require('../../assets/fonts/PolySans-Slim.otf'),
  });

  const { fetchModules } = useModules();
  const { fetchTags } = useTags();
  const { fetchMe } = useAuth();
  const { fetchNotifications } = useNotifications();

  useEffect(() => {
    const fetchAppData = async () => {
      await fetchModules();
      await fetchTags();
      await fetchMe();
      await fetchNotifications();
    };

    if (fontsLoaded) {
      fetchAppData().then(() => {
        onAppLoadingReady();
      });
    }
  }, [fontsLoaded]);
  return null;
};
export default AppDataLoader;
