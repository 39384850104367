import React, { useEffect, useState } from 'react';
import { View, Text, TextInputProps } from 'react-native';
import { ErrorMessage, useField } from 'formik';
import MaskInput from 'react-native-mask-input';
import { cln } from '../../utils/classnames';

interface Props extends TextInputProps {
  title: string;
  name: string;
  required?: boolean;
  style?: unknown;
}

const TimeInput: React.FC<Props> = ({ title, name, required = false, style, ...props }) => {
  const [field, meta, helpers] = useField(name);

  const [timeMask, setTimeMask] = useState([/[012]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]);

  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    if (field.value === '2') {
      setTimeMask([/[012]/, /[0-3]/, ':', /[0-5]/, /[0-9]/]);
    } else {
      setTimeMask([/[012]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]);
    }
  }, [field.value]);

  return (
    <View className="mb-11 relative" style={style}>
      <View className="flex-row">
        <Text className="text-input text-neutral-950 dark:text-neutral-50 font-[sans-600]">
          {title}
        </Text>
        {required && <Text className="text-input text-danger-500 font-[sans-400]"> *</Text>}
      </View>
      <MaskInput
        value={field?.value ? field.value : ''}
        onChangeText={(masked, unmasked) => {
          helpers.setValue(masked);
        }}
        mask={timeMask}
        className={cln(
          isFocused
            ? 'text-neutral-50 bg-neutral-950 dark:text-neutral-950 dark:bg-neutral-50'
            : 'text-neutral-950 bg-neutral-50 dark:text-neutral-50 dark:bg-neutral-950',
          meta.touched && meta.error
            ? 'border-danger-500 text-danger-500'
            : 'border-neutral-900 dark:border-neutral-200',
          'h-12 border-[1px] flex-1 p-3  outline-none',
        )}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style={{ outline: 'none', zIndex: 10 }}
        onBlur={() => {
          setIsFocused(false);
          field.onBlur(name);
        }}
        focusable={false}
        onFocus={() => {
          setIsFocused(true);
        }}
        blurOnSubmit={true}
        onSubmitEditing={() => setIsFocused(false)}
      />

      <ErrorMessage name={name}>
        {(message) => (
          <Text className="text-notificationMessage text-danger-500 font-[sans-600] absolute top-[76px]">
            {message}
          </Text>
        )}
      </ErrorMessage>
    </View>
  );
};

export default TimeInput;
