import React, { useEffect } from 'react';
import { Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ToggleWithText } from '../index';
import { Language } from '../../resources/interfaces';
import { loadLocalStorage, saveLocalStorage } from '../../utils/localStorage';
import client from '../../client/client';
import { useTags } from '../../contexts/Tag/TagContext';
import { useNotifications } from '../../contexts/Notification/NotificationContext';
import { useAuth } from '../../contexts/AuthContext/AuthContext';

const LanguageChanger: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { fetchTags } = useTags();
  const { fetchNotifications } = useNotifications();
  const { me } = useAuth();

  //Load saved language
  useEffect(() => {
    loadLocalStorage('language', i18n.changeLanguage);
  }, []);

  async function handleLanguageChange(currentOption: Language) {
    i18n.changeLanguage(currentOption);
    saveLocalStorage('language', currentOption);
    await fetchTags();
    await fetchNotifications();

    if (me?.isOnboarded) {
      client.saveUserLanguage(currentOption).catch((error) => {
        console.error('save user language error:', error);
      });
    }
  }

  return (
    <View className="mb-10 md:mb-0 md:flex-1 md:mr-10">
      <Text className="text-sectionSubtitle font-[sans-700] text-neutral-950 dark:text-neutral-50">
        {t('common:language')}
      </Text>
      <View className="flex-row">
        <ToggleWithText
          onPress={handleLanguageChange}
          activeOption={i18n.language}
          options={Object.values(Language).map((l) => ({ key: l, label: l.toUpperCase() }))}
        />
      </View>
    </View>
  );
};

export default LanguageChanger;
