import { MutableRefObject } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { filesize } from 'filesize';
import client from '../../client/client';
import { useMessage } from '../../contexts/Messages/MessageContext';
import { FileUploadStatus, UploadedFile } from '../../resources/interfaces';
import { FileModuleType } from '../../client/interfaces';

export const UseFileUpload = (
  ref: MutableRefObject<Record<string, UploadedFile>>,
  files: { [k: string]: UploadedFile },
  setValue: (value: unknown) => void,
  module: FileModuleType,
  maxSize: number,
) => {
  const { setMessage } = useMessage();
  const { t } = useTranslation();

  const fetchAsBlob = (url) => fetch(url).then((response) => response.blob());

  const convertBlobToBase64 = (blob: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  const uploadToAzure = async (
    blob: Blob,
    sasUrl: string,
  ): Promise<AxiosResponse<unknown, unknown>> => {
    try {
      const response = await axios.put(sasUrl, blob, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
      });

      if (response.status === 201) {
        console.log('Upload successful!');
        return response;
      } else {
        console.error('Upload failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading to Azure:', error);
    }
  };

  const loadFiles = () => {
    ref.current = files;
  };

  const handleFiles = async (value: FileList) => {
    for (const file of value) {
      if (ref.current && file.name in ref.current) continue;

      if (file.size > maxSize) {
        setMessage({
          message: t('error_messages:maximum_file_size', { max: filesize(maxSize) }),
          type: 'error',
          translateMessage: false,
        });
        continue;
      }

      const blob = await fetchAsBlob(URL.createObjectURL(file));
      const base64 = await convertBlobToBase64(blob);

      ref.current = ref.current || {};
      ref.current[file.name] = {
        id: undefined,
        file,
        status: FileUploadStatus.inProgress,
        src: base64 as string,
      };
    }

    setValue(ref.current);
  };

  const upload = async (fileName: string, controller: AbortController) => {
    try {
      if (!ref.current) return;
      if (!ref.current?.[fileName]) return;
      if (ref.current?.[fileName].status === FileUploadStatus.done) return;
      const { file } = ref.current[fileName];
      const { id, uploadUrl } = await client.getFileUploadUrl(module, controller);
      const blob = await fetchAsBlob(URL.createObjectURL(file));
      const response = await uploadToAzure(blob, uploadUrl);

      if (response) {
        ref.current[fileName] = {
          ...ref.current[fileName],
          status: FileUploadStatus.done,
          id,
        };
        setValue(ref.current);
        setMessage({
          message: t('admin:common:successful_image_upload'),
          type: 'success',
          translateMessage: false,
        });
      } else {
        setMessage({
          message: t('error_messages:file_upload_failed'),
          type: 'error',
          translateMessage: false,
        });
      }
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
  };

  const cancelUpload = (fileName: string, controller: AbortController) => {
    controller.abort();
    delete ref.current[fileName];
    setValue(ref.current);
  };

  const deleteUploadedFile = async (fileName: string) => {
    try {
      const { id } = ref.current[fileName];
      await client.deleteFile(id);
      delete ref.current[fileName];
      setValue(ref.current);
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
  };

  return {
    handleFiles,
    upload,
    cancelUpload,
    deleteUploadedFile,
    loadFiles,
  };
};
