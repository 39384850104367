import React, { useEffect, useRef, useState } from 'react';
import { FlatList, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import Layout from '../../../components/Layout';
import svgIcons, { SvgIcon } from '../../../assets/index';
import { Icon, IconCard } from '../../../components';
import {
  NavigationParamList,
  ScreenName,
  TabNavigationScreenProps,
} from '../../../navigation/types';
import { useAuth } from '../../../contexts/AuthContext/AuthContext';
import { Role } from '../../../client/interfaces';
import { isMobile } from '../../../utils/responsive';

enum IconCardType {
  article = 'article',
  event = 'event',
  extreme_event = 'extreme-event',
}

interface IconCardInterface {
  type: IconCardType;
  icon: SvgIcon;
  onPress: () => void;
  active: boolean;
}

const AdminIndexScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.AdminIndexScreen>
> = ({ navigation }) => {
  const { t } = useTranslation();
  const { isAdmin, hasRole, me } = useAuth();
  const [iconCards, setIconCards] = useState<IconCardInterface[]>([]);
  const [parentWidth, setParentWidth] = useState<number>(0);
  const [cardWidth, setCardWidth] = useState<number>(0);

  const flatListRef = useRef<FlatList>(null);

  const numColumns = 5;
  const cardMargin = 24;

  const titles: { [key in IconCardType]: string } = {
    [IconCardType.article]: t('navigation:news'),
    [IconCardType.event]: t('navigation:events'),
    [IconCardType.extreme_event]: t('navigation:extreme_events'),
  };

  useEffect(() => {
    if (!parentWidth) {
      return;
    }
    setCardWidth((parentWidth - (numColumns - 1) * cardMargin) / numColumns);
  }, [parentWidth]);

  const updateIconCards = () => {
    const allIconCards = [
      {
        type: IconCardType.article,
        icon: svgIcons.newspaperIcon,
        onPress: () => navigation.navigate(ScreenName.AdminArticleListScreen),
        active: hasRole(Role.publisher),
      },
      {
        type: IconCardType.event,
        icon: svgIcons.calendarCardIcon,
        onPress: () => navigation.navigate(ScreenName.AdminEventListScreen),
        active: hasRole(Role.publisher),
      },
      {
        type: IconCardType.extreme_event,
        icon: svgIcons.extremeEventCalendarIcon,
        onPress: () => navigation.navigate(ScreenName.AdminExtremeEventListScreen),
        active: hasRole(Role.extremeEventAdmin),
      },
    ];
    setIconCards(allIconCards.filter((card) => card.active));
  };

  useEffect(() => {
    updateIconCards();
  }, [me?.roleList]);

  return (
    <Layout title={t('navigation:admin_index')}>
      <View
        onLayout={(event) => {
          const { width } = event.nativeEvent.layout;
          setParentWidth(width);
        }}
      >
        <Text className="text-lead text-neutral-950 dark:text-neutral-50 mb-10 relative top-[-28px] font-[sans-600]">
          {t('admin:index:subtitle')}
        </Text>

        {isAdmin ? (
          <FlatList
            data={iconCards}
            ref={flatListRef}
            contentContainerStyle={{
              paddingBottom: isMobile ? 200 : 0,
            }}
            numColumns={numColumns}
            horizontal={false}
            keyExtractor={(item) => item.type}
            renderItem={({ item, index }) => (
              <IconCard
                icon={item.icon}
                title={titles[item.type]}
                onPress={item.onPress}
                style={{
                  width: cardWidth,
                  minWidth: 140,
                  minHeight: 176,
                  marginRight: (index + 1) % numColumns === 0 ? 0 : cardMargin,
                  marginBottom: cardMargin,
                }}
              />
            )}
          />
        ) : (
          <View className="flex flex-row justify-center items-center">
            <Icon
              icon={svgIcons.alertIcon}
              mobileSize={{ width: 64, height: 64 }}
              webSize={{ width: 64, height: 64 }}
            />
            <Text className="text-sectionSubtitle text-neutral-950 dark:text-neutral-50 ml-2 font-[sans-400]">
              {t('admin:index:not_authorized')}
            </Text>
          </View>
        )}
      </View>
    </Layout>
  );
};

export default AdminIndexScreen;
