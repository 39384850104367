import React, { useEffect, useRef, useState } from 'react';
import { TextInput, View, Pressable, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useColorScheme } from 'nativewind';
import { FormikErrors } from 'formik';
import { Tag as TagInterface } from '../../client/interfaces';
import { isWeb } from '../../utils/responsive';
import svgIcons from '../../assets';
import { Icon, Dropdown } from '../index';
import { cln } from '../../utils/classnames';
import { useTags } from '../../contexts/Tag/TagContext';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import Tag from '../Tag';
import { TagVariant } from '../../resources/interfaces';
import { useResize } from '../../utils/resize';

interface Props {
  hasError?: boolean;
  setTagParams: (t: string[]) => Promise<void | FormikErrors<unknown>>;
  tagParams: string[];
}

const TagSelector: React.FC<Props> = ({ hasError = false, setTagParams, tagParams = [] }) => {
  const { t } = useTranslation();
  const { colorScheme } = useColorScheme();
  const { filterTags } = useTags();
  const { isWidthBelowMedium } = useResize();

  // Dropdown input field states
  const [dropdownTags, setDropdownTags] = useState([]);
  const [tagSearch, setTagSearch] = useState<string>('');

  // Use refs for clicking outside
  // Dropdown ref for bluring the input in the handleBlur function
  const dropdownInputRef = useRef(null);
  const dropdownRef = useRef(null);
  const { visible, setVisible, reference } = useOnClickOutside(false);

  const [firstInputKey, setFirstInputKey] = useState(0);
  const [secondInputKey, setSecondInputKey] = useState(0);

  //Android padding adding workaround
  const handleReRender = () => {
    if ((firstInputKey === 0 || secondInputKey === 0) && Platform.OS === 'android') {
      setFirstInputKey((k) => k + 1);
      setSecondInputKey((k) => k + 1);
    }
  };

  // Dropdown filtering based on text input
  useEffect(() => {
    if (tagSearch) {
      const filteringTagsUpdated = filterTags.filter((tag) => {
        return (
          tag.translationValue.toLowerCase().includes(tagSearch.toLowerCase()) &&
          !tagParams?.includes(String(tag.id))
        );
      });
      setDropdownTags(filteringTagsUpdated);
    } else {
      const updatedTags = filterTags.filter((tag) => !tagParams?.includes(String(tag.id)));
      setDropdownTags(updatedTags);
    }
  }, [tagSearch, filterTags]);

  //Removing tags from chosen tags
  function removeTag(tag: TagInterface) {
    setTagParams(
      tagParams.filter((tagId) => {
        return tag.id !== Number(tagId);
      }),
    );
  }

  // Updating the list of tags we can choose from when chosen tags change so either removing from the list or adding to the list
  useEffect(() => {
    const updatedArray = filterTags.filter((item) => !tagParams?.includes(String(item.id)));
    setDropdownTags(updatedArray);
  }, [tagParams]);

  function handleBlur() {
    dropdownInputRef.current.blur();
    setVisible(false);
  }

  function handleFocus() {
    setVisible(true);
    dropdownInputRef.current.focus();
  }

  let dropdownPlaceholderColor: string;

  if (!visible) {
    dropdownPlaceholderColor = colorScheme === 'dark' ? '#888888' : '#101010';
  } else if (visible) {
    dropdownPlaceholderColor = colorScheme === 'dark' ? '#101010' : '#888888';
  }

  return (
    <View className="flex-column z-30" style={{ columnGap: 16, rowGap: 8 }}>
      <View className="flex-column sm:flex-row relative z-30" style={{ columnGap: 16, rowGap: 16 }}>
        <View
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={reference}
          className={cln('flex-row items-center relative z-80', isWeb && 'flex-1')}
        >
          <TextInput
            onLayout={handleReRender}
            key={secondInputKey}
            style={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              isWeb && { outline: 'none' }
            }
            ref={dropdownInputRef}
            placeholderTextColor={dropdownPlaceholderColor}
            className={cln(
              visible
                ? 'text-neutral-50 bg-neutral-950 dark:text-neutral-950 dark:bg-neutral-50'
                : 'text-neutral-950 bg-neutral-50 dark:text-neutral-50 dark:bg-neutral-950',
              'h-12 border-[1px] flex-1 px-3 ',
              hasError ? 'border-danger-500' : 'border-neutral-900 dark:border-neutral-200',
            )}
            onFocus={handleFocus}
            onChangeText={setTagSearch}
            value={tagSearch}
            keyboardType="ascii-capable"
            placeholder={t('common:tags')}
            blurOnSubmit={true}
            tabIndex={-1}
          />
          <View className="absolute right-[8px] z-20">
            <Pressable
              onPress={() => {
                if (visible) {
                  handleBlur();
                } else {
                  handleFocus();
                }
              }}
              className="py-2 px-2"
            >
              <Icon
                icon={visible ? svgIcons.chevronUpIcon : svgIcons.chevronDownIcon}
                mobileSize={{ width: 24, height: 24 }}
                webSize={{ width: 24, height: 24 }}
                inverted={visible}
              />
            </Pressable>
          </View>
          {visible && (
            <Dropdown
              onPress={(option) => {
                setTagParams([...tagParams, String(option.id)]);
                handleBlur();
                setTagSearch('');
              }}
              dropdownTags={dropdownTags}
              dropdownRef={dropdownRef}
            />
          )}
        </View>
      </View>
      <View
        className="w-full justify-between flex-nowrap"
        style={{
          flexDirection: isWidthBelowMedium ? 'column' : 'row',
        }}
      >
        <View
          className="flex-row flex-wrap z-10"
          style={{
            rowGap: 8,
            columnGap: 8,
            width: isWidthBelowMedium ? '100%' : '75%',
            display: 'flex',
            marginBottom: tagParams?.length >= 1 ? 12 : 0,
          }}
        >
          {tagParams?.map((tagId) => {
            const tag = filterTags.find((t) => t.id === Number(tagId));
            return tag ? (
              <Tag
                key={tag.id}
                variant={TagVariant.Selected}
                onPress={() => {
                  removeTag(tag);
                }}
                onPressRemove={() => {
                  removeTag(tag);
                }}
              >
                {tag.translationValue}
              </Tag>
            ) : null;
          })}
        </View>
      </View>
    </View>
  );
};

export default TagSelector;
