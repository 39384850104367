import { TFunction } from 'i18next';
import { AnyTranslationRole, Translation } from '../client/interfaces';
import { Language } from '../resources/interfaces';

export const getTranslation = (
  translations: Translation<AnyTranslationRole>[],
  role,
  language?: Language,
): string => {
  return translations.find((t) => {
    const roleMatch = t.role === role;
    let languageMatch = true;

    if (language) {
      languageMatch = t.language === language;
    }

    return roleMatch && languageMatch;
  })?.value;
};

export const translationKeyExists = (t: TFunction, key: string): boolean => {
  return t(key) !== key;
};
