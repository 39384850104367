import React, { PropsWithChildren, useEffect } from 'react';
import Animated, {
  useSharedValue,
  withTiming,
  Easing,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { PropsWithClassName } from '../../resources/interfaces';

interface Props extends PropsWithChildren, PropsWithClassName {
  duration: number;
  timeout: number;
}

const FadeInOut: React.FC<Props> = ({ duration, timeout, children, classNames }) => {
  const fadeInOpacity = useSharedValue(0);

  const fadeIn = () => {
    fadeInOpacity.value = withTiming(1, {
      duration,
      easing: Easing.linear,
    });
  };

  const fadeOut = () => {
    fadeInOpacity.value = withTiming(0, {
      duration: 1000,
      easing: Easing.linear,
    });
  };

  useEffect(() => {
    fadeIn();

    const timeoutId = setTimeout(() => {
      fadeOut();
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [fadeIn, fadeOut]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: fadeInOpacity.value,
    };
  }, [fadeInOpacity]);

  return (
    <Animated.View style={[animatedStyle]} className={classNames}>
      {children}
    </Animated.View>
  );
};

export default FadeInOut;
