import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { useFocusEffect } from '@react-navigation/core';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { Loader, EventCardList, Button } from '../../components';
import { Article } from '../../client/interfaces';
import client from '../../client/client';
import { useMessage } from '../../contexts/Messages/MessageContext';
import { NavigationParamList, ScreenName } from '../../navigation/types';
import { isMobile, isNative } from '../../utils/responsive';

const FeedSection: React.FC = () => {
  const { setMessage } = useMessage();
  const [isLoading, setIsLoading] = useState(true);
  const [articles, setArticles] = useState<Article[]>([]);
  const navigation =
    useNavigation<NavigationProp<NavigationParamList, ScreenName.ArticlesScreen>>();
  const { t } = useTranslation();

  const fetchArticles = async () => {
    try {
      setIsLoading(true);
      const articlesResponse = await client.getHighlightedArticles();
      setArticles(articlesResponse);
      setIsLoading(false);
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
    }
    setIsLoading(false);
  };

  const toggleBookmark = (article: Article) => {
    setArticles((prev) =>
      prev.map((a) => (a.id === article.id ? { ...a, isBookmarked: !a?.isBookmarked } : a)),
    );
  };

  const emptyArticlesState = () => {
    setArticles([]);
  };

  useFocusEffect(
    useCallback(() => {
      fetchArticles();

      return () => {
        emptyArticlesState();
      };
    }, []),
  );

  return isLoading ? (
    <Loader />
  ) : (
    <View style={{ flex: isNative ? 1 : null }}>
      <EventCardList items={articles} toggleBookmark={toggleBookmark} />
      <View style={{ paddingHorizontal: isNative ? 32 : 0, width: '100%', marginTop: 24 }}>
        <Button
          text={t('section:other_news')}
          variant={'outline'}
          size={'medium'}
          onPress={() => navigation.navigate(ScreenName.ArticlesScreen, { isBookmarked: false })}
          style={{ alignItems: 'flex-end' }}
          iconName={!isMobile && 'arrowRightIcon'}
        />
      </View>
    </View>
  );
};

export default FeedSection;
