import React from 'react';
import { View, Pressable } from 'react-native';
import SimpleArrowUp from '../../public/svg/simple-arrow-up.svg';
import SimpleArrowDown from '../../public/svg/simple-arrow-down.svg';
import { cln } from '../../utils/classnames';
import { Icon } from '../index';
import svgIcons from '../../assets';
interface TimePickerHour {
  stringFormat: string;
  hour: number;
  minutes: number;
  isSelected: boolean;
}

interface TimePickerProps {
  hours: TimePickerHour[];

  onClick(hour: number, time: number): void;
}

const TimePicker = ({ hours, onClick }: TimePickerProps): JSX.Element => {
  const pickerRef = React.useRef();

  const handleArrowUpClick = () => {
    const list = pickerRef.current as Element;
    if (list) {
      list.scrollTop = list.scrollTop - list.clientHeight;
    }
  };

  const handleArrowDownClick = () => {
    const list = pickerRef.current as Element;
    if (list) {
      list.scrollTop = list.scrollTop + list.clientHeight;
    }
  };

  return (
    <View>
      <Pressable
        className="justify-center items-center px-5 cursor-pointer"
        onPress={handleArrowUpClick}
      >
        <Icon
          icon={svgIcons.chevronUpIcon}
          mobileSize={{ width: 24, height: 24 }}
          webSize={{ width: 24, height: 24 }}
        />
      </Pressable>
      <View
        className="flex flex-col h-[340px] w-[80px] overflow-scroll scroll-smooth"
        ref={pickerRef}
      >
        {hours.map((time, index) => {
          return (
            <Pressable
              className={cln(
                'cursor-pointer flex items-center justify-center border border-solid border-neutral-600 p-4 dark:bg-neutral-930',
                time.isSelected && 'bg-primary-500 text-neutral-930',
                time.isSelected && 'dark:bg-primary-500',
                'dark:bg-neutral-900',
              )}
              onPress={() => onClick(time.hour, time.minutes)}
              key={`time-${time.stringFormat}`}
            >
              {time.stringFormat}
            </Pressable>
          );
        })}
      </View>
      <Pressable
        className="flex justify-center items-center p-5 cursor-pointer"
        onPress={handleArrowDownClick}
      >
        <Icon
          icon={svgIcons.chevronDownIcon}
          mobileSize={{ width: 24, height: 24 }}
          webSize={{ width: 24, height: 24 }}
        />
      </Pressable>
    </View>
  );
};

export default TimePicker;
