import React, { useCallback, useRef, useState } from 'react';
import { useFocusEffect } from '@react-navigation/core';
import { Image, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useColorScheme } from 'nativewind';
import {
  Button,
  EventCardList,
  Layout,
  Loader,
  Tag,
  HyperlinkedText,
} from '../../components/index';
import { isMobile, isMobileWeb, isNative, isTablet, isWeb } from '../../utils/responsive';
import { cln } from '../../utils/classnames';
import { Article, ArticleTranslationRole } from '../../client/interfaces';
import { ErrorType, TagVariant } from '../../resources/interfaces';
import { NavigationParamList, ScreenName, TabNavigationScreenProps } from '../../navigation/types';
import client from '../../client/client';
import { getTranslation } from '../../utils/translations';
import { useMessage } from '../../contexts/Messages/MessageContext';
import { useTags } from '../../contexts/Tag/TagContext';
import { handleLinkPress } from '../../utils';

const ArticleScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.ArticleScreen>
> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const [article, setArticle] = useState(null);
  const [similarArticles, setSimilarArticles] = useState<Article[]>(null);
  const { setMessage } = useMessage();
  const { tags } = useTags();
  const [tagIds, setTagIds] = useState<number[]>(null);
  const finalTagData = tags?.filter((tag) => tagIds?.includes(tag.id));
  const articleRef = useRef(null);
  const [hasCtaLabel, setHasCtaLabel] = useState<boolean>(false);
  const { colorScheme } = useColorScheme();

  const renderedTags = finalTagData?.map((tag) => {
    return (
      <Tag
        key={tag.id}
        variant={TagVariant.Border}
        onPress={() =>
          navigation.navigate(ScreenName.ArticlesScreen, {
            tags: [`${tag.id}`],
            isBookmarked: false,
          })
        }
        margin="mr-3 mb-3"
      >
        {tag.translationValue}
      </Tag>
    );
  });

  const fetchArticle = async () => {
    try {
      if (articleRef.current && isNative) {
        articleRef?.current?.scrollTo({ y: 10, animated: false });
      } else if (articleRef.current && !isNative) {
        window.scrollTo({ top: 0, behavior: 'instant' });
      }
      const articleRes = await client.getArticle(route.params.postId);

      setArticle(articleRes);

      setHasCtaLabel(!!getTranslation(articleRes.translations, ArticleTranslationRole.ctaLabel));

      navigation.setOptions({
        title: getTranslation(articleRes.translations, ArticleTranslationRole.title),
      });

      const tagIds = articleRes?.tags?.map((tag) => {
        return tag.id;
      });
      setTagIds(tagIds);

      const similarArticlesRes = await client.getSimilarArticles({
        postId: route.params.postId,
        tagIds: tagIds,
        limit: 3,
      });

      setSimilarArticles(similarArticlesRes);
    } catch (error) {
      setMessage({ message: error.message, type: 'error' });
      navigation.navigate(ScreenName.ErrorScreen, {
        type:
          error?.response?.status === 404 ? ErrorType.not_found : ErrorType.internal_server_error,
      });
    }
  };

  // FETCHING DATA
  useFocusEffect(
    useCallback(() => {
      route?.params?.postId && fetchArticle();
    }, [route?.params?.postId]),
  );

  const toggleBookmark = (article: Article) => {
    setSimilarArticles((prev) =>
      prev.map((a) => (a.id === article.id ? { ...a, isBookmarked: !a?.isBookmarked } : a)),
    );
  };

  const fallbackImage =
    colorScheme === 'light'
      ? require('../../assets/fallback.jpg')
      : require('../../assets/fallback-dark.jpg');

  return article && article?.id == route?.params?.postId ? (
    <Layout
      title={getTranslation(article.translations, ArticleTranslationRole.title)}
      isScrollView={isMobile}
      isArticleStyle={true}
      refProp={articleRef}
      titleMarginBottom={false}
    >
      <View className="max-w-[640px] w-full self-center mt-2">
        <View className={cln(isMobile && 'px-0', 'w-full mt-0')}>
          <View
            className={cln(
              isMobile ? 'mb-0' : 'mt-2 mb-6',
              'flex-row justify-between items-center',
            )}
            style={{
              minHeight: 40,
              marginBottom: 24,
              marginTop: 0,
            }}
          >
            <View>
              <Text className="font-[sans-400] text-xs text-neutral-950 dark:text-neutral-50">
                {`${t('article:uploaded_by')}: ${!article.publisher ? '' : article.publisher}`}
              </Text>
              <Text className="font-[sans-400] text-xs text-neutral-950 dark:text-neutral-50 mt-3">
                {t('article:date')}: {article.publishedAtUtc.slice(0, 10)}
              </Text>
            </View>
          </View>
          <View />
        </View>
        <Tag
          onPress={async () => {
            if (article?.isBookmarked) {
              await client.deletePostBookmark(article?.id);
              setArticle((prevValue) => {
                return { ...prevValue, isBookmarked: false };
              });
            } else {
              await client.createPostBookmark(article?.id);
              setArticle((prevValue) => {
                return { ...prevValue, isBookmarked: true };
              });
            }
          }}
          variant={TagVariant.IconTag}
          iconName={article?.isBookmarked ? 'bookmarkFilledIcon' : 'bookmarkBorder'}
          isFilled={article?.isBookmarked}
          margin="mr-3"
          width={isMobile && !isTablet && 'w-full'}
        >
          {article.isBookmarked ? t('article:article_saved') : t('article:save_article')}
        </Tag>

        <View
          className={isMobile ? 'my-6' : 'my-6'}
          style={{ aspectRatio: 4 / 3, width: '100%', overflow: 'hidden' }}
        >
          <Image
            source={article.imageUrl ? { uri: article.imageUrl } : fallbackImage}
            resizeMode={'cover'}
            style={{ height: '100%', maxWidth: '100%' }}
          />
        </View>
        <View className="flex-row flex-wrap mb-6">{renderedTags}</View>

        <View className={cln(isMobile && 'mt-0', 'self-center mb-6 sm:mb-10 w-full')}>
          <HyperlinkedText
            text={getTranslation(article.translations, ArticleTranslationRole.description)}
          />
        </View>

        {hasCtaLabel && (
          <View
            style={{
              marginBottom:
                // On phone without similar articles
                isNative && similarArticles?.length < 1
                  ? 40
                  : isNative && similarArticles?.length >= 1
                    ? 0
                    : // On web phone width with similar articles
                      isMobileWeb && similarArticles?.length > 0
                      ? 0
                      : // On web phone width without similar articles
                        isMobileWeb && similarArticles?.length < 1
                        ? 40
                        : // On web bigger screens without similar articles
                          isWeb && similarArticles?.length <= 0
                          ? 0
                          : // On web bigger screens with similar articles
                            40,
            }}
          >
            <Button
              text={getTranslation(article.translations, ArticleTranslationRole.ctaLabel)}
              variant={'fill'}
              onPress={() =>
                handleLinkPress(
                  getTranslation(article.translations, ArticleTranslationRole.ctaLink),
                )
              }
              size={'wide'}
              style={{ alignItems: 'center' }}
            />
          </View>
        )}
      </View>
      {similarArticles?.length >= 1 && (
        <View
          className={cln(isNative ? 'mb-0 left-[-32px] w-full' : 'mb-0')}
          style={{
            marginTop: isNative && !hasCtaLabel ? 64 : isMobileWeb && !hasCtaLabel ? 16 : 90,
          }}
        >
          <EventCardList
            items={similarArticles}
            toggleBookmark={toggleBookmark}
            title={t('article:similar_articles')}
          />
        </View>
      )}
    </Layout>
  ) : (
    <View className="h-full w-full items-center justify-center">
      <Loader />
    </View>
  );
};

export default ArticleScreen;
