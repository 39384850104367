import axios, { AxiosRequestConfig } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import qs from 'qs';
import { EXPO_PUBLIC_BE_BASE_URL, EXPO_PUBLIC_IAM_BE_BASE_URL } from '@env';

const defaultAxiosRequestConfig = {
  baseURL: EXPO_PUBLIC_BE_BASE_URL,
  timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  // Helps to create an array in the query string, for backend consume
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
};

export const createAxiosInstance = (axiosRequestConfig?: AxiosRequestConfig) =>
  applyCaseMiddleware(axios.create({ ...defaultAxiosRequestConfig, ...axiosRequestConfig }));

export const axiosInstance = createAxiosInstance();

export const iamAxiosInstance = createAxiosInstance({ baseURL: EXPO_PUBLIC_IAM_BE_BASE_URL });
