import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeyExists } from '../../utils/translations';

export type MessageType = 'error' | 'success';

export interface MessageInterface {
  message: string;
  type: MessageType;
  translateMessage?: boolean;
}

type MessageContextType = {
  type: MessageType;
  message: string;
  setMessage: ({ message, type }: MessageInterface) => void;
  resetMessage: () => void;
};

const messageContextDefaultValues: MessageContextType = {
  message: '',
  type: 'error',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMessage: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetMessage: () => {},
};

export const MessageContext = createContext<MessageContextType>(messageContextDefaultValues);

export function useMessage() {
  return useContext(MessageContext);
}

export function MessageProvider({ children }: Readonly<PropsWithChildren>) {
  const [message, setMessage] = useState<string>(messageContextDefaultValues.message);
  const [type, setType] = useState<MessageType>(messageContextDefaultValues.type);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(messageContextDefaultValues.message);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  const handleChange = ({
    message,
    type,
    translateMessage = true,
  }: {
    message: string;
    type: MessageType;
    translateMessage?: boolean;
  }) => {
    let translatedMessage = message;

    if (translateMessage) {
      if (translationKeyExists(t, message)) {
        translatedMessage = t(message);
      } else if (translationKeyExists(t, `error_messages.${message}`)) {
        translatedMessage = t(`error_messages.${message}`);
      } else {
        console.error(message);
        translatedMessage = t(`error_messages:default`);
      }
    }

    setMessage(translatedMessage);
    setType(type);
  };

  const handleReset = () => {
    setMessage(messageContextDefaultValues.message);
  };

  return (
    <MessageContext.Provider
      value={React.useMemo(
        () => ({
          setMessage: handleChange,
          resetMessage: handleReset,
          message,
          type,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [message, type],
      )}
    >
      {children}
    </MessageContext.Provider>
  );
}
