import React, { FC } from 'react';
import { Pressable, View } from 'react-native';
import { AdminOrderParams, Order } from '../../client/interfaces';
import svgIcons from '../../assets';
import { Icon } from '../index';

interface Props {
  columnName: string;
  params: AdminOrderParams;
  paramSetter: React.Dispatch<React.SetStateAction<AdminOrderParams>>;
}
const SortingIcon: FC<Props> = ({ columnName, params, paramSetter }) => {
  const setOrder = (order: Order, orderBy) => {
    paramSetter((prev) => ({ ...prev, order, orderBy, page: 1 }));
  };

  return (
    <View className="inline-flex ml-2.5 cursor-pointer">
      {columnName === params.orderBy && (
        <Pressable
          onPress={() => setOrder(params.order === Order.ASC ? Order.DESC : Order.ASC, columnName)}
        >
          <Icon
            icon={
              params.order === Order.ASC ? svgIcons.chevronUpSmallIcon : svgIcons.chevronDownIcon
            }
            mobileSize={{ width: 15, height: 15 }}
            webSize={{ width: 15, height: 15 }}
          />
        </Pressable>
      )}
      {columnName !== params.orderBy && (
        <View className="flex flex-col">
          <Pressable onPress={() => setOrder(Order.ASC, columnName)}>
            <Icon
              icon={svgIcons.chevronUpSmallIcon}
              mobileSize={{ width: 15, height: 15 }}
              webSize={{ width: 15, height: 15 }}
            />
          </Pressable>
          <Pressable onPress={() => setOrder(Order.DESC, columnName)}>
            <Icon
              icon={svgIcons.chevronDownIcon}
              mobileSize={{ width: 15, height: 15 }}
              webSize={{ width: 15, height: 15 }}
            />
          </Pressable>
        </View>
      )}
    </View>
  );
};

export default SortingIcon;
