import React, { useEffect, useRef, useState } from 'react';
import { Pressable, View, Text, Animated } from 'react-native';
import { cln } from '../../utils/classnames';
import { PropsWithClassName } from '../../resources/interfaces';

interface Props extends PropsWithClassName {
  value: boolean;
  onPress?: () => void;
  label?: string;
}

const ToggleSwitch: React.FC<Props> = ({ value, onPress, label, classNames }, ref) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const animatedValue = useRef(new Animated.Value(value ? 1 : 0)).current;

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: value ? 1 : 0,
      duration: 50,
      useNativeDriver: false,
    }).start();
  }, [value]);

  const leftPosition = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 24],
  });

  const bgStyles = {
    selected: isHovered
      ? 'bg-primaryLight-200 dark:bg-primary-300'
      : 'bg-primaryLight-500 dark:bg-primary-500',
    notSelected: 'bg-neutral-600 dark:bg-neutral-300',
  };

  return (
    <View className={cln('flex-row', classNames)}>
      <Pressable
        onPress={onPress}
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => {
          setIsHovered(false);
        }}
        className={cln('w-11 h-5 flex-row items-center relative')}
      >
        {/* Rectangle */}
        <Animated.View
          style={{
            shadowColor: '#000',
            shadowOffset: {
              width: value ? 2 : -2,
              height: 6,
            },
            shadowOpacity: 0.25,
            shadowRadius: 6,
            left: leftPosition,
          }}
          className={cln('absolute z-10 w-5 h-5 bg-neutral-50 transition-all ease-in duration-500')}
        />
        {/* Track */}
        <View className={cln('w-11 h-2', value ? bgStyles.selected : bgStyles.notSelected)} />
      </Pressable>
      {label && (
        <Text className="ml-3 text-input text-neutral-950 dark:text-neutral-50 font-[sans-600]">
          {label}
        </Text>
      )}
    </View>
  );
};

export default ToggleSwitch;
