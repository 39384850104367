import { forwardRef } from 'react';
import { View, TextInput, Text, TextInputProps } from 'react-native';
import { cln } from '../../utils/classnames';

export interface InputProps extends TextInputProps {
  label?: string;
  validated?: boolean;
  helperText?: string;
  inputClassName?: string;
  disabled?: boolean;
  hidden?: boolean;
}

// TODO: mergelni a FormInput komponenssel
const Input = forwardRef<TextInput, InputProps>(
  ({ label, className, validated, disabled, inputClassName, hidden = false, ...props }, ref) => {
    return (
      <View className={cln('text-label w-full flex flex-col', className, hidden && 'hidden')}>
        <TextInput
          {...props}
          ref={ref}
          editable={disabled}
          className={cln(
            validated && 'border-primary-500 text-primary-500 text-label',
            disabled && 'opacity-30',
            'peer order-2 text-input px-[10px] w-full outline-none bg-transparent h-11 border border-neutral-900 placeholder:text-neutral-500',
            'focus:!border-secondary-500',
            'dark:border-neutral-50',
            inputClassName,
          )}
        />
        {label && (
          <Text className="order-1 peer-required:after:content-['\*'] peer-required:after:text-danger-500 text-danger-500">
            {label}
          </Text>
        )}
      </View>
    );
  },
);

Input.displayName = 'Input';
export default Input;
