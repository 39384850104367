export const isDoc = (type: string) => {
  return ['document', 'pdf', 'presentation', 'sheet'].some((el) => type.includes(el));
};

export const isZip = (type: string) => {
  return ['compressed'].some((el) => type.includes(el));
};

export const isMp4 = (type: string) => {
  return ['video/mp4'].some((el) => type.includes(el));
};

export const extensionImageWhitelist = ['image/jpg', 'image/jpeg', 'image/png'];
