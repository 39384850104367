import axios, { AxiosInstance } from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { EXPO_PUBLIC_APP_ENV } from '@env';
import { ConditionalStorage, StorageKey } from '../utils/storage';
import { Language } from '../resources/interfaces';
import { IAM_BE_ENDPOINTS } from './endpoints';
import { iamAxiosInstance } from './axiosInstance';
import { AppEnv, LoginResponse } from './interfaces';

const getAuthData = (): Promise<LoginResponse | null> => {
  return ConditionalStorage.getItem(StorageKey.auth);
};

const setupInterceptors = (
  refresh: () => Promise<LoginResponse>,
  logout: () => void,
  instance: AxiosInstance,
) => {
  let originalConfig;
  const controller = new AbortController();
  let isRefreshing = false;
  let refreshPromise = null;
  const requestQueueSizeLimit = 10;
  let requestsQueue = [];

  // Retry all requests in the queue with the new token
  const retryRequests = (newAccessToken) => {
    requestsQueue.forEach((callback) => callback(newAccessToken));
    requestsQueue = []; // Clear the queue after processing
  };

  // Add a request interceptor
  iamAxiosInstance.interceptors.request.use(
    async (config) => {
      // Get accessToken from the storage
      const parsed = await getAuthData();
      // Do something before request is sent
      if (parsed?.accessToken && config.url !== IAM_BE_ENDPOINTS.REFRESH_TOKEN) {
        // Set access token to every request if there is one except for refresh
        config.headers.Authorization = `Bearer ${parsed.accessToken}`;
      }
      if (parsed?.refreshToken && config.url === IAM_BE_ENDPOINTS.REFRESH_TOKEN) {
        // Set refresh token for refresh
        config.headers.Authorization = `Bearer ${parsed.refreshToken}`;
      }
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  iamAxiosInstance.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      originalConfig = error?.config;

      if (originalConfig?.url === IAM_BE_ENDPOINTS.REFRESH_TOKEN) {
        // Abort the original request
        const parsed = await getAuthData();
        controller.abort();
        // Refreshing token was not successful, the original request failed again
        // logout
        parsed && (await logout());

        return Promise.reject(error);
      }

      if (axios.isCancel(error)) {
        return null;
      }
      return Promise.reject(error);
    },
  );

  // Add a request interceptor
  instance.interceptors.request.use(
    async (config) => {
      config.headers['Accept-Language'] = (await AsyncStorage.getItem('language')) || Language.en;
      // Get accessToken from the storage
      const parsed = await getAuthData();
      // Do something before request is sent

      if (parsed?.accessToken) {
        config.headers.Authorization = `Bearer ${parsed.accessToken}`;
      }

      // Dev módban a backenden hardcode-olva van a bejelentkezett user, csak annyira van szüksége, hogy bármilyen truthy érték legyen a Bearer headerben
      if (EXPO_PUBLIC_APP_ENV === AppEnv.dev) {
        config.headers.Authorization = 'Bearer -';
      }

      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      originalConfig = error?.config;
      if (error?.response) {
        // Access Token was expired
        if (
          (error.response.status === 401 || error.response.status === 403) &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;

          if (!isRefreshing) {
            isRefreshing = true;
            refreshPromise = refresh()
              .then((refreshResponse) => {
                if (!refreshResponse?.accessToken) {
                  Promise.reject('No access token in response');
                }
                isRefreshing = false;
                originalConfig.headers['Authorization'] = `Bearer ${refreshResponse.accessToken}`;
                retryRequests(refreshResponse.accessToken);
                refreshPromise = null;
                return refreshResponse.accessToken;
              })
              .catch((refreshError) => {
                isRefreshing = false;
                requestsQueue = []; // Clear queue on refresh failure
                refreshPromise = null;
                return Promise.reject(refreshError);
              });
          }

          // Return a promise that resolves when the refresh is done
          return new Promise((resolve, reject) => {
            // Add the request to the queue

            if (requestsQueue.length < requestQueueSizeLimit) {
              requestsQueue.push((newAccessToken) => {
                originalConfig.headers['Authorization'] = `Bearer ${newAccessToken}`;
                resolve(instance(originalConfig));
              });
            }

            refreshPromise.catch(reject); // Reject the promise if the refresh fails
          });
        }
      }
      if (axios.isCancel(error)) {
        return null;
      }
      return Promise.reject(error);
    },
  );
};
export default setupInterceptors;
