import React, { useState } from 'react';
import { Text, View, Pressable, Image, ViewStyle } from 'react-native';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { useColorScheme } from 'nativewind';
import { t } from 'i18next';
import svgIcons from '../../assets';
import { Icon } from '../index';
import client from '../../client/client';
import { ArticleTranslationRole, EventCardItem, PostType, Event } from '../../client/interfaces';
import { getTranslation } from '../../utils/translations';
import { NavigationParamList, ScreenName } from '../../navigation/types';
import { getDateDetails } from '../../utils/getDateDetails';
import { cln } from '../../utils/classnames';
import { isWeb } from '../../utils/responsive';

interface Props {
  item: EventCardItem;
  toggleCardMark: (item: EventCardItem) => void;
  styleProp?: ViewStyle;
}

const cardMarkIconDimensions = {
  width: 24,
  height: 24,
};

const cardMarkIcons = {
  bookmark: {
    active: svgIcons.bookmarkBorder,
    inactive: svgIcons.bookmarkBorder,
  },
  calendar: {
    active: svgIcons.calendarIcon,
    inactive: svgIcons.calendarIcon,
  },
};

enum CardMarkType {
  bookmark = 'bookmark',
  calendar = 'calendar',
}

const cardMarkIndicators: { [key in CardMarkType]: keyof EventCardItem } = {
  [CardMarkType.bookmark]: 'isBookmarked',
  [CardMarkType.calendar]: 'isBookmarked',
};

// Check if the item is of type Event
const isEvent = (item: EventCardItem): item is Event => item.type === PostType.event;

const EventCard: React.FC<Props> = ({ item, toggleCardMark, styleProp }) => {
  const title = getTranslation(item.translations, ArticleTranslationRole.title);
  const cardMarkType =
    item.type === PostType.article ? CardMarkType.bookmark : CardMarkType.calendar;
  const cardType = item.type === 'article' ? ScreenName.ArticleScreen : ScreenName.EventScreen;
  const cardMarkActive = !!item?.[cardMarkIndicators[cardMarkType]];
  const cardMarkIcon = cardMarkIcons[cardMarkType][cardMarkActive ? 'active' : 'inactive'];

  const [bookmarkHovered, setBookmarkHovered] = useState(false);
  const [bookmarkPressed, setBookmarkPressed] = useState(false);

  const navigation = useNavigation<NavigationProp<NavigationParamList, typeof cardType>>();

  const { colorScheme } = useColorScheme();

  const bookmarkTexts = {
    article: cardMarkActive ? t('article:article_saved') : t('article:save_article'),
    event: cardMarkActive ? t('event:event_saved') : t('event:save_event'),
  };

  const bookmarkBackground = {
    default: cardMarkActive ? 'bg-neutral-50' : 'bg-neutral-930/85',
    hover: cardMarkActive ? 'bg-neutral-300' : 'bg-neutral-700',
    press: cardMarkActive ? 'bg-neutral-500' : 'bg-neutral-900',
  };

  // If isEvent, we have related item, otherwise not
  const eventDetails = isEvent(item) ? item?.relatedItem : undefined;
  const dateDetails = getDateDetails(eventDetails);

  const givenDate = new Date(dateDetails.fullEndDate);
  const currentDate = new Date();
  const isPast = givenDate < currentDate;

  const onCardMarkPress = async () => {
    if (!isPast) {
      if (item?.isBookmarked) {
        await client.deletePostBookmark(item.id);
      } else {
        await client.createPostBookmark(item.id);
      }
      toggleCardMark(item);
    }
  };

  const fallbackImage =
    colorScheme === 'light'
      ? require('../../assets/fallback.jpg')
      : require('../../assets/fallback-dark.jpg');

  return (
    <View className={'mb-10 md:mb-0'} style={styleProp ? styleProp : { width: '100%' }}>
      <Pressable
        style={{ width: styleProp ? '100%' : null }}
        onPress={() => navigation.navigate(cardType, { postId: item.id })}
      >
        <View
          className="mb-2 md:mb-25 w-full overflow-hidden"
          style={{
            aspectRatio: 4 / 3,
          }}
        >
          <Image
            resizeMode={'cover'}
            style={{ height: '100%', opacity: isPast ? 0.5 : 1 }}
            source={item.imageUrl ? { uri: item.imageUrl } : fallbackImage}
          />
          <Pressable
            disabled={isPast}
            onPress={onCardMarkPress}
            onHoverIn={() => setBookmarkHovered(true)}
            onHoverOut={() => setBookmarkHovered(false)}
            onPressIn={() => {
              setBookmarkHovered(false);
              setBookmarkPressed(true);
            }}
            onPressOut={() => setBookmarkPressed(false)}
            className={cln(
              'absolute top-[18px] right-[18px] h-10 bg-neutral-950/[.85] rounded justify-center items-center flex-1 flex-row px-2',
              bookmarkHovered
                ? bookmarkBackground.hover
                : bookmarkPressed
                  ? bookmarkBackground.press
                  : bookmarkBackground.default,
            )}
            style={[
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              isWeb ? { transition: 'all 0.1s ease-in-out' } : null,
            ]}
          >
            <Icon
              icon={cardMarkIcon}
              mobileSize={cardMarkIconDimensions}
              webSize={cardMarkIconDimensions}
              classNames="items-center justify-center mr-2"
              inverted={
                colorScheme === 'dark'
                  ? cardMarkActive && !bookmarkPressed
                  : !cardMarkActive || (cardMarkActive && bookmarkPressed)
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              style={{ opacity: isPast ? 0.5 : 1 }}
            />
            <Text
              className={cln(
                cardMarkActive && !bookmarkPressed
                  ? 'text-neutral-930'
                  : isPast
                    ? 'text-neutral-500'
                    : 'text-neutral-50',
                'text-body font-[sans-400]',
              )}
            >
              {isEvent(item) ? bookmarkTexts.event : bookmarkTexts.article}
            </Text>
          </Pressable>
        </View>
        <Text className="text-cardTitleMobile md:text-cardTitle text-neutral-950 dark:text-neutral-50 font-[sans-600]">
          {title}
        </Text>
      </Pressable>
      {item?.type === 'event' ? (
        <View className="w-full">
          <View className="flex-row items-center mt-3 relative">
            <Icon
              icon={svgIcons.calendarIcon}
              mobileSize={{ width: 24, height: 24 }}
              webSize={{ width: 24, height: 24 }}
              classNames={'mr-2'}
            />
            <View className="flex-row items-start flex-1 flex-wrap">
              <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-600] text-calendarCardTitle md:text-body mr-2">
                {dateDetails.dateFirstLine} {dateDetails.dateSecondLine ? '-' : ''}
              </Text>
              <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-600] text-calendarCardTitle md:text-body mr-2">
                {dateDetails.dateSecondLine}
              </Text>
            </View>
          </View>
          <View className="flex-row items-center mt-2 relative">
            <Icon
              icon={svgIcons.pinIcon}
              mobileSize={{ width: 24, height: 24 }}
              webSize={{ width: 24, height: 24 }}
              classNames={'mr-2'}
            />
            <View className="flex-1">
              <Text
                className="text-neutral-930 dark:text-neutral-50 font-[sans-600] text-calendarCardTitle md:text-body"
                numberOfLines={2}
              >
                {item?.relatedItem?.location}
                {item?.relatedItem?.joinLink
                  ? `${item?.relatedItem?.location ? ' / ' : ''}${t('common:online')}`
                  : ``}
              </Text>
            </View>
          </View>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

export default EventCard;
