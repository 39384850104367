import { ButtonHTMLAttributes, FC } from 'react';
import { GestureResponderEvent, Pressable } from 'react-native';
import { Icon } from '../../index';
import svgIcons from '../../../assets';
import { cln } from '../../../utils/classnames';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'left' | 'right' | 'first';
  visible: boolean;
  onPress: (event: GestureResponderEvent) => void;
}

const PaginationArrow: FC<Props> = ({ variant, visible, onPress }) => {
  return (
    <Pressable
      className={cln(
        'h-full w-[35px] flex items-center cursor-pointer',
        visible ? 'visible' : 'h-0 opacity-0',
        variant === 'left' && 'ml-3',
      )}
      onPress={onPress}
    >
      {visible && (
        <Icon
          icon={variant === 'first' ? svgIcons.doubleArrowLeftIcon : svgIcons.arrowLeftIcon}
          webSize={{ width: 35, height: 35 }}
          mobileSize={{ width: 0, height: 0 }}
          classNames={cln(variant === 'right' && 'rotate-180')}
        />
      )}
    </Pressable>
  );
};

export default PaginationArrow;
