import * as SecureStore from 'expo-secure-store';
import Cookies from 'js-cookie';
import { LoginResponse } from '../client/interfaces';
import { isWeb } from './responsive';

export enum StorageKey {
  auth = 'auth',
}

export interface StorageValueTypes {
  [StorageKey.auth]: LoginResponse;
}

export interface IStorage {
  getItem<T extends StorageKey>(key: T): Promise<StorageValueTypes[T] | null>;
  setItem<T extends StorageKey>(key: T, value: StorageValueTypes[T]): Promise<void>;
  removeItem(key: StorageKey): Promise<void>;
}

const cookieOptions = {
  path: '/',
  domain: isWeb ? `.${location.hostname.split('.').slice(-2).join('.')}` : '',
};

export const ConditionalStorage: IStorage = {
  async getItem<T extends StorageKey>(key: T): Promise<StorageValueTypes[T] | null> {
    if (isWeb) {
      const rawData = Cookies.get(key);
      return rawData ? JSON.parse(rawData) : null;
    } else {
      const rawData = await SecureStore.getItemAsync(key);
      return rawData ? JSON.parse(rawData) : null;
    }
  },

  async setItem<T extends StorageKey>(key: T, value: StorageValueTypes[T]): Promise<void> {
    const stringValue = JSON.stringify(value);
    if (isWeb) {
      Cookies.set(key, stringValue, cookieOptions);
    } else {
      await SecureStore.setItemAsync(key, stringValue);
    }
  },

  async removeItem(key: StorageKey): Promise<void> {
    if (isWeb) {
      Cookies.remove(key, cookieOptions);
    } else {
      await SecureStore.deleteItemAsync(key);
    }
  },
};
